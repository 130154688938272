import { configureStore } from '@reduxjs/toolkit';
import dataReducer from '../slices/dataSlice';
import goldenBatchReducer from '../slices/goldenBatchSlice';
import uiReducer from '../slices/uiSlice';
import configurationReducer from '../slices/configurationSlice';
import systemsReducer from '../slices/systemsSlice';
const store = configureStore({
  reducer: {
    data: dataReducer,
    goldenBatch: goldenBatchReducer,
    ui: uiReducer,
    configuration: configurationReducer,
    systems: systemsReducer,
  },
});

export default store;