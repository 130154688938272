import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import type { DragEndEvent, DragOverEvent, UniqueIdentifier } from '@dnd-kit/core';
import '../../styles/flow.css'
import {
  closestCenter,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { Button, Input, Space, Table } from 'antd';
import type { InputRef, TableColumnType, TableColumnsType } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { DataIndex } from 'rc-table/lib/interface';
import { getRequest, putRequest } from '../../utils/apiHandler';
import { useLocation, useNavigate } from 'react-router-dom';
// interface DataType {
//   key: string;
//   name: string;
//   gender: string;
//   age: number;
//   email: string;
//   address: string;
// }
interface HeaderCellProps extends React.HTMLAttributes<HTMLTableCellElement> {
  id: string;
}

interface BodyCellProps extends React.HTMLAttributes<HTMLTableCellElement> {
  id: string;
}

interface DragIndexState {
  active: UniqueIdentifier;
  over: UniqueIdentifier | undefined;
  direction?: 'left' | 'right';
}
interface RunDeviationTableProps {
  columnsData: any; // Replace `any` with the actual type for `columnsData`
  runDeviationData: any; // Replace `any` with the actual type for `data`
  getRunDeviationData: any;
  getSelectedCellData:any;
  mlJobData:any;
  getSelectedCheckboxData:any;
  resetCheckboxes:boolean;
}

const DragIndexContext = createContext<DragIndexState>({ active: -1, over: -1 });

const dragActiveStyle = (dragState: DragIndexState, id: string) => {
  const { active, over, direction } = dragState;
  // drag active style
  let style: React.CSSProperties = {};
  if (active && active === id) {
    style = { backgroundColor: 'gray', opacity: 0.5 };
  }
  // dragover dashed style
  else if (over && id === over && active !== over) {
    style =
      direction === 'right'
        ? { borderRight: '1px dashed gray' }
        : { borderLeft: '1px dashed gray' };
  }
  return style;
};

const TableBodyCell: React.FC<BodyCellProps> = (props) => {
  const dragState = useContext<DragIndexState>(DragIndexContext);
  return <td {...props} style={{ ...props.style, ...dragActiveStyle(dragState, props.id) }} />;
};

const TableHeaderCell: React.FC<HeaderCellProps> = (props) => {
  const dragState = useContext(DragIndexContext);
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({ id: props.id });
  const style: React.CSSProperties = {
    ...props.style,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999, userSelect: 'none' } : {}),
    ...dragActiveStyle(dragState, props.id),
  };
  return <th {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

let dataSource: any = [];

let baseColumns: TableColumnsType<any> = [{
  title: 'Row',
  dataIndex: 'key',
  rowScope: 'row',
}];

const isTargetVariable = (columnTitle: string, targetVariableSettings: any): boolean => {
  if (!targetVariableSettings) return false;

  // Case 1: Regular target variable settings with rules
  if (targetVariableSettings.rules) {
    return targetVariableSettings.rules.some((rule: any) => 
      rule.field?.toLowerCase() === columnTitle?.toLowerCase()
    );
  }

  // Case 2: RCA target variable settings
  return Object.keys(targetVariableSettings).some(key => 
    key.toLowerCase() === columnTitle?.toLowerCase()
  );
};

const RunDeviationTable: React.FC <RunDeviationTableProps>= ({columnsData , runDeviationData, getRunDeviationData, getSelectedCellData, mlJobData, getSelectedCheckboxData, resetCheckboxes}:any) => {
  console.log('mlJobData', mlJobData?.settings?.datasource?.target_variable_settings);
  
  const location = useLocation()
  const [genericIdentifiers, setGenericIdentifiers] = useState<any>(null);
  const [contextMenu, setContextMenu] = useState<{ x: number; y: number; visible: boolean; record: any; cellValue: any, header: string } | null>(null);
  const [typeOfoperation, setTypeOfOperation] = useState<any>(null)
  const [visibleCheckboxColumn, setVisibleCheckboxColumn] = useState<string>("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<any>([])
  const contextMenuRef = useRef<HTMLDivElement | null>(null);

  
  const getColumnSearchProps = (dataIndex: string): TableColumnType<any> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (!record[dataIndex]) {
        return
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    }
  });

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target as Node)
      ) {
        setContextMenu(null); // Close the context menu
        setVisibleCheckboxColumn(""); // Reset visibleCheckboxColumn
        setSelectedCheckboxes([]) // Reset selected checkboxes
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

    const [targetVariableSettings, setTargetVariableSettings] = useState<any>(null)
    const [targetVariablesLoaded, setTargetVariablesLoaded]= useState<boolean>(false)
    const queryParams = new URLSearchParams(location.search);
    const workflowId = queryParams.get('workflowId');
    useEffect(() => {
      const loadTargetVariableSettings = async () => {
        if (workflowId && parseInt(workflowId) > 0){
          const response = await getRequest(`/workflow/${workflowId}`);
          const workFlowComponents = response?.data?.data?.workflowComponents || []
          workFlowComponents.forEach((component:any) => {
            if (component.type === "file") {
              const targetSettings = component.settings.target_variable_settings
              setTargetVariableSettings(targetSettings)
              setTargetVariablesLoaded(true)
            }
            else if(component.component === "rca"){
              const targetSettings =
                mlJobData?.settings?.datasource?.target_variable_settings;
              setTargetVariableSettings(targetSettings)
              setTargetVariablesLoaded(true)
            }
          })
        }
      }
      loadTargetVariableSettings()
    },[workflowId])


  let runDeviationcolumns: any = [{
    title: 'Row',
    dataIndex: 'sortedKey',
    rowScope: 'row',
    width: 100,
  }];

  columnsData.map((runDeviationData: any, index: number) => {
    if (!runDeviationcolumns.some((column: any) => column.title === runDeviationData)) {
      let columns = {
        title: runDeviationData,
        dataIndex: runDeviationData,
        key: runDeviationData,
        fixed: index == 0 ? 'left' : '',
        width: 200,
        textAling: 'center',
        ...getColumnSearchProps(runDeviationData),
        render: (text: any, record: any) => {
          const isChecked = selectedCheckboxes.some(
            (item: any) => item.record.key === record.key && item.header === runDeviationData
          );
          return (
            <div className={record[`${runDeviationData}%`] ? 'run-deviation' : ''}
              style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span>{text}</span>
              {record.key !== 0 && visibleCheckboxColumn?.toLowerCase() == runDeviationData?.toLowerCase() && (
                <input
                  type="checkbox"
                  className="ml-2"
                  checked={isChecked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedCheckboxes((prev: any) => [...prev, { record, text, header: runDeviationData }]);
                    } else {
                      setSelectedCheckboxes((prev: any) =>
                        prev.filter((item: any) => item.record.key !== record.key)
                      );
                    }
                  }}
                />
              )}
            </div>
          );
        }
      }
      runDeviationcolumns.push(columns)

    }
  })
  let runDeviationRowData: any = [];

  // Process and sort the runDeviationData
  const sortedRunDeviationData = runDeviationData.map((runDeviationData: any, index: any) => {
    const result: { [key: string]: any } = { key: index + 1 };
    const goldendata: { [key: string]: any } = { key: index };
    let count = 0;
    if (index === 0) {
      for (const key in runDeviationData) {
        if (runDeviationData[key]?.hasOwnProperty("golden_value")) {
          goldendata[key] =
            key === "DateTime"
              ? "Golden Values (Algo)"
              : runDeviationData[key]?.golden_value
              ? `${runDeviationData[key]?.golden_value.toFixed(2)}`
              : "";
        }
      }
      runDeviationRowData.push(goldendata);
  
      // Manually insert Golden Values (Custom) at index 1
      const customGoldenData: { [key: string]: any } = { key: 1 };
      let hasCustomGoldenValue = false;
  
      for (const key in runDeviationData) {
        if (runDeviationData[key]?.hasOwnProperty("custom_golden_value")) {
          hasCustomGoldenValue = true;
          customGoldenData[key] =
            key === "DateTime"
              ? "Golden Values (Custom)"
              : runDeviationData[key]?.custom_golden_value
              ? `${runDeviationData[key]?.custom_golden_value.toFixed(2)}`
              : "";
        }
      }
  
      // Ensure entry at index 1 even if no values exist
      if (hasCustomGoldenValue) {
        customGoldenData["DateTime"] = "Golden Values (Custom)";
        runDeviationRowData.push(customGoldenData);
      }
    }
  
    for (const key in runDeviationData) {
      if (runDeviationData[key]?.hasOwnProperty("value")) {
        result[key] =
          typeof runDeviationData[key]?.value === "number"
            ? `${runDeviationData[key]?.value.toFixed(2)} (${(
                runDeviationData[key]?.percentage_deviation * 100
              ).toFixed(2)}%)`
            : `${runDeviationData[key]?.value} `;
  
        result[`${key}%`] =
          Math.abs(runDeviationData[key]?.percentage_deviation * 100) > 5 &&
          runDeviationData[key]?.deviation_threshold_crossed;
  
        if (result[`${key}%`]) {
          count++;
        }
      }
    }
    return { count, result };
  });
  
  const sortedData = sortedRunDeviationData.sort(
    (a: { count: number }, b: { count: number }) => b.count - a.count
  );
  
  sortedData.forEach(({ result }:any, sortedIndex: number) => {
    result['sortedKey'] = sortedIndex + 1; // Add the sortedKey during sorting
    runDeviationRowData.push(result);
  });
  

  const [updateColumns , setUpdateColumns] = useState(false)
  const [dragIndex, setDragIndex] = useState<DragIndexState>({ active: -1, over: -1 });
  baseColumns = runDeviationcolumns
  dataSource = runDeviationRowData
  // const [columns, setColumns] = useState(() =>
  //   baseColumns.map((column: any, i: any) => ({
  //     ...column,
  //     key: `${i}`,
  //     onHeaderCell: () => ({ id: `${i}` }),
  //     onCell: () => ({ id: `${i}` }),
  //   })),
  // );
  let userData: any | undefined;
  const storedUserData = localStorage.getItem('userData');
  if (storedUserData) {
    userData = JSON.parse(storedUserData);
  }

  const [columns, setColumns] = useState(() =>
    baseColumns.map((column: any, i: any) => ({
      ...column,
      fixed: isTargetVariable(column.title, targetVariableSettings) || column.fixed,
      key: `${i}`,
      onHeaderCell: () => ({ id: `${i}`, header: column.title }), // Pass header title here
      onCell: (record: any, rowIndex: number) => {
        const isClickable = userData?.role?.alias == "process_engineer" && i > 1 && rowIndex > 0; // Check if cell should be clickable
        return {
          onContextMenu: isClickable
            ? (e: React.MouseEvent) => {
                handleContextMenu(e, record, record[column.dataIndex], column.title); // Pass column title as header
              }
            : undefined, // No context menu for non-clickable cells
          style: {
            cursor: isClickable ? 'pointer' : 'default', // Add pointer cursor for clickable cells
          },
        };
      },
    })).sort((prev, next) => {
      if (prev.fixed === "left" && next.fixed !== "left") {
        return -1;
      }
      if (prev.fixed !== "left" && next.fixed === "left") {
        return 1;
      }
      return 0;
    })
  );
  useEffect(() => {
    setColumns(baseColumns.map((column: any, i: any) => ({
      ...column,
      fixed: isTargetVariable(column.title, targetVariableSettings) || column.title === 'Row' 
        ? 'left' 
        : column.fixed,
      key: `${i}`,
      onHeaderCell: () => ({ id: `${i}`, header: column.title }), // Pass header title here
      onCell: (record: any, rowIndex: number) => {
        const isClickable = userData?.role?.alias == "process_engineer" && i > 1 && rowIndex > 0; // Check if cell should be clickable
        return {
          onContextMenu: isClickable
            ? (e: React.MouseEvent) => {
                handleContextMenu(e, record, record[column.dataIndex], column.title); // Pass column title as header
              }
            : undefined, // No context menu for non-clickable cells
          style: {
            cursor: isClickable ? 'pointer' : 'default', // Add pointer cursor for clickable cells
          },
        };
      },
    })).sort((prev,next) => {
      if (prev.fixed === "left" && next.fixed !== "left") {
        return -1;
      }
      if (prev.fixed !== "left" && next.fixed === "left") {
        return 1;
      }
      return 0;
    }))

  },[targetVariablesLoaded, visibleCheckboxColumn, selectedCheckboxes])
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  useEffect(() => {

    console.log('resetCheckboxes', resetCheckboxes)
    if(resetCheckboxes){
      console.log('resetCheckboxes', resetCheckboxes)
      setSelectedCheckboxes([])
      setVisibleCheckboxColumn("")
    }
  },[resetCheckboxes])

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if(active.id == 1 || over?.id==1 || over?.id==0 || active.id == 0){
      setDragIndex({ active: -1, over: -1 });
      setUpdateColumns(false);
      return
    }
    if (active.id !== over?.id) {
      setColumns((prevState) => {
        const activeIndex = prevState.findIndex((i) => i.key === active?.id);
        const overIndex = prevState.findIndex((i) => i.key === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
    setDragIndex({ active: -1, over: -1 });
    setUpdateColumns(true)
  };
  
  useEffect(()=>{
    if(updateColumns){
      updateOrderOfColumns()
    }
  },[updateColumns,columns])

  useEffect(() => {
    getParameterType();
  },[])

  const getParameterType = async () => {
    try {
      let type = 'GenericIdentifiers';
      const response = await getRequest(`/parameter-mapping/${type}`);
      if(response?.data?.data){
        setGenericIdentifiers(response?.data?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const onDragOver = ({ active, over }: DragOverEvent) => {
    const activeIndex = columns.findIndex((i) => i.key === active.id);
    const overIndex = columns.findIndex((i) => i.key === over?.id);
    setDragIndex({
      active: active.id,
      over: over?.id,
      direction: overIndex > activeIndex ? 'right' : 'left',
    });
  };

  const updateOrderOfColumns = () =>{
  const data = columns
  .map((item: { title: any }) => {
    if (item.title !== 'Row') {
      return item.title;
    }
    return null; // Explicitly return null if the condition isn't met
  })
  .filter(item => item !== null && item !== undefined); // Remove null or undefined values

  const params = new URLSearchParams(location.search);
  const workflowId = params.get('workflowId');
  let rundeviationData={
    workflowId:workflowId,
    columns:data
  }
  const updatecol = putRequest('/workflow/rundeviation/columns-order',rundeviationData)

  }

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };



  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const handleRowClick = (record: any) => {
    setSelectedRowKey(record.key); // Set the clicked row's key
    getRunDeviationData(record.key - 1)
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10); // Ensure currentPageSize is a number
  
  // Handle pagination change
  const handlePaginationChange = (page: number, pageSize: number | string) => {
    setCurrentPage(page);
  
    if (!pageSize) {
      setCurrentPageSize(dataSource.length); // Set to total records
    } else {
      setCurrentPageSize(Number(pageSize)); // Convert to number
    }
  };
  
  // Calculate the data for the current page
  const paginatedDataSource =
    currentPageSize === dataSource.length
      ? dataSource // Show all records if "All" is selected
      : dataSource.slice(
          (currentPage - 1) * currentPageSize,
          currentPage * currentPageSize
        );

  const handleContextMenu = (e: React.MouseEvent, record: any, cellValue: any, header: string) => {
    e.preventDefault();
    setSelectedCheckboxes([])
    // setVisibleCheckboxColumn(header)
    setSelectedCheckboxes((prev: any) => {
      const isAlreadySelected = prev.some(
        (item: any) => item.record.key === record.key && item.header === header
      );
      if (!isAlreadySelected) {
        return [...prev, { record, text: cellValue, header }];
      }
  
      return prev;
    });

    console.log('e.clientX', e.clientX)
    console.log('eeeeeeee', e)
    let width = 0
    let width1 = 0
    const element = document.querySelector('.side-tray');
    if (element) {
      width = element.getBoundingClientRect().width;
    }
    const element1 = document.querySelector('.insight-tabs');
    if (element1) {
      width1 = element1.getBoundingClientRect().width;
    }
    let totalWidth = width + width1;

    // To calculate dynamic height 
    const getElementTopPosition = (element: HTMLElement) => {
      const rect = element.getBoundingClientRect();
      return rect.top + window.scrollY; // The top position relative to the document
    };
    // const myDiv = document.querySelector('.save_golden_batch'); // Replace with your actual div selector
    const myDiv = document.querySelector('.rundeviation-table'); // Replace with your actual div selector
    let topHeight = 0
    if (myDiv) {
      const topPosition = getElementTopPosition(myDiv as HTMLElement);
      topHeight = topPosition
    }

    let totalHeaderHeight = 0;

    const headers = document.querySelectorAll('.top-header, .insight-header') as NodeListOf<HTMLElement>;
    
    headers.forEach(header => {
      totalHeaderHeight += header.offsetHeight;
    });
    
    let aboveDivHeight = 0
    const divElement = document.querySelector('.save_golden_batch') as HTMLDivElement | null;
    if (divElement) {
      aboveDivHeight = divElement.offsetHeight
    }

    setContextMenu({
      x: e.clientX - totalWidth,
      y: e.clientY - (totalHeaderHeight + topHeight),
      // y: e.clientY - totalHeaderHeight - (topHeight - (aboveDivHeight + 20)),
      visible: true,
      record,
      cellValue,
      header,
    });
  };

  const handleCreateNewTask = (record: any, cellValue: any, header: string) => {
    console.log('Create New Task for:', record);       // Logs the full row data
    console.log('Selected Field Value:', cellValue);   // Logs the value of the clicked cell
    console.log('Selected Field Header:', header);     // Logs the header (column title)
    setContextMenu(null);  // Close the context menu after selection
    // let dataToSend = {
    //   DateTime: record.DateTime,
    //   value: cellValue,
    //   header
    // }

    setVisibleCheckboxColumn(header)
    const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
    console.log('genericIdentifiers', genericIdentifiers)
    const genericData = genericIdentifiers.reduce((acc: any, item: { name: string }) => {
      const capitalizedKey = capitalize(item.name);
      // const value = record[capitalizedKey];
      const value = record[item.name];
      if (value !== null && value !== undefined) {
        acc[item.name] = value;
      }
      return acc;
    }, {});
    let dataToSend = {
      ...genericData,
      DateTime: record.DateTime,
      value: cellValue,
      header,
      run_deviation_data: record
    };  
    getSelectedCellData(dataToSend)
  };

  const closeContextMenu = () => {
    setContextMenu(null);
    // setVisibleCheckboxColumn(null); // Reset visibleCheckboxColumn
  };

  useEffect(() => {
    const dataToSendArray = selectedCheckboxes.map(({ record, text, header }: any) => {
      const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
  
      const genericData = genericIdentifiers.reduce((acc: any, item: { name: string }) => {
        const capitalizedKey = capitalize(item.name);
        const value = record[item.name];
        if (value !== null && value !== undefined) {
          acc[item.name] = value;
        }
        return acc;
      }, {});
  
      return {
        ...genericData,
        DateTime: record.DateTime,
        value: text,
        header,
        run_deviation_data: record,
      };
    });
    getSelectedCheckboxData(dataToSendArray)
  },[selectedCheckboxes])

console.log(JSON.stringify(columns))
  return (
    <div onClick={closeContextMenu}>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToHorizontalAxis]}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
        collisionDetection={closestCenter}
      >
        <SortableContext
          items={columns.map((i) => i.key)}
          strategy={horizontalListSortingStrategy}
        >
          <DragIndexContext.Provider value={dragIndex}>
            <Table<any>
              rowKey="key"
              rowHoverable={false}
              columns={columns}
              dataSource={dataSource}
              onRow={(record) => ({
                onClick: (e: React.MouseEvent) => {
                  const target = e.target as HTMLInputElement; //To manage the row click and checkbox
                  if (target.tagName === "INPUT" && target.type === "checkbox") {
                    return;
                  }
                  handleRowClick(record); // Handle row click
                },
              })}
              // rowClassName={(record) =>
              //   record.key === selectedRowKey ? 'highlighted-row' : ''
              // }
              rowClassName={(record, index) => {
                if (index === 0) {
                  return "sticky top-0 bg-white z-10";
                } else if (index === 1 ) {
                  for (const key in runDeviationData) {
                    if(runDeviationData[key]?.hasOwnProperty("custom_golden_value")){
                      return "sticky top-[55px] bg-white z-10";
                    }
                  }
                } else if (record.key === selectedRowKey) {
                  return "highlighted-row";
                }
                return "";
              }}
              pagination={{
                current: currentPage,
                pageSize:
                  currentPageSize == dataSource.length
                    ? dataSource.length
                    : currentPageSize,
                total: dataSource.length,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100", "All"], // Use total records instead of 'All'
                onChange: handlePaginationChange,
              }}
              scroll={{
                x: 1500,
                y: 55 * 10,
              }}
              components={{
                header: { cell: TableHeaderCell },
                body: { cell: TableBodyCell },
              }}
              className="rundeviation-table"
              // className={styles.customTable}
            />
          </DragIndexContext.Provider>
        </SortableContext>
        <DragOverlay>
          <th style={{ backgroundColor: "gray", padding: 16 }}>
            {
              columns[columns.findIndex((i) => i.key === dragIndex.active)]
                ?.title as React.ReactNode
            }
          </th>
        </DragOverlay>
      </DndContext>
      {contextMenu?.visible && (
        <div
          ref={contextMenuRef} 
          className="absolute z-10 bg-white shadow-lg border rounded-md p-2 text-sm"
          style={{ top: contextMenu.y, left: contextMenu.x }}
        >
          <button
            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
            onClick={() =>
              handleCreateNewTask(
                contextMenu.record,
                contextMenu.cellValue,
                contextMenu.header
              )
            }
          >
            Create New Task
          </button>
        </div>
      )}
    </div>
  );
};

export default RunDeviationTable;