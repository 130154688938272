import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { getRequest, postRequest } from "../../utils/apiHandler";
import dayjs, { Dayjs } from 'dayjs';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Card, DatePicker, Segmented } from "antd";
import Plot from 'react-plotly.js';
import type { RangePickerProps } from 'antd/es/date-picker';
import arrow from "../../img/arrowdownlight.svg";
import { formatDate } from "../Common/dateFormatter";

// Define TypeScript interfaces for the data
interface TimeSpentData {
  user_id: number;
  user_name: string;
  day: string;
  totalMinutesSpent: number;
  workflowCount: number;
  averageTimeSpent: number;
}

interface AverageTimeData {
  user_id: number;
  user_name: string;
  averageMinutesSpent: number;
}

interface ViewType {
  type: 'daily' | 'monthly' | 'weekly';
}

interface DateRange {
  start: Date | undefined;
  end: Date | undefined;
}

const TimeSpentPage: React.FC = () => {
  const today = new Date();
  const tomorrow = new Date();
  today.setDate(today.getDate() - 1);
  // tomorrow.setDate(today.getDate() + 1);

  const [dateRange, setDateRange] = useState<DateRange>({
    start: today,
    end: tomorrow,
  });
  const [data, setData] = useState<TimeSpentData[]>([]);
  const [dailyTime, setDailyTime] = useState<any[]>([]);
  const [perUserData, setPerUserData] = useState<any[]>([]);
  const [averageData, setAverageData] = useState<AverageTimeData[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [viewType, setViewType] = useState<ViewType['type']>('daily');
  const { RangePicker } = DatePicker;
  const defaultDateRange: [Dayjs, Dayjs] = [
    dayjs().startOf("day"), // Current day's start
    dayjs().endOf("day"),
  ];
  const [dateRanges, setDateRanges] = useState<[Dayjs, Dayjs]>(defaultDateRange);
  const [showOptions, setShowOptions] = useState(false);
  const [tenants, setTenants] = useState<any>(null);
  const [selectedTenants, setSelectedTenants] = useState<string[]>([]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);


  useEffect(() => {
    if (containerRef.current && perUserData && perUserData.length > 0) {
      const lastChild = containerRef.current.lastElementChild as HTMLElement | null;
      lastChild?.scrollIntoView({ behavior: "smooth" });
    }
  }, [perUserData]);

  useEffect(() => {
    fetchData()
  }, [viewType, dateRanges, setDateRanges])

  useEffect(() => {
    getTenants();
  },[])

  const getTenants = async () => {
    try {
      const response = await getRequest('/activityLog/tenants');
      console.log('response', response)
      if(response.data.data){
        setTenants(response.data.data)
      }
    } catch (error) {
      console.log('error', error)
      
    }
  }

  const fetchData = async () => {
    if (!dateRange.start || !dateRange.end) {
      setError("Please select a valid date range.");
      return;
    }
    if (!dateRanges || dateRanges.length !== 2) {
      setError("Please select a valid date range.");
      return;
    }
    const [startDate, endDate] = dateRanges;

    setLoading(true);
    setError(null);

    // const startDate = dateRange.start.toISOString();
    // const endDate = dateRange.end.toISOString();

    try {
      const payload: any = {
        range: {
          start: startDate.startOf("day").toISOString(),
          end: endDate.endOf("day").toISOString(),
        },
        viewType: viewType
      };
      
      if(selectedTenants.length > 0){
        payload.tenants = selectedTenants
      }

      const response = await postRequest('/activityLog/user-details', payload);
      const fetchedData: TimeSpentData[] = response.data.results;
      console.log('fetchedData', fetchedData)

      setData(fetchedData);
      setDailyTime(response.data.totalTimeEachDay);
      setPerUserData([])
    } catch (err: any) {
      if(err?.response?.data?.error)
        setError(err?.response?.data?.error)
      else
        setError("An error occurred while fetching data.");
      
      setData([])
      setDailyTime([])
    } finally {
      setLoading(false);
    }
  };

  const getUserDetail = async (user: any) => {
    console.log('user', user)
    if (!dateRanges || dateRanges.length !== 2) {
      setError("Please select a valid date range.");
      return;
    }
    const [startDate, endDate] = dateRanges;

    setLoading(true);
    setError(null);

    const userExists = perUserData.some((item: any) => String(item.user_id) === String(user.user_id));
    console.log('userExists', userExists)

    if (userExists) {
      setPerUserData((prevData) => prevData.filter((item: any) => String(item.user_id) !== String(user.user_id)));
      setLoading(false);
      return;
    }

    try {
      const payload = {
        user_id: user.user_id,
        range: {
          start: startDate.startOf("day").toISOString(),
          end: endDate.endOf("day").toISOString(),
        },
        viewType: viewType
      };

      const response = await postRequest('/activityLog/user-time', payload);
      // if(response?.data?.results?.dailyData)
      //   setPerUserData(response?.data?.results);
      if (response?.data?.results?.dailyData) {
        setPerUserData((prevData) => {
          // Check if user data already exists to avoid duplicates
          const userExists = prevData.some(
            (item: any) => String(item.user_id) === String(user.user_id)
          );
          return userExists ? prevData : [...prevData, response.data.results];
        });
      }
    } catch (err: any) {
      if(err?.response?.data?.error)
        setError(err?.response?.data?.error)
      else
        setError("An error occurred while fetching data.");
      
      setData([])
    } finally {
      setLoading(false);
    }
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  interface ActivityData {
    day: string; // e.g., "2024-12-01"
    totalTime: number; // e.g., 10
  }

  interface UserDailyActivityChartProps {
    activityData: ActivityData[];
  }

  const UserDailyActivityChart: React.FC<UserDailyActivityChartProps> = ({ activityData }) => {
    function getDateOfISOWeek(year: number, week: number) {
      const simple = new Date(year, 0, 1 + (week - 1) * 7);
      const dayOfWeek = simple.getDay();
      const ISOweekStart = simple;
      if (dayOfWeek <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
      else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
      return ISOweekStart;
    }

    const xCategories = activityData.map((point) => {
      if(viewType === 'weekly'){
        const [year, week] = point.day.split("-").map(Number);
        const date = getDateOfISOWeek(year, week);
        return date.toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
      }
      return formatDate(point.day);
    });

    const data = [{
      type: 'bar',
      x: xCategories,
      y: activityData.map((data) => data.totalTime),
      marker: { color: '#323780' }
    }];

    const layout = {
      title: 'User Daily Activity',
      height: 200,
      xaxis: {
        title: 'Date',
        tickangle: -45
      },
      yaxis: {
        title: {
          text: 'Number of Activities',
          standoff: 10
        }
      },
      margin: { l: 60, r: 20, t: 30, b: 50 }
    };

    const config = {
      displayModeBar: false,
      responsive: true
    };

    return (
      <Plot
        data={data as any}
        layout={layout}
        config={config}
        style={{ width: '100%', height: '100%' }}
      />
    );
  };

  const handleViewTypeChange = (value: ViewType['type']) => {
    setViewType(value);
    
    // If switching to monthly, set date range to current month
    if (value === 'monthly') {
      const currentDate = dayjs();
      const start = currentDate.startOf('month');
      const end = currentDate.endOf('month');
      
      // setDateRange([start, end]);

    } else {
      // If switching to daily, set date range to last 30 days
      // setDateRange(defaultDateRange);

    }
  };

  const handleDateRangeChange: RangePickerProps['onChange'] = (dates) => {
    if (dates) {
      const [start, end] = dates as [Dayjs, Dayjs];      
      // Convert local dates to UTC for API request
      const startUtc = start.startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const endUtc = end.endOf('day').format('YYYY-MM-DD HH:mm:ss');
      
      setDateRanges([start, end]); // Keep local dates for datepicker
    } else {
      // Reset to default date range
      const defaultStart = defaultDateRange[0];
      const defaultEnd = defaultDateRange[1];
      
      // Convert default dates to UTC
      const startUtc = defaultStart.startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      const endUtc = defaultEnd.endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      
      setDateRanges([defaultStart, defaultEnd]); // Keep local dates for datepicker
    }
  };

  const handleWeekRangeChange: RangePickerProps['onChange'] = (dates) => {
    if (dates) {
      const [start, end] = dates as [Dayjs, Dayjs];
  
      // Adjust to start of the week (Monday) and end of the week (Sunday)
      const startOfWeek = start.startOf('week'); // Customize if Monday is not the first day
      const endOfWeek = end.endOf('week');
  
      setDateRanges([startOfWeek, endOfWeek]);

    } else {
      // Reset to default date range if no dates are selected
      const defaultStart = defaultDateRange[0];
      const defaultEnd = defaultDateRange[1];
  
      const startUtc = defaultStart.startOf('week').utc().format('YYYY-MM-DD HH:mm:ss');
      const endUtc = defaultEnd.endOf('week').utc().format('YYYY-MM-DD HH:mm:ss');
  
      setDateRanges([defaultStart, defaultEnd]);
    }
  };
  
  const handleMonthRangeChange = (dates: any) => {
    if (dates) {
      const [start, end] = dates;
      // Set start to beginning of first month and end to end of last month
      const startDate = start.startOf("month");
      const endDate = end.endOf("month");

      setDateRanges([startDate, endDate]);
    }
  };


  const handleCheckboxChange = (status: string) => {
    setSelectedTenants((prev) =>
      prev.includes(status)
        ? prev.filter((item) => item !== status)
        : [...prev, status]
    );
  };

  const handleTenantsFilter = () => {
    setShowOptions(false)
    fetchData();
  }

  const UsageChart = () => {
    const percentageChanges = dailyTime.map((point, index) => {
      if (index === 0) return 0;
      const prevValue = Number.parseFloat(dailyTime[index - 1].totalTime);
      const currentValue = Number.parseFloat(point.totalTime);

      if (prevValue === 0 && currentValue === 0) return 0;
      if (prevValue === 0) return 100;

      const percentageChange = ((currentValue - prevValue) / prevValue) * 100;
      return Number.parseFloat(percentageChange.toFixed(2));
    });

    function getDateOfISOWeek(year: number, week: number) {
      const simple = new Date(year, 0, 1 + (week - 1) * 7);
      const dayOfWeek = simple.getDay();
      const ISOweekStart = simple;
      if (dayOfWeek <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
      else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
      return ISOweekStart;
    }

    const xCategories = dailyTime.map((point) => {
      if(viewType === 'weekly'){
        const [year, week] = point.day.split("-").map(Number);
        const date = getDateOfISOWeek(year, week);
        return date.toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
      }
      return formatDate(point.day);
    });

    const data = [{
      type: 'scatter',
      mode: 'lines+markers',
      x: xCategories,
      y: percentageChanges,
      line: { color: '#323780' }
    }];

    const layout = {
      height: 200,
      xaxis: {
        tickangle: -45
      },
      yaxis: {
        title: {
          text: 'Percentage Change (%)',
          standoff: 10
        }
      },
      margin: { l: 60, r: 20, t: 10, b: 50 }
    };

    const config = {
      displayModeBar: false,
      responsive: true
    };

    return (
      <Plot
        data={data as any}
        layout={layout}
        config={config}
        style={{ width: '100%', height: '100%' }}
      />
    );
  };

  return (
    <div className="bg-gray-100 py-4 px-4">
      <div className="mx-auto bg-white shadow-lg rounded-lg p-3 mb-6">
        <div className="flex flex-wrap gap-3 justify-between ">
          <div className="flex flex-wrap gap-4 items-center">
            <Segmented
              options={[
                { label: 'Daily', value: 'daily' },
                { label: 'Weekly', value: 'weekly' },
                { label: 'Monthly', value: 'monthly' },
              ]}
              value={viewType}
              onChange={handleViewTypeChange}
              style={{
                padding: '4px',
                background: '#1a237e',
                borderRadius: '4px',
              }}
              className={`
                ${viewType === 'daily' ? 'selected' : ''}
                [&_.ant-segmented-item]:text-white
                [&_.ant-segmented-item-selected]:bg-white
                [&_.ant-segmented-item-selected]:!text-[#1a237e]
                [&_.ant-segmented-item-selected_.ant-segmented-item-label]:!text-[#1a237e]
              `}
            />
            {viewType === 'daily' ? (
              <RangePicker
                showTime
                onChange={handleDateRangeChange}
                value={dateRanges}
                className="w-[400px]"
                defaultValue={defaultDateRange}
              />
            ) : viewType === 'weekly' ? (
              <RangePicker
                picker="week"
                onChange={handleWeekRangeChange}
                value={dateRanges}
                className="w-[400px]"
              />
            ) : (
              <RangePicker
                picker="month"
                onChange={handleMonthRangeChange}
                value={dateRanges}
                className="w-[400px]"
              />
            )}

            <div className="relative" ref={dropdownRef}>
              <button
                className={`border text-left border-[#B3B3B3] text-[#4D4D4D] worksans
                  ${
                    showOptions
                      ? "!bg-primary !border-primary active !text-white"
                      : "bg-[#F7F7F7] border-[#B3B3B3]"
                  } text-sm h-[32px] rounded-md min-w-40 px-3 py-1`}
                onClick={() => setShowOptions((prev) => !prev)}
              >
                Tenants
                <img
                className={`ml-2 h-[6px] absolute right-2 top-3 ${
                  showOptions ? "invert brightness-0 rotate-180" : ""
                }`}
                src={arrow}
              />
              </button>

              {showOptions && (
                <div className="absolute top-10 left-0 bg-white border border-gray-300 rounded-md shadow-lg p-3 z-10 w-56">
                  {tenants?.map((tenants: any) => (
                    <div key={tenants?.id} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        id={tenants.id}
                        value={tenants.id}
                        checked={selectedTenants.includes(tenants.id)}
                        onChange={() => handleCheckboxChange(tenants.id)}
                        className="mr-2 accent-primary"
                      />
                      <label htmlFor={tenants.id} className="text-sm text-gray-700">
                        {tenants?.name}
                      </label>
                    </div>
                  ))}

                  <div className="flex justify-end mt-3">
                    <button
                      className="text-sm text-blue-500 mr-3"
                      onClick={() => setSelectedTenants([])}
                    >
                      Clear
                    </button>
                    <button
                      className="text-sm text-blue-500"
                      onClick={handleTenantsFilter}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )}
            </div>

          </div>
        </div>
      </div>

      <div className="flex flex-wrap w-full mb-6">

        <Card title="User List" className="w-[60%]">
          {error && <p className="text-red-500 text-center mb-6">{error}</p>}

          <div className="overflow-x-auto max-h-[446px]">
            <table className="min-w-full table-auto text-left">
              <thead>
                <tr>
                  <th className="px-3 py-3 border-b border-solid border-[#ccc] text-sm font-semibold text-gray-700">User</th>
                  <th className="px-3 py-3 border-b border-solid border-[#ccc] text-sm font-semibold text-gray-700">Time Spent (minutes)</th>
                  <th className="px-3 py-3 border-b border-solid border-[#ccc] text-sm font-semibold text-gray-700">Workflows Created</th>
                  <th className="px-3 py-3 border-b border-solid border-[#ccc] text-sm font-semibold text-gray-700">Average Time Spent (minutes)</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={`${item.user_id}-${item?.day}`} className={`cursor-pointer border-b hover:bg-[#e9e9f5] ${perUserData.some((data: any) => data.user_id == item.user_id) ? 'bg-[#e9e9f5]' : 'bg-white'}`} onClick={()=> getUserDetail(item)}>
                    <td className="px-3 py-2">{item.user_name}</td>
                    <td className="px-3 py-2">{item?.totalMinutesSpent}</td>
                    <td className="px-3 py-2">{item?.workflowCount}</td>
                    <td className="px-3 py-2">{item?.averageTimeSpent}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>

        <div className="flex flex-col w-[40%] pl-5 gap-6">
          <Card title="Percentage Increase/Decrease" >
            <div className="h-[200px]">
              <UsageChart />
            </div>
          </Card>

          <Card title={`Total Time Spent By Users Per ${viewType == 'daily' ? 'Day' : viewType == 'weekly' ? 'Week' : viewType == 'monthly' ? 'Month' : viewType}`}>
            <div className="h-[200px]">
              <UserDailyActivityChart activityData={dailyTime} />
            </div>
          </Card>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 w-full">
        {perUserData?.length > 0 && (
          perUserData.map((userData, index) => (
            <div key={index} className="flex-1" ref={containerRef}>
              <Card
                title={(
                  <>
                    <span className="font-semibold text-xl">{userData?.user_name} </span>
                    Daily Time Spent
                  </>
                )}
              >
                <div className="h-[200px]">
                  <UserDailyActivityChart activityData={userData?.dailyData} />
                </div>
              </Card>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TimeSpentPage;
