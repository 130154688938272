import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Select, Form, Radio, InputNumber,Space } from "antd";
import { useLocation } from "react-router-dom";
import { useGoldenValues } from "../../context/goldenValues";
import { useSelector } from 'react-redux';
import { getRequest } from "../../utils/apiHandler";
const { Option } = Select;

interface OperationConfigProps {
  nodeData?: any;
  operationType?: string;
  selectedGoldenName:any;
  selectedGoldenId:any;
  onSave?: (config: any) => void;
  setPreSetQueryConfigurations:any;
  preSetOperationConfigurations:any;
  setGoldenFilterId:any;
  onChange?: (config: any) => void
}

function OperationConfig({ nodeData, operationType, selectedGoldenName,selectedGoldenId, onSave,setPreSetQueryConfigurations,preSetOperationConfigurations, setGoldenFilterId,onChange }: OperationConfigProps) {
  const { goldenBatches } = useSelector((state: any) => state.goldenBatch);
  const [clusteringModel, setClusteringModel] = useState("kmeans");
  const [correlationType, setCorrelationType] = useState({
    cross_correlation: "false",
    // target_variable_correlation: "true"
  });
  const [rcaRuns, setRcaRuns] = useState(true);
  const [theresold, setTheresold] = useState(0);
  const [selectGoldenName, setselectGoldenName] = useState(null);
  const [selectCustomGoldenName, setSelectCustomGoldenName] = useState<any>(null);
  const [customGoldenData, setCustomGoldenData] = useState<any>(null);
  const [goldenValueData, setGoldenValueData] = useState(null);
  const [customFilterDropdown, setCustomFilterDropdown] = useState([]);
  const [goldenParameters, setGoldenParameters] = useState({trueValues: [], falseValues: []});
  const { goldenValue, updateGoldenValue } = useGoldenValues();
  const [dimensionalityReduction , setDimensionalityReduction] = useState<any>(null)
  
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const executeFlow = params.get('execute');
  // Initialize states from saved configuration
  useEffect(() => {
    
    if (nodeData?.data?.operationConfig) {
          if (nodeData.data.operationConfig.correlation) {
        setCorrelationType({
          cross_correlation: nodeData.data.operationConfig.correlation.cross_correlation || "False",
          // target_variable_correlation: nodeData.data.operationConfig.correlation.target_variable_correlation || "True"
        });
      }
      if (nodeData.data.operationConfig.golden_cluster) {
        setClusteringModel(nodeData.data.operationConfig.golden_cluster.clustering_model || "kmean");
        if (nodeData.data.operationConfig.rca) {
          setRcaRuns(nodeData.data.operationConfig.rca.rca_runs || true);
        }
      }
      if(nodeData.data.operationConfig.dimensionality_reduction){
        setDimensionalityReduction(nodeData.data.operationConfig.dimensionality_reduction.algorithm || "kmean")
      }
      if (nodeData.data.operationConfig.rca) {
        setRcaRuns(nodeData.data.operationConfig.rca.rca_runs || true);
        setTheresold(nodeData.data.operationConfig.rca.deviation_threshold || 0);
        setselectGoldenName(nodeData.data.operationConfig.rca.name || null);
        setSelectCustomGoldenName(nodeData?.data?.operationConfig?.rca?.custom_name || null);
        setGoldenValueData(nodeData.data.operationConfig.rca.id || null);
        const excludedFeatures = nodeData?.data?.operationConfig?.golden_cluster?.exclude_features || [];
        // Get all available columns
        const allColumns = preSetOperationConfigurations || [];
        // Set selected columns as those that are NOT in exclude_features
        const selectedCols = allColumns?.filter((col:any) => !excludedFeatures.includes(col));
        setSelectedColumns(selectedCols);
      }
    }
  }, [nodeData]);

  useEffect(() => {
    if (selectedGoldenName) {
      setselectGoldenName(selectedGoldenName);
    }
    if (selectedGoldenId) {
      setGoldenValueData(selectedGoldenId);
    }
  }, [selectedGoldenName, selectedGoldenId]);

  const dataFromLocalStorage = () =>{
    let DummyResponseData= []
    let localData= localStorage.getItem('goldenData')
    if(localData){
      DummyResponseData = JSON.parse(localData)
    }
    let response ={
      status:200,
      data:DummyResponseData,
      message:"successfully get the data from local storage"
    }
    return response
  }

  const handleDropdownValue = (value: any) => {
    const parsedValue = JSON.parse(value);
    setSelectCustomGoldenName(null)
    setGoldenValueData(parsedValue.id)
    // let path_for_aiml = parsedValue.settings.datasource.file_path.split('DATA/')
    // let file_path = 'public/datasource/' + path_for_aiml[1];
    setselectGoldenName(parsedValue.name);
    let path_for_aiml = parsedValue?.settings?.datasource?.file_path?.split('DATA/')
    let file_path = path_for_aiml ? 'public/datasource/' + path_for_aiml[1] : '';
    updateGoldenValue(file_path)
    setPreSetQueryConfigurations(parsedValue?.settings?.datasource)
    
    if(parsedValue.filter_id){
    setGoldenFilterId(parsedValue.filter_id)
      const filteredTrueValues = goldenParameters.trueValues.filter((trueValue: any) => {
        return trueValue.filter_id === parsedValue.filter_id;
      });
      //   setGoldenParameters((prev) => ({
      //     ...prev,
      //     trueValues: filteredTrueValues,
      // }));
      setCustomFilterDropdown(filteredTrueValues)
    }
    else
      setCustomFilterDropdown([])

  };

  const handleCustomDropdownValue = (value: any) => {
    const parsedValue = JSON.parse(value);
    console.log('parsedValue', parsedValue)
    setSelectCustomGoldenName(parsedValue.name)
    setCustomGoldenData(parsedValue)
  };

  const [selectedColumns, setSelectedColumns] = useState<string[]>(preSetOperationConfigurations);


  // Coment useEffect for persistance of selected columns
  // useEffect(() => {
  //   // Initialize selected columns when preSetOperationConfigurations changes
  //   if (preSetOperationConfigurations) {
  //     setSelectedColumns(preSetOperationConfigurations);
  //   }
  // }, [preSetOperationConfigurations]);

  const handleColumnToggle = (columnName: string, isChecked: boolean) => {
    setSelectedColumns(prev => {
      if (isChecked) {
        return [...prev, columnName];
      } else {
        return prev.filter(col => col !== columnName);
      }
    });
  };  

  const createChunkedFields = (fields: string[]): string[][] => {
    return fields?.reduce((acc: string[][], curr: string, i: number) => {
      if (i % 3 === 0) {
        acc.push([curr]);
      } else {
        acc[acc.length - 1].push(curr);
      }
      return acc;
    }, []) || [];
  };

  useEffect(() =>{
    getGoldenParameters();
  },[])

  const getGoldenParameters = async () => {
    try {
      let filters = {
        pageSize: 500
      }
      const response = await getRequest(`/golden-data/0`, {filters });
      console.log('response', response)
      if(response?.data?.data){
        const trueValues = response.data.data.filter((item : any) => item.custom_parameter == true);
        console.log('trueValues', trueValues)
        const falseValues = response.data.data.filter((item : any) => item.custom_parameter == false || !item.custom_parameter);
        console.log('falseValues', falseValues)
        setGoldenParameters({trueValues, falseValues})

        if(nodeData?.data?.operationConfig?.rca?.id){
          let filterId = falseValues.filter((item: any) => item.id == nodeData?.data?.operationConfig?.rca?.id)
          filterId = filterId[0].filter_id
          if(filterId){
            const filteredTrueValues = trueValues.filter((trueValue: any) => {
              return trueValue.filter_id === filterId;
            });
            setCustomFilterDropdown(filteredTrueValues)
          }
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const renderOperationUI = () => {
    switch (operationType) {
      case 'golden_batch':
        return (
          <>
            {/* Column Selection Section */}
            <h3 className='mb-3'>Select Columns</h3>
            {preSetOperationConfigurations?(<div className="mb-4 border p-4 rounded">
              {createChunkedFields(preSetOperationConfigurations || []).map((row: string[], rowIndex: number) => (
                <div key={rowIndex} className="flex mb-2">
                  {row.map((field: string) => (
                    <div key={field} className="w-1/3 flex items-center">
                      <input
                        type="checkbox"
                        id={field}
                        checked={selectedColumns.includes(field)}
                        onChange={(e) => handleColumnToggle(field, e.target.checked)}
                        disabled={!!executeFlow}
                        className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <label htmlFor={field} className="text-sm text-gray-700 cursor-pointer">
                        {field}
                      </label>
                    </div>
                  ))}
                </div>
              ))}
            </div>):(<div className="mb-4 border p-4 rounded">Please save CSV settings before proceeding.</div>)}

            {/* Existing Golden Batch Configuration */}
            <Form.Item label="Clustering Model">
              <Select
                value={clusteringModel}
                onChange={(value) => setClusteringModel(value)}
                disabled={!!executeFlow}
              >
                <Option value="kmeans">K-means</Option>
              </Select>
            </Form.Item>
            <Form.Item label="RCA Runs">
              <Select
                value={rcaRuns}
                onChange={(value) => setRcaRuns(value)}
                disabled={!!executeFlow}
              >
                <Option value='true'>True</Option>
                <Option value='false'>False</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Deviation Threshold">
              <Space>
                <InputNumber<number>
                  defaultValue={0}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  value={theresold}
                  disabled={!!executeFlow}
                  parser={(value) => value?.replace('%', '') as unknown as number}
                  onChange={(value) => setTheresold(value ? value : 0)}
                />
              </Space>
            </Form.Item>
          </>
        );
      case 'correlation':
        return (
          <>
            <Form.Item label="Cross Correlation">
              <Radio.Group
                value={correlationType.cross_correlation}
                onChange={(e) => setCorrelationType(prev => ({
                  ...prev,
                  cross_correlation: e.target.value
                }))}
                disabled={!!executeFlow}
              >
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Radio.Group>
            </Form.Item>
            {/* <Form.Item label="Target Variable Correlation">
              <Radio.Group
                value={correlationType.target_variable_correlation}
                onChange={(e) => setCorrelationType(prev => ({
                  ...prev,
                  target_variable_correlation: e.target.value
                }))}
              >
                <Radio value="true">True</Radio>
                <Radio value="false">False</Radio>
              </Radio.Group>
            </Form.Item> */}
          </>
        );
      case 'rca':
        return (
          <div>
          {/* <Form.Item label="RCA Runs">
            <Select
              value={rcaRuns}
              onChange={(value) => setRcaRuns(value)}
            >
              <Option value="true">True</Option>
              <Option value="false">False</Option>
            </Select>
          </Form.Item> */}
            {/* <Select
              value={selectGoldenName}
              onChange={(option) => handleDropdownValue(option)}
              style={{ width: 200, marginBottom: 10 }}
              placeholder="Select Golden Name"
            >
              {goldenBatches?.map((option: any, index: any) => (
                <Option key={index} value={JSON.stringify(option)}>
                  {option.name}
                </Option>
              ))}
            </Select> */}
            <div className="inline-flex flex-col">
              <label>Golden Name</label>
              <Select
                value={selectGoldenName}
                onChange={(option) => handleDropdownValue(option)}
                style={{ width: 200, marginBottom: 10 }}
                placeholder="Select Golden Name"
              >
                {goldenParameters.falseValues?.map((option: any, index: any) => (
                  <Option key={index} value={JSON.stringify(option)}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </div>
            {customFilterDropdown?.length > 0 && (
              <div className="inline-flex flex-col ml-3">
                <label>Custom Golden Name</label>
                <Select
                  value={selectCustomGoldenName}
                  onChange={(option) => handleCustomDropdownValue(option)}
                  style={{ width: 200, marginBottom: 10 }}
                  placeholder="Select Custom Golden Name"
                  className=""
                >
                  {customFilterDropdown?.map((option: any, index: any) => (
                    <Option key={index} value={JSON.stringify(option)}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            <Form.Item label="Deviation Threshold">
            <Space>
              <InputNumber<number>
                defaultValue={0}
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                value={theresold}
                disabled={!!executeFlow}
                parser={(value) => value?.replace('%', '') as unknown as number}
                onChange={(value) => setTheresold(value ? value : 0)}
              />
            </Space>
            </Form.Item>
          </div>
        );
      case 'feature_importance':
        return <div>Feature Importance UI coming soon</div>;
      case 'prediction_model':
        return <div>Prediction Model UI coming soon</div>;
      case 'identification_model':
        return (
          <>
            {/* Column Selection Section */}
            {/* <h3 className='mb-3'>Select Columns</h3>
            {preSetOperationConfigurations?(<div className="mb-4 border p-4 rounded">
              {createChunkedFields(preSetOperationConfigurations || []).map((row: string[], rowIndex: number) => (
                <div key={rowIndex} className="flex mb-2">
                  {row.map((field: string) => (
                    <div key={field} className="w-1/3 flex items-center">
                      <input
                        type="checkbox"
                        id={field}
                        checked={selectedColumns.includes(field)}
                        onChange={(e) => handleColumnToggle(field, e.target.checked)}
                        disabled={!!executeFlow}
                        className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <label htmlFor={field} className="text-sm text-gray-700 cursor-pointer">
                        {field}
                      </label>
                    </div>
                  ))}
                </div>
              ))}
            </div>):(<div className="mb-4 border p-4 rounded">Please save CSV settings before proceeding.</div>)} */}
            <Form.Item label="Clustering Model">
              <Select
                value={clusteringModel}
                onChange={(value) => setClusteringModel(value)}
                // disabled={!!executeFlow}
              >
                <Option value="kmeans">K-means</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Dimensionality Reduction">
              <Select
                value={dimensionalityReduction}
                onChange={(value) => setDimensionalityReduction(value)}
                // disabled={!!executeFlow}
              >
               <Option value="tsne">tsne</Option>
               <Option value="pca">pca</Option>
                {/* <Option value='false'>False</Option> */}
              </Select>
            </Form.Item>
            {/* <Form.Item label="Deviation Threshold">
              <Space>
                <InputNumber<number>
                  defaultValue={0}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  value={theresold}
                  disabled={!!executeFlow}
                  parser={(value) => value?.replace('%', '') as unknown as number}
                  onChange={(value) => setTheresold(value ? value : 0)}
                />
              </Space>
            </Form.Item> */}
          </>
        );
      default:
        return <div>Select an operation type</div>;
    }
  };

  const handleSaveConfig = () => {
    let configData;

    switch (operationType) {
      case 'golden_batch':
        const allColumns = preSetOperationConfigurations || [];
      // Get unchecked columns (those not in selectedColumns)
      const excludedColumns = allColumns.filter((col:any) => !selectedColumns.includes(col));
        configData = {          
          golden_cluster: {
            exclude_features: excludedColumns,
            clustering_model: clusteringModel
          },
          rca: {
            rca_runs: rcaRuns,
            deviation_threshold: theresold
          }
        };
        break;
      case 'correlation':
        configData = {
          correlation: {
            cross_correlation: correlationType.cross_correlation,
            // target_variable_correlation: correlationType.target_variable_correlation
          }
        };
        break;
      case 'rca':
        configData = {
          rca: {
            rca_runs: rcaRuns,
            deviation_threshold: theresold,
            cluster_run_data: goldenValueData,
            name: selectGoldenName,
            id: goldenValueData,

            ...(customGoldenData && { 
              custom_rca_runs: rcaRuns,
              custom_deviation_threshold: theresold,
              custom_cluster_run_data: customGoldenData.id,
              custom_name: customGoldenData.name,
              custom_id: customGoldenData.id,
            })
          }
        };
        break;
        case 'identification_model':
          configData = {
            clustering: {
              algorithm : clusteringModel,
              config :{}
            },
            dimensionality_reduction: {
              algorithm: dimensionalityReduction,
              config:{}
            }
          };
          break;
      default:
        configData = {};
    }

    console.log("Saving config:", configData);
    onSave?.(configData);
  };
  const triggerOnChange = () => {
    const configData = {
      rca: {
        rca_runs: rcaRuns,
        deviation_threshold: theresold,
        cluster_run_data: goldenValueData,
        name: selectGoldenName,
        id: goldenValueData,

        ...(customGoldenData && { 
          custom_rca_runs: rcaRuns,
          custom_deviation_threshold: theresold,
          custom_cluster_run_data: customGoldenData.id,
          custom_name: customGoldenData.name,
          custom_id: customGoldenData.id,
        })
      }
    };
    onChange?.(configData)
  }
  return (
    <div className="p-5 rounded-lg">
      <h3 className="mb-2">Operation Configuration:</h3>
      <Form layout="vertical" className="operation-form">
        {renderOperationUI()}
        <Form.Item>
          {!executeFlow && (
            <button className="btn btn-primary-new" onClick={handleSaveConfig}>
              Save Configuration
            </button>
          )}
          <button className="btn btn-primary-new invisible" id="save-workflow-config" onClick={triggerOnChange} >
            Save Configuration
          </button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default OperationConfig;
