import React from "react";

const DummyCementResult = () => {
  return (
    <div className="flex flex-col gap-6 p-4">
      {/* Graphs Section */}
      <div className="flex flex-col gap-4 p-4">
        <div className="border rounded-lg p-4 shadow-sm">
          <h3 className="text-lg font-semibold mb-2">Graph-1</h3>
          <img
            src="/graph1.jpeg"
            alt="Temperature Graph 1"
            className="w-full h-auto"
          />
        </div>

        <div className="border rounded-lg p-4 shadow-sm">
          <h3 className="text-lg font-semibold mb-2">Graph-2</h3>
          <img
            src="/graph2.jpeg"
            alt="Temperature Graph 2"
            className="w-full h-auto"
          />
        </div>

        <div className="border rounded-lg p-4 shadow-sm">
          <h3 className="text-lg font-semibold mb-2">
            Pressure March-April 2024
          </h3>
          <img
            src="/graph3.jpeg"
            alt="Pressure Graph"
            className="w-full h-auto"
          />
        </div>
      </div>

      {/* Statistics Tables Section */}
      <div className="flex flex-col gap-8">
        {/* TT1 Tables */}
        <div className="border rounded-lg p-4">
          <h3 className="text-lg font-semibold mb-4">2406CN6_TT1 Statistics</h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="bg-gray-900 text-white p-4 rounded-lg">
              <h4 className="text-sm font-semibold mb-2 !text-white">
                Forecast
              </h4>
              <div className="overflow-x-auto">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="text-left px-4 py-2"> </th>
                      <th className="text-left px-4 py-2"> Val</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-4 py-2">count</td>
                      <td className="px-4 py-2">1000.0</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">mean</td>
                      <td className="px-4 py-2">921.769466548</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">std</td>
                      <td className="px-4 py-2">10.519212508</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">min</td>
                      <td className="px-4 py-2">887.163673400</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">25%</td>
                      <td className="px-4 py-2">914.522760624</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">50%</td>
                      <td className="px-4 py-2">921.749199940</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">75%</td>
                      <td className="px-4 py-2">928.495029188</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">max</td>
                      <td className="px-4 py-2">955.420538187</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="bg-gray-900 text-white p-4 rounded-lg">
              <h4 className="text-sm font-semibold mb-2 !text-white">Actual</h4>
              <div className="overflow-x-auto">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="text-left px-4 py-2"> </th>
                      <th className="text-left px-4 py-2">Val</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-4 py-2">count</td>
                      <td className="px-4 py-2">899.0</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">mean</td>
                      <td className="px-4 py-2">920.402111829</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">std</td>
                      <td className="px-4 py-2">16.301090280</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">min</td>
                      <td className="px-4 py-2">884.236328125</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">25%</td>
                      <td className="px-4 py-2">908.683593750</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">50%</td>
                      <td className="px-4 py-2">919.013183593</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">75%</td>
                      <td className="px-4 py-2">930.031738281</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">max</td>
                      <td className="px-4 py-2">989.600585937</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* TT2 Tables */}
        <div className="border rounded-lg p-4">
          <h3 className="text-lg font-semibold mb-4">2406CN6_TT2 Statistics</h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="bg-gray-900 text-white p-4 rounded-lg">
              <h4 className="text-sm font-semibold mb-2 !text-white">
                Forecast
              </h4>
              <div className="overflow-x-auto">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="text-left px-4 py-2"> </th>
                      <th className="text-left px-4 py-2"> Val</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-4 py-2">count</td>
                      <td className="px-4 py-2">1000.0</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">mean</td>
                      <td className="px-4 py-2">908.290778691</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">std</td>
                      <td className="px-4 py-2">13.401796501</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">min</td>
                      <td className="px-4 py-2">867.972555160</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">25%</td>
                      <td className="px-4 py-2">899.078865408</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">50%</td>
                      <td className="px-4 py-2">908.069892108</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">75%</td>
                      <td className="px-4 py-2">917.263613943</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">max</td>
                      <td className="px-4 py-2">943.059479951</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="bg-gray-900 text-white p-4 rounded-lg">
              <h4 className="text-sm font-semibold mb-2 !text-white">Actual</h4>
              <div className="overflow-x-auto">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="text-left px-4 py-2"> </th>
                      <th className="text-left px-4 py-2">Val</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-4 py-2">count</td>
                      <td className="px-4 py-2">899.0</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">mean</td>
                      <td className="px-4 py-2">912.381005909</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">std</td>
                      <td className="px-4 py-2">20.298659914</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">min</td>
                      <td className="px-4 py-2">864.929687500</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">25%</td>
                      <td className="px-4 py-2">898.609375000</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">50%</td>
                      <td className="px-4 py-2">912.152343750</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">75%</td>
                      <td className="px-4 py-2">924.652343750</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">max</td>
                      <td className="px-4 py-2">993.054687500</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* PI2 Tables */}
        <div className="border rounded-lg p-4">
          <h3 className="text-lg font-semibold mb-4">2406CN6_PI2 Statistics</h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="bg-gray-900 text-white p-4 rounded-lg">
              <h4 className="text-sm font-semibold mb-2 !text-white">
                Forecast
              </h4>
              <div className="overflow-x-auto">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="text-left px-4 py-2"> </th>
                      <th className="text-left px-4 py-2"> Val</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-4 py-2">count</td>
                      <td className="px-4 py-2">1000.0</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">mean</td>
                      <td className="px-4 py-2">-177.123804434</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">std</td>
                      <td className="px-4 py-2">6.635895019</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">min</td>
                      <td className="px-4 py-2">-199.162624120</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">25%</td>
                      <td className="px-4 py-2">-181.054034560</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">50%</td>
                      <td className="px-4 py-2">-176.574209570</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">75%</td>
                      <td className="px-4 py-2">-172.494483172</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">max</td>
                      <td className="px-4 py-2">-159.325013637</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="bg-gray-900 text-white p-4 rounded-lg">
              <h4 className="text-sm font-semibold mb-2 !text-white">Actual</h4>
              <div className="overflow-x-auto">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="text-left px-4 py-2"> </th>
                      <th className="text-left px-4 py-2">Val</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-4 py-2">count</td>
                      <td className="px-4 py-2">900.0</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">mean</td>
                      <td className="px-4 py-2">-179.032621527</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">std</td>
                      <td className="px-4 py-2">10.782333123</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">min</td>
                      <td className="px-4 py-2">-232.8125</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">25%</td>
                      <td className="px-4 py-2">-184.546875</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">50%</td>
                      <td className="px-4 py-2">-178.429687</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">75%</td>
                      <td className="px-4 py-2">-172.308593</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2">max</td>
                      <td className="px-4 py-2">-151.214843</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DummyCementResult;
