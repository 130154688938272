import { createSlice } from '@reduxjs/toolkit';
type System = {
    config: any;
    systems: any;
  };
// Initial state
const initialState = {
  systems: []as System[], // Array to hold the list of systems
};

// Create the slice
const systemsSlice = createSlice({
  name: 'systems',
  initialState,
  reducers: {
    setSystems: (state, action) => {
        // Replace the entire list of systems with the new data
        state.systems = action.payload.map((newSystem: System) => {
          const existingSystem = state.systems.find(system => system.systems.systemId === newSystem.systems.systemId);
          return newSystem;
          // console.log('existingSystem :', existingSystem);
          // if (existingSystem) {
          //   // If system exists, update the config, otherwise keep the name intact
          //   return { ...existingSystem, config: newSystem.config };
          // } else {
          //   // If system does not exist, add the new system
          //   return newSystem;
          // }
        })},
    addSystem: (state, action) => {
      state.systems.push(action.payload); // Add a new system to the list
    },
    removeSystem: (state, action) => {
      state.systems = state.systems.filter((system) => system.systems.systemId !== action.payload.systemId); // Remove system by systemId
    },
    clearSystems: (state) => {
      state.systems = []; // Clear all systems from the list
    },
  },
});

// Export actions
export const { setSystems, addSystem, removeSystem, clearSystems } = systemsSlice.actions;

// Export the reducer
export default systemsSlice.reducer;