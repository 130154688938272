import { Button, Input, Space, Table } from "antd";
import React, { useRef, useState } from "react";
import type { InputRef, TableColumnType, TableColumnsType } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
// import { FilterDropdownProps } from 'antd/es/table/interface';
// import { DataIndex } from 'rc-table/lib/interface';
// import { getRequest, putRequest } from '../../utils/apiHandler';
// import { useLocation, useNavigate } from 'react-router-dom';
interface IdentificationModeTableProps {
  clusteredData: any;
}

const IdentificationModelClusterTable: React.FC<IdentificationModeTableProps> = ({ clusteredData }) => {
// const searchInput = useRef<InputRef>(null);
//   if (!clusteredData || clusteredData.length === 0) {
//     return <div>No data available</div>;
//   }


  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters:any) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string): TableColumnType<any> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (!record[dataIndex]) {
        return
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    }
  });




  let rowdata = clusteredData.map((item: any, index: number) => ({
    ...item,
    index: index + 1,
  }));

  const columnsData = Object.keys(clusteredData[0]);
  let columns = [
    {
      title: "Row",
      dataIndex: "index",
      key: "index",
    },
    ...columnsData.map((res: string) => ({
      title: res,
      dataIndex: res,
      key: res,
      ...getColumnSearchProps(res)
    })),
  ];



  
  return (
    <div className="overflow-x-auto p-2">
      <Table dataSource={rowdata} columns={columns} rowKey="index" 
                  scroll={{
                x: columnsData.length * 100,
                y: 60 * 10,
            }}
            />
    </div>
  );
};

export default IdentificationModelClusterTable;
