import React, { useEffect, useState } from 'react';
import { Modal, Input, Button, Avatar, Tag, Checkbox, Dropdown, Menu, Radio } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getRequest, postRequest } from '../../utils/apiHandler';
import { useAuth } from "../../context/AuthContext";
import { Notify } from 'notiflix';

interface TaskStatusModalProps {
  visible: boolean;
  onClose: (data: ShareData) => void;
  workflowData: any;
  resultData?: any; // Optional - only present when sharing a result
  shareType: 'workflow' | 'result' | 'runs';
}

interface AccessTypeOption {
  id: string;
  name: 'edit' | 'execute' | 'view';
}

interface User {
  id: string;
  name: string;
  email: string;
  accessType: string; // Stores the ID of the access type
  avatarInitial: string;
  avatarColor?: string;
}

interface ShareData {
  accessType: 'user' | 'tenant';
  selectedUsers: User[]; // List of selected users (only applicable in 'user' mode)
  tenantAccessType: string; // Tenant access type ID (only applicable in 'tenant' mode)
  // generalAccessType: string; // General access type ID
}

const TaskStatusModal: React.FC<TaskStatusModalProps> = ({ 
  visible, 
  onClose, 
  workflowData,
  resultData,
  shareType 
}) => {
  const accessTypeOptions: AccessTypeOption[] = [
    // { id: '1', name: 'Owner' },
    { id: '1', name: 'edit' },
    // { id: '2', name: 'execute' },
    { id: '3', name: 'view' },
  ];

  const [allUsers, setAllUsers] = useState<User[]>([])
  const [searchQuery, setSearchQuery] = useState('');
  const [accessType, setAccessType] = useState<'user' | 'tenant'>('user');
  const [tenantAccessType, setTenantAccessType] = useState<string>('edit'); // Default to 'Viewer' ID
  // const [generalAccessType, setGeneralAccessType] = useState<string>('2'); // Default to 'Viewer' ID
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [userList , setUserList] = useState<any>([])
  const { authState } = useAuth();

  

  useEffect(() => {
    if (visible) {  // Only fetch users when modal is visible
      getUsersList();
    }
  }, [visible])  // Only depend on visible prop

  const getUsersList = async () =>{
    try {
      const response = await getRequest('/user/list')
      if (response?.data?.data) {
        const transformedUsers = response.data.data
          .map((user: any) => {
            if (user.id !== authState?.user?.id) {
              return {
                id: String(user.id),
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
                accessType: "edit",
                avatarInitial: user.first_name.charAt(0).toUpperCase(),
                avatarColor: "#87d068",
              };
            }
            return null;
          })
          .filter(Boolean);
        
        setAllUsers(transformedUsers);
        setUserList(response.data.data);
      }
    } catch (error) {
      console.log('error fetching users:', error);
    }
  }


  const getAccessTypeName = (id: string) => {
    const option = accessTypeOptions.find(opt => opt.name == id);
    return option ? option.name : 'Unknown';
  };

  const handleAccessTypeChange = (userId: string, newAccessTypeName: string) => {
    setAllUsers(prevUsers => prevUsers.map(user => user.id === userId ? { ...user, accessType: newAccessTypeName } : user));
  };

  const handleTenantAccessTypeChange = (newAccessTypeId: string) => {
    setTenantAccessType(newAccessTypeId);
  };


  const handleCheckboxChange = (userId: string, checked: boolean) => {
    setSelectedUserIds(prev =>
      checked ? [...prev, userId] : prev.filter(id => id !== userId)
    );
  };

  const filteredUsers = allUsers.filter(user =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getModalTitle = () => {
    if (shareType === 'runs') {
      return `Share Selected Runs`;
    }
    return `Share "${workflowData?.name}"`;
  };

  const handleShare = async () => {
    const shareData: any = {
      accessType,
      tenantAccessType,
    };
    let selectedUsers = allUsers?.filter(user => selectedUserIds.includes(user?.id))

    let payload: any = {
      share_to: accessType == 'user' ? 'users' : 'tenant',
      shared_to_id: selectedUsers,
      share_type: shareType,
      run_ids: shareType === 'runs' ? resultData.runIds : undefined,
      share_type_id: shareType === 'workflow' ? workflowData?.id : undefined,
      workflow_id: workflowData?.id,
      access_level: accessType == 'tenant' ? tenantAccessType : undefined
    }

    try {
      const endpoint = shareType === 'workflow' ? '/shared-workflow' : '/shared-result';
      let response = await postRequest(endpoint, payload)
      if(response.status == 200){
        Notify.success(`${shareType === 'workflow' ? 'Workflow' : 'Runs'} shared successfully`, 
          { timeout: 2000, distance: '50px', clickToClose: true }
        )
      }
    } catch (error) {
      Notify.failure("Something went wrong", { 
        timeout: 2000, 
        distance: '50px',
        clickToClose: true 
      });
    }

    onClose(shareData);
    setSelectedUserIds([]);
    setAccessType('user');
  };

  return (
    <Modal
      open={visible}
      onCancel={() => onClose({ accessType, selectedUsers: [], tenantAccessType })}
      footer={null}
      closeIcon={<span className="text-gray-500">×</span>}
      className="modal-custom"
      width={400}
      style={{ maxHeight: '80vh' }}
    >
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-base font-semibold text-gray-800">
            {getModalTitle()}
          </h2>
        </div>
        <div className="flex justify-between items-center mb-2">
          <Radio.Group
            value={accessType}
            onChange={(e) => setAccessType(e.target.value)}
          >
            <Radio value="user">User</Radio>
            <Radio value="tenant">Tenant</Radio>
          </Radio.Group>
        </div>

        <Input
          placeholder="Add people, groups, and calendar events"
          className="mb-4 border-purple-500 focus:border-purple-500 focus:ring-2 focus:ring-purple-200"
          prefix={<span className="text-gray-400">+</span>}
          value={searchQuery}
          disabled={accessType !== 'user'}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <div className="mb-4">
          <h3 className="text-sm font-medium text-gray-700">
            {accessType === 'user' ? 'People with access' : 'Tenant with access'}
          </h3>
          {accessType === 'user' ? (
            <>
              <div className="max-h-60 overflow-y-auto h-60">
                {filteredUsers.map((user) => (
                  <div key={user.id} className="flex items-center justify-between py-2">
                    <div className="flex items-center gap-2">
                      <Checkbox
                        className="mr-2"
                        checked={selectedUserIds.includes(user.id)}
                        onChange={(e) => handleCheckboxChange(user.id, e.target.checked)}
                      />
                      <Avatar
                        size="small"
                        style={{ backgroundColor: user.avatarColor || '#d1d5db' }}
                      >
                        {user.avatarInitial}
                      </Avatar>
                      <div>
                        <p className="text-sm text-gray-800">{user.name}</p>
                        <p className="text-xs text-gray-500">{user.email}</p>
                      </div>
                    </div>
                    <Dropdown
                      overlay={
                        <Menu>
                          {accessTypeOptions.map(option => (
                            <Menu.Item key={option.id} onClick={() => handleAccessTypeChange(user.id, option.name)}>
                              {option.name}
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Tag color={user.accessType === 'edit' ? 'blue' : 'green'} className="text-xs cursor-pointer flex items-center gap-1  bg-[#F9FAFB]">
                        {getAccessTypeName(user.accessType)} <DownOutlined />
                      </Tag>
                    </Dropdown>
                  </div>
                ))}
              </div>
            </>


          ) : (
            <div className="py-2 h-20">
              <div className="h-20">
                <div className="mb-4">
                  <h3 className="text-sm font-semibold text-gray-800 mt-1 mb-2 flex items-center">
                    <span className="mr-2">🔒</span> General Access
                  </h3>

                  <div className="flex items-center justify-between bg-gray-100 p-2 rounded-lg shadow-sm border border-gray-300 transition-all">
                    <span className="text-sm font-medium text-gray-700">Assign access to tenant all users</span>

                    <Dropdown
                      overlay={
                        <Menu className="shadow-lg rounded-md">
                          {accessTypeOptions
                            // .filter(opt => opt.name !== "View")
                            .map(option => (
                              <Menu.Item
                                key={option.id} onClick={() => handleTenantAccessTypeChange(option?.name)}
                                className="px-4 py-2 flex items-center gap-2 hover:bg-gray-100 transition"
                              >
                                <span className="text-sm text-gray-900">{option.name}</span>
                              </Menu.Item>
                            ))}
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Tag color={tenantAccessType === 'edit' ? 'blue' : 'green'} className="text-xs cursor-pointer flex items-center gap-1">
                        {getAccessTypeName(tenantAccessType)} <DownOutlined />
                      </Tag>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>


        <div className="flex justify-between items-center">
          <Button
            type="primary"
            className="bg-purple-600 hover:bg-purple-700 ml-auto"
            onClick={handleShare}
          >
            Share
          </Button>
        </div>

      </div>
    </Modal>
  );
};

export default TaskStatusModal;