import React, { useState, useEffect } from "react";
import {
  QueryBuilder as ReactQueryBuilder,
  formatQuery,
} from "react-querybuilder";
import { useLocation, useNavigate } from "react-router-dom";
import "react-querybuilder/dist/query-builder.css";
import { useAuth } from "../../context/AuthContext";
import { Select, Button, Tooltip, Checkbox, Switch, Input, Radio, DatePicker } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { getRequest, putRequest } from "../../utils/apiHandler";
import { useSelector } from "react-redux";
import { Notify } from "notiflix";
import EditNameModal from "../Modal/EditNameModal";
import EditConfigSaveModal from "../Modal/EditConfigSaveModal";
import FilterModal from "../Modal/EditFilterName";
import dayjs, { Dayjs } from 'dayjs';

interface QueryBuilderProps {
  onSave: (
    queryData: any,
    targetQueryData: any,
    fields?: any,
    filter_id?: any,
    filterModified?: any
  ) => void;
  fields: string[];
  fileName: string;
  existingQuery: any;
  preSetQueryConfigurations: any;
  setPreSetOperationConfigurations: any;
  operationType: any;
  workflowFilterOptions?: any[];
  fetchWorkflowFilter: (data?: any) => void;
  goldenFilterId:any
  dateRange: any
  setDateRange: any
  isSharedworkflow:any
  saveAtExecute: (
    queryData: any,
    targetQueryData: any,
  ) => void;
}

interface ChunkedRow {
  field: string;
  index: number;
}

function QueryBuilder({
  onSave,
  fields,
  fileName,
  existingQuery,
  preSetQueryConfigurations,
  operationType,
  setPreSetOperationConfigurations,
  workflowFilterOptions = [],
  fetchWorkflowFilter,
  goldenFilterId,
  dateRange,
  setDateRange,
  isSharedworkflow,
  saveAtExecute
}: QueryBuilderProps) {
  let formattedBuilderFields = fields?.map((field: string) => ({
    name: field,
    label: field,
  }));
  const { Option } = Select;
  const { authState } = useAuth();
  const userType = authState.user?.first_name?.toLowerCase() || "";
  const { RangePicker } = DatePicker;
  // const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  
  const [query, setQuery] = useState(
    existingQuery && Object.keys(existingQuery).length
      ? existingQuery
      : { combinator: "and", rules: [] }
  );
  const [globalQuery, setGlobalQuery] = useState(
    existingQuery?.global_query && Object.keys(existingQuery?.global_query).length
      ? existingQuery?.global_query
      : { combinator: "and", rules: [] }
  );
  const [targetQuery, setTargetQuery] = useState(
    existingQuery?.target_variable_settings || { combinator: "and", rules: [] }
  );
  const [excludeFeatures, setExcludeFeatures] = useState<string[]>(
    existingQuery?.exclude_features || []
  );
  const [showDirectQuery, setShowDirectQuery] = useState(false);
  const [directQuery, setDirectQuery] = useState("");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [filterData, setFilterData] = useState<any>(null);
  const [customFilterData, setCustomFilterData] = useState<any>(null);
  const [updatedFilterData, setUpdatedFilterData] = useState<any>(null);
  const [formattedFields, setFormattedFields] = useState<any>(formattedBuilderFields);
  const [customFormattedFields, setCustomFormattedFields] = useState<any>(formattedBuilderFields);
  const [globalFields, setGlobalFields] = useState<any>(null);
  const sampleQuery = `Identify the Golden Batch (June–Sept 2024) based on input material quality, excluding sequence numbers, with pH 5.67–5.77.`;
  const selectSystems = useSelector((state: any) => state.systems.systems);
  const [selectedFilterId, setSelectedFilterId] = useState<number | null>(null);
  const [selectedCustomFilterId, setSelectedCustomFilterId] = useState<number | null>(null);
  const [createNewFilter, setCreateNewFilter] = useState(true);
  const [filterModal, setFilterModal] = useState(false);
  const location = useLocation();
  const [setAsDefault, setSetAsDefault] = useState(false);
  const tenantId = authState.user?.tenant_id;
  const [saveMode, setSaveMode] = useState("default");
  const [isCustomSaveMode, setIsCustomSaveMode] = useState(false);
  const [customSaveMode, setCustomSaveMode] = useState("default");
  const [filterName1, setFilterName1] = useState<any>('');
  const [filterName2, setFilterName2] = useState<any>('');

  // To update the formattedfields in case we have delay for setting fields data
  useEffect(() => {
    let formattedBuilderFields = fields?.map((field: string) => ({
      name: field,
      label: field,
    }));
    setCustomFormattedFields(formattedBuilderFields)
    setFormattedFields(formattedBuilderFields)
  }, [fields])

  useEffect(() => {
    const checkDefaultFilter = async () => {
            const defaultFilter = workflowFilterOptions.find((filter) => {
        const allSystemsMatch = selectSystems[0]?.systems.every((system: any) =>
          filter.systems_id.includes(system.systemId)
        );

        return (
          filter.tenant_id === tenantId &&
          filter.is_default_setting === true &&
          allSystemsMatch
        );
      });

      if (defaultFilter) {
        let result = [];
        let filteredTargetRules = [];
        let filteredRules = [];
        console.log("Default setting found:", defaultFilter);
        const response = await getRequest(`/filter/${defaultFilter.id}`);
        if (response?.data?.data[0]?.filters?.file) {
          let filterConfig = {
            id: response?.data?.data[0]?.id,
            filter: response.data.data[0].filters.file,
          };

          // Check on change and apply here
          if(!existingQuery)
            setFilterData(filterConfig);
          let excludedColumns =
            response.data.data[0].filters.file.exclude_features;
          if (excludedColumns) {
            result = excludedColumns.filter((element: string) =>
              fields.includes(element)
            );
            setExcludeFeatures(result);
          }
          if (
            response?.data?.data[0]?.filters?.file?.target_variable_settings
              ?.rules
          ) {
            let targetRules =
            response.data.data[0].filters.file?.target_variable_settings;
            filteredTargetRules =
              response.data.data[0].filters.file?.target_variable_settings.rules.filter(
                (rule: { field: string }) => fields.includes(rule.field)
              );

            targetRules.rules = filteredTargetRules.length
              ? filteredTargetRules
              : [];
            if(!existingQuery)
              setTargetQuery(targetRules);
          }

          let data = { ...query };
          data.rules = [];
          if (response?.data?.data[0]?.filters?.file?.global?.rules) {
            filteredRules = response?.data?.data[0]?.filters?.file?.global?.rules;
            data.rules = filteredRules ? filteredRules : [];
          }
    
          if (response?.data?.data[0]?.is_default_setting) {
            setSetAsDefault(response?.data?.data[0]?.is_default_setting);
          }

          if(!existingQuery)
            setGlobalQuery(data);

          // let data = { ...query };
          // data.rules = [];
          // if (response?.data?.data[0]?.filters?.file?.rules) {
          //   filteredRules =
          //     response?.data?.data[0]?.filters?.file?.rules.filter(
          //       (rule: { field: string }) => fields.includes(rule.field)
          //     );
          //   data.rules = filteredRules ? filteredRules : [];
          // }

          // if (response?.data?.data[0]?.is_default_setting) {
          //   setSetAsDefault(response?.data?.data[0]?.is_default_setting);
          // }
          // setQuery(data);
          if (
            !result.length &&
            !filteredTargetRules.length &&
            !filteredRules.length
          ) {
            setFilterData(null);
            // Notify.warning(
            //   "Filter columns Not Matched So Filter Is Not Applied"
            // );
          }
          // setSelectedFilterId(filterConfig.id);
        }
      } else {
        // Reset states when no default filter is found
        setFilterData(null);
        setExcludeFeatures([]);
        setTargetQuery({ combinator: "and", rules: [] });
        setQuery({ combinator: "and", rules: [] });
        setSelectedFilterId(null);
        setSetAsDefault(false);
      }
      const columns = await extractFieldsFromConfigurations(selectSystems);
      let newFormattedFields = columns?.map((field: any) => ({
        name: field?.title,
        label: field?.title,
      }));
      setGlobalFields(newFormattedFields)
      // let filteredFields = formattedFields?.filter((item1: any) =>
      //   !newFormattedFields.some(item2 => item1?.name === item2?.name)
      // );
      // console.log('filteredFields', filteredFields)
      // setFormattedFields(filteredFields)
      let combinedFields = [...formattedFields, ...newFormattedFields];
      let uniqueFields = Array.from(new Set(combinedFields.map(field => field.name)))
        .map(name => combinedFields.find(field => field.name === name));
  
      setFormattedFields(uniqueFields);
      
      if(goldenFilterId && operationType=='rca'){
        setFilterData(null);
        setExcludeFeatures([]);
        setTargetQuery({ combinator: "and", rules: [] });
        setQuery({ combinator: "and", rules: [] });
        setSelectedFilterId(goldenFilterId);
        setSetAsDefault(false);
        getFilterAndSetQueryBuilder(goldenFilterId)
      }
    // setGlobalQuery(obdata);
    };
     checkDefaultFilter();
  }, [workflowFilterOptions, selectSystems, tenantId]);

  const extractFieldsFromConfigurations = async (data: any) => {
    const globalConfig = await getRequest('/configurations/get-configuration')
    const globalConfigData = globalConfig.data.data
    const fields = new Set<string>();
    const config = data[0]?.systems?.map((system: any) => ({
      name: system?.systemName,
      config: globalConfigData[system?.systemName] || {}
    })) || [{ name: '', config: {} }];
  
      config.forEach((system: any) => {
        if (system.config?.Processes) {
          Object.values(system.config.Processes).forEach((process: any) => {
            Object.keys(process.parameters || {}).forEach((param) => {
              fields.add(param);
            });
          });
        }
        if (system.config?.TimeGeneralIdentifiers) {
          Object.values(system.config.TimeGeneralIdentifiers).forEach((generic: any) => {
            fields.add(generic);
          });
        }
      });
  
    // Convert the set to an array of FieldOutput objects
    return Array.from(fields).map(field => ({
      key: field,
      title: field,
    }));
  };

  // Check if the user is a process engineer
  const isAutherizedToEditConfiguration =
    authState.user?.role.alias === "process_engineer";

  // Create chunks of 3 for the grid layout with proper typing
  const createChunkedFields = (fields: string[]): string[][] => {
    return (
      fields?.reduce((acc: string[][], curr: string, i: number) => {
        if (i % 3 === 0) {
          acc.push([curr]);
        } else {
          acc[acc.length - 1].push(curr);
        }
        return acc;
      }, []) || []
    );
  };

  const params = new URLSearchParams(location.search);
  const executeFlow = params.get("execute");

  const chunkedFields = createChunkedFields(fields || []);

  const handleCheckboxChange = (columnName: string, isChecked: boolean) => {
    setExcludeFeatures((prev) => {
      if (isChecked) {
        return prev.filter((name) => name !== columnName);
      } else {
        return [...prev, columnName];
      }
    });
  };

  const handleSave = (data?: any) => {
    const updatedQueryData = {
      ...query,
      target_variable_settings: targetQuery,
      exclude_features: excludeFeatures,
      global_query: globalQuery,
    };
        if (data && data == "rca") {
      onSave(updatedQueryData, targetQuery, fields, null);
      return;
    }
    if(saveMode == 'create' && !filterName2.trim()?.length){
      Notify.warning("Please enter global filter name");
      return
    }
    if(customSaveMode == 'create' && !filterName1.trim()?.length){
      Notify.warning("Please enter custom filter name");
      return
    }
    console.log('filterData', filterData)
    if (filterData) {
      const checkdata = deepEqual(updatedQueryData, filterData.filter);
      let filter: any = {
        id: filterData?.id,
        type: checkdata ? "no-change" : "",
        name: filterName1,
        name2: filterName2,
        isDefaultSetting: setAsDefault,
        selectedSystems: selectSystems[0]?.systems,
      };
      if(customFilterData){
        filter.custom_id = customFilterData?.id
      }
      let filterDetail = {
        updatedQueryData: updatedQueryData,
        targetQuery: targetQuery,
        fields: fields,
        filter: filter,
      };
      console.log('checkdata', checkdata)
      if (checkdata) {
        setCreateNewFilter(false);
        console.log('filterrrr', filter)
        if(saveMode == 'update'){
          filter.type = 'update'
        }
        if(customSaveMode == 'update'){
          filter.custom_type = 'update'
        }
        onSave(updatedQueryData, targetQuery, fields, filter);
      }
      if (!checkdata) {
        setCreateNewFilter(false);
        setUpdatedFilterData(filterDetail);
        // setIsEditModalVisible(true);
        console.log('filterrrr', filter)
        if(saveMode == 'create'){
          filter.type = 'create'
          filter.name = filterName1
        }
        else if(saveMode == 'default'){
          filter.type = 'no-change'
        }
        else if(saveMode == 'update'){
          filter.type = 'update'
        }

        if(customSaveMode == 'create'){
          filter.custom_type = 'create'
          filter.name2 = filterName2
        }
        else if(customSaveMode == 'default'){
          filter.custom_type = 'no-change'
        }
        else if(customSaveMode == 'update'){
          filter.custom_type = 'update'
        }
        onSave(updatedQueryData, targetQuery, fields, filter);
      }
    } else {
      let filterDetail = {
        updatedQueryData: updatedQueryData,
        targetQuery: targetQuery,
        fields: fields,
        filter: {
          id: null,
          name: "",
          type: "create",
          isDefaultSetting: setAsDefault,
          selectedSystems: selectSystems[0]?.systems,
        },
      };

      let filter = {
        id: filterData?.id,
        type: saveMode == 'create' ? 'create' : saveMode == 'update' ? 'update' : 'default',
        custom_type: customSaveMode == 'create' ? 'create' : customSaveMode == 'update' ? 'update' : 'default',
        name: filterName1,
        name2: filterName2,
        isDefaultSetting: setAsDefault,
        selectedSystems: selectSystems[0]?.systems,
      };

      setCreateNewFilter(true);
      setUpdatedFilterData(filterDetail);
      // setIsEditModalVisible(true);
      console.log('filter', filter)
      onSave(updatedQueryData, targetQuery, fields, filter);
    }
  };

  function deepEqual<T>(obj1: T, obj2: T): boolean {
    if (obj1 === obj2) return true;
    if (
      typeof obj1 !== "object" ||
      typeof obj2 !== "object" ||
      obj1 === null ||
      obj2 === null
    )
      return false;

    const keys1 = Object.keys(obj1) as (keyof T)[];
    const keys2 = Object.keys(obj2) as (keyof T)[];

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!keys2.includes(key)) return false;
      if (!deepEqual(obj1[key], obj2[key])) return false;
    }

    return true;
  }

  const handleEditCreate = (filterDetails: any) => {
    onSave(
      filterDetails.updatedQueryData,
      filterDetails.targetQuery,
      filterDetails.fields,
      filterDetails.filter
    );
    setIsEditModalVisible(false);
  };

  const handleFilterUpdate = (filterDetails: any) => {
    onSave(
      filterDetails.updatedQueryData,
      filterDetails.targetQuery,
      filterDetails.fields,
      filterDetails.filter
    );
    setIsEditModalVisible(false);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleWorkflowSavedFilter = (id: any) => {
    setSelectedFilterId(id);
    if (id != "-1") {
      // getAndSetSavedFilters(id);
      getFilterAndSetQueryBuilder(id);
      return;
    }
    // when we select no filter empty the excluded features , filter features and target variable
    let targetData = { ...targetQuery };
    targetData.rules = [];
    let data = { ...query };
    data.rules = [];
    setExcludeFeatures([]);
    setTargetQuery(targetData);
    setQuery(data);
    setFilterData(null);
  };

  const handleWorkflowCustomSavedFilter = (id: any) => {
    setSelectedCustomFilterId(id);
    if (id != "-1") {
      // getAndSetSavedFilters(id);
      getFilterAndSetCustomQueryBuilder(id);
      return;
    }
    // when we select no filter empty the excluded features , filter features and target variable
    let targetData = { ...targetQuery };
    targetData.rules = [];
    let data = { ...query };
    data.rules = [];
    setExcludeFeatures([]);
    // setTargetQuery(targetData);
    setQuery(data);
    setFilterData(null);
  };

  useEffect(() => {
    try {
      const params = new URLSearchParams(location.search);
      const workflowId = params.get("workflowId");
      storedWorkflowData(workflowId);
    } catch (error) {}
  }, []);

  const handleFilterEdit = async (filterId: any, filterName: any) => {
    let payload = {
      filterData: filterId,
      updatedName: filterName,
    };

    if (filterId && filterName) {
      let response = await putRequest(
        `/filter/update-filter-name/${filterId}`,
        payload
      );
      fetchWorkflowFilter();
    }
    setFilterModal(false);
  };
  const handleFilterCancel = () => {
    setFilterModal(false);
  };

  const storedWorkflowData = async (id: any) => {
    const response = await getRequest(`/filter/workflow-filter/${id}`);
    if (response?.data?.data[0]?.filters?.file) {
      let filterConfig = {
        id: response?.data?.data[0]?.id,
        filter: response.data.data[0].filters.file,
      };
      setFilterData(filterConfig);
      setSelectedFilterId(filterConfig.id);

      if(response?.data?.data[1]?.filters)
        setSelectedCustomFilterId(response?.data?.data[1]?.id);

      setSetAsDefault(response?.data?.data[0]?.is_default_setting);
      setExcludeFeatures(response.data.data[0]?.filters?.file?.exclude_features || [])
      setQuery(
        existingQuery && Object.keys(existingQuery).length
          ? existingQuery
          : { combinator: "and", rules: [] }
      );
    }
  };

  const getFilterAndSetQueryBuilder = async (id: any) => {
    try {
      let result = [];
      let filteredTargetRules = [];
      let filteredRules = [];

      const response = await getRequest(`/filter/${id}`);
      // console.log('response?.data?.data', response?.data?.data)
      if (response?.data?.data[0]?.filters?.file) {
        let filterConfig = {
          id: response?.data?.data[0]?.id,
          filter: response.data.data[0].filters.file,
        };
        setFilterData(filterConfig);
      }
      let excludedColumns = response.data.data[0].filters.file.exclude_features;
      if (excludedColumns) {
        result = excludedColumns.filter((element: string) =>
          fields.includes(element)
        );
        setExcludeFeatures(result);
      }
      if (
        response?.data?.data[0]?.filters?.file?.target_variable_settings?.rules
      ) {
        let targetRules =
          response.data.data[0].filters.file?.target_variable_settings;
        filteredTargetRules =
          response.data.data[0].filters.file?.target_variable_settings.rules.filter(
            (rule: { field: string }) => fields.includes(rule.field)
          );

        targetRules.rules = filteredTargetRules.length
          ? filteredTargetRules
          : [];
        setTargetQuery(targetRules);
      }
      let data = { ...query };
      data.rules = [];
      if (response?.data?.data[0]?.filters?.file?.global?.rules) {
        filteredRules = response?.data?.data[0]?.filters?.file?.global?.rules;
        data.rules = filteredRules ? filteredRules : [];
      }

      if (response?.data?.data[0]?.is_default_setting) {
        setSetAsDefault(response?.data?.data[0]?.is_default_setting);
      }

      setGlobalQuery(data);
      if (
        !result.length &&
        !filteredTargetRules.length &&
        !filteredRules.length
      ) {
        setFilterData(null);
        Notify.warning("Filter columns Not Matched So Filter Is Not Applied");
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  const getFilterAndSetCustomQueryBuilder = async (id: any) => {
    try {
      let result = [];
      let filteredTargetRules = [];
      let filteredRules = [];

      const response = await getRequest(`/filter/${id}`);
      console.log('response?.data?.data', response?.data?.data)
      if (response?.data?.data[0]?.filters?.file) {
        let filterConfig = {
          id: response?.data?.data[0]?.id,
          filter: response.data.data[0].filters.file,
        };
        setCustomFilterData(filterConfig);
      }
      let excludedColumns = response.data.data[0].filters.file.exclude_features;
      if (excludedColumns) {
        result = excludedColumns.filter((element: string) =>
          fields.includes(element)
        );
        setExcludeFeatures(result);
      }
      let data = { ...query };
      data.rules = [];
      if (response?.data?.data[0]?.filters?.file?.rules) {
        filteredRules = response?.data?.data[0]?.filters?.file?.rules.filter(
          (rule: { field: string }) => fields.includes(rule.field)
        );
        data.rules = filteredRules ? filteredRules : [];
      }

      if (response?.data?.data[0]?.is_default_setting) {
        setSetAsDefault(response?.data?.data[0]?.is_default_setting);
      }

      console.log('data', data)
      setQuery(data);
      if (
        !result.length &&
        !filteredTargetRules.length &&
        !filteredRules.length
      ) {
        setFilterData(null);
        Notify.warning("Filter columns Not Matched So Filter Is Not Applied");
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  const getAndSetSavedFilters = async (id: any) => {
    try {
      let result = [];
      let filteredTargetRules = [];
      let filteredRules = [];

      const response = await getRequest(`/filter/${id}`);
      console.log('response?.data?.data', response?.data?.data)
      if (response?.data?.data[0]?.filters?.file) {
        let filterConfig = {
          id: response?.data?.data[0]?.id,
          filter: response.data.data[0].filters.file,
        };
        setFilterData(filterConfig);
      }
      let excludedColumns = response.data.data[0].filters.file.exclude_features;
      if (excludedColumns) {
        result = excludedColumns.filter((element: string) =>
          fields.includes(element)
        );
        setExcludeFeatures(result);
      }
      if (
        response?.data?.data[0]?.filters?.file?.target_variable_settings?.rules
      ) {
        let targetRules =
          response.data.data[0].filters.file?.target_variable_settings;
        filteredTargetRules =
          response.data.data[0].filters.file?.target_variable_settings.rules.filter(
            (rule: { field: string }) => fields.includes(rule.field)
          );

        targetRules.rules = filteredTargetRules.length
          ? filteredTargetRules
          : [];
        setTargetQuery(targetRules);
      }
      let data = { ...query };
      data.rules = [];
      if (response?.data?.data[0]?.filters?.file?.rules) {
        filteredRules = response?.data?.data[0]?.filters?.file?.rules.filter(
          (rule: { field: string }) => fields.includes(rule.field)
        );
        data.rules = filteredRules ? filteredRules : [];
      }

      if (response?.data?.data[0]?.is_default_setting) {
        setSetAsDefault(response?.data?.data[0]?.is_default_setting);
      }

      console.log('data', data)
      setQuery(data);
      if (
        !result.length &&
        !filteredTargetRules.length &&
        !filteredRules.length
      ) {
        setFilterData(null);
        Notify.warning("Filter columns Not Matched So Filter Is Not Applied");
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  useEffect(() => {
    setQuery(
      existingQuery && Object.keys(existingQuery).length
        ? existingQuery
        : { combinator: "and", rules: [] }
    );
    setTargetQuery(
      existingQuery?.target_variable_settings || {
        combinator: "and",
        rules: [],
      }
    );
    setExcludeFeatures(existingQuery?.exclude_features || []);
  }, [workflowFilterOptions,fileName, existingQuery]);

  useEffect(() => {
    if (!existingQuery) {
      let data = { ...query };

      if (selectSystems[0]?.config?.length > 0) {
        const allRules = selectSystems[0].config.flatMap(
          (configItem: { config: { configurations: { rules: any } } }) =>
            configItem?.config?.configurations?.rules || []
        );
        const filteredRules = allRules.filter((rule: { field: string }) =>
          fields.includes(rule.field)
        );
        data.rules = filteredRules ? filteredRules : [];
      }
      setQuery(data);
    }
  }, []);

  const saveAtExecuteMode = (newQuery: any) => {
    saveAtExecute(newQuery, targetQuery);
  }

  const handleDateRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    console.log('datessss', dates)
    if (dates) {
      const startDate = dates[0];
      const endDate = dates[1] ? dates[1].endOf('day') : null; // Use null if dates[1] is undefined
      setDateRange([startDate, endDate]);

    } else {
      setDateRange([null, null]);
    }
  };

  useEffect(() => {
    console.log('dateRange', dateRange)
  },[dateRange, setDateRange])


  const handleToggleCustomChange = (checked: boolean) => {
    setIsCustomSaveMode(checked);
  };

  const renderPresetConfigurations = () => {
    const params = new URLSearchParams(location.search);
    const executeFlow = params.get("execute");

    if (
      !preSetQueryConfigurations ||
      Object.keys(preSetQueryConfigurations).length == 0
    )
      return (
        <div className="flex items-center justify-center h-full text-center bg-gray-100 text-gray-600 font-medium text-lg p-6 rounded-md shadow-md">
          No Golden Parameters defined in RCA Operations
        </div>
      );

    return (
      <div className="p-5">
        {/* Column Selection Section */}
        <h3 className="mb-3">Selected Columns</h3>
        <div className="mb-4 border p-4 rounded bg-gray-50">
          {chunkedFields.map((row: string[], rowIndex: number) => (
            <div key={rowIndex} className="flex mb-2">
              {row.map((field: string) => (
                <div key={field} className="w-1/3 flex items-center">
                  <input
                    type="checkbox"
                    id={field}
                    checked={
                      !preSetQueryConfigurations.exclude_features?.includes(
                        field
                      )
                    }
                    disabled
                    className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 cursor-not-allowed opacity-60"
                  />
                  <label htmlFor={field} className="text-sm text-gray-700">
                    {field}
                  </label>
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* Feature Filters Section */}
        <h3 className="mb-1">Feature Filters</h3>
        <div className="border p-4 rounded mb-4 bg-gray-50">
          {Object.entries(preSetQueryConfigurations.feature_filters || {}).map(
            ([feature, filter]: [string, any]) => (
              <div
                key={feature}
                className="mb-3 p-3 bg-white  shadow-sm bg-[rgba(0,75,183,0.2)] px-[15px] py-[10px] rounded-md border border-[#8081a2] "
              >
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <label className="block text-sm font-medium text-gray-700">
                      Field
                    </label>
                    <input
                      type="text"
                      value={feature}
                      disabled
                      className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700 cursor-not-allowed"
                    />
                  </div>
                  <div className="flex-1">
                    <label className="block text-sm font-medium text-gray-700">
                      Operator
                    </label>
                    <input
                      type="text"
                      value={filter.operator}
                      disabled
                      className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700 cursor-not-allowed"
                    />
                  </div>
                  <div className="flex-1">
                    <label className="block text-sm font-medium text-gray-700">
                      Value
                    </label>
                    <input
                      type="text"
                      value={
                        Array.isArray(filter.value)
                          ? filter.value.join(" - ")
                          : filter.value
                      }
                      disabled
                      className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700 cursor-not-allowed"
                    />
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        {/* Target Variable Settings Section */}
        <h3 className="mt-1">Target Variable Settings</h3>
        <div className="border p-4 rounded bg-yellow-100">
          {Object.entries(
            preSetQueryConfigurations.target_variable_settings || {}
          ).map(([variable, settings]: [string, any]) => (
            <div
              key={variable}
              className="mb-3 p-3 bg-white bg-[rgba(0,75,183,0.2)] px-[15px] py-[10px] rounded-md border border-[#8081a2]  shadow-sm"
            >
              <div className="flex items-center gap-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Variable
                  </label>
                  <input
                    type="text"
                    value={variable}
                    disabled
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700 cursor-not-allowed"
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Operator
                  </label>
                  <input
                    type="text"
                    value={settings.operator}
                    disabled
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700 cursor-not-allowed"
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Value
                  </label>
                  <input
                    type="text"
                    value={
                      Array.isArray(settings.value)
                        ? settings.value.join(" - ")
                        : settings.value
                    }
                    disabled
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700 cursor-not-allowed"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        {!executeFlow && (
          <button
            onClick={() => {
              handleSave("rca");
            }}
            className="btn btn-primary-new mt-4 mb-4"
          >
            Save
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="p-5">
      {operationType == "rcarca" ? (
        renderPresetConfigurations()
      ) : (
        <>
          {userType === "chemical" && (
            <div className="flex items-center mb-4 absolute right-5">
              <input
                type="checkbox"
                id="directQueryToggle"
                checked={showDirectQuery}
                onChange={(e) => setShowDirectQuery(e.target.checked)}
                className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <label
                htmlFor="directQueryToggle"
                className="text-sm text-gray-700 cursor-pointer"
              >
                Input query directly
              </label>
            </div>
          )}

          {showDirectQuery ? (
            <div className="mb-4">
              <div className="flex items-center mb-2">
                <label className="text-sm text-gray-700 mr-2">
                  Insert Query Here:
                </label>
                <div className="relative group">
                  <svg
                    className="w-5 h-5 text-gray-600 cursor-help"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div className="absolute hidden group-hover:block bg-white border p-4 rounded shadow-lg z-10 w-[40rem] top-6 left-0">
                    <pre className="text-sm whitespace-pre-wrap">
                      {sampleQuery}
                    </pre>
                  </div>
                </div>
              </div>
              <textarea
                rows={6}
                value={directQuery}
                onChange={(e) => setDirectQuery(e.target.value)}
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your query here..."
              />
            </div>
          ) : (
            <>
              <div className="d-flex">
                <div>
                  <h3 className="mb-1">Select Columns for {fileName}</h3>
                </div>

              </div>
              <div
                className={`mb-4 border p-4 rounded ${
                  executeFlow ? "pointer-events-none bg-gray-50" : ""
                }`}
              >
                {chunkedFields.map((row: string[], rowIndex: number) => (
                  <div key={rowIndex} className="flex mb-2">
                    {row.map((field: string) => (
                      <div key={field} className="w-1/3 flex items-center">
                        <input
                          type="checkbox"
                          id={field}
                          checked={!excludeFeatures.includes(field)}
                          onChange={(e) =>
                            handleCheckboxChange(field, e.target.checked)
                          }
                          disabled={!!executeFlow || operationType=='rca'}
                          className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <label
                          htmlFor={field}
                          className="text-sm text-gray-700 cursor-pointer"
                        >
                          {field}
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <div className="border p-4 rounded mb-2 bg-yellow-100">
                
                <div className="flex">
                  <h3 className="mb-1">Global Query Builder for {fileName}</h3>
                  {/* <div className="w-56 ml-auto mr-20 mb-3 gap-2">
                    <Switch
                      checkedChildren="Save"
                      unCheckedChildren="Update"
                      checked={isSaveMode}
                      onChange={handleToggleChange}
                    />
                  </div> */}
                  <div className="ml-auto mr-20 mb-3 gap-2">
                    <Radio.Group
                      onChange={(e) => setSaveMode(e.target.value)}
                      value={saveMode}
                      disabled={operationType=='rca'}
                    >
                      <Radio value="create">Edit & Create New</Radio>
                      <Radio value="update">Edit & Update</Radio>
                      <Radio value="default">Default</Radio>
                    </Radio.Group>
                  </div>
                    {saveMode != 'create' ? (
                      <div className="w-56 ml-auto mr-20 mb-3  flex items-center gap-2">
                        <Select
                          placeholder="Apply workflow filters"
                          className="select-file w-56"
                          onChange={handleWorkflowSavedFilter}
                          value={selectedFilterId}
                          showSearch={true}
                          disabled={!!executeFlow || operationType=='rca'}
                          optionFilterProp="children"
                          filterSort={(optionA, optionB) => {
                            const labelA = (optionA?.children ?? "")
                              .toString()
                              .toLowerCase();
                            const labelB = (optionB?.children ?? "")
                              .toString()
                              .toLowerCase();
                            if (labelA === "no filter") return -1;
                            if (labelB === "no filter") return 1;
                            return labelA.localeCompare(labelB);
                          }}
                        >
                          <Option key={Date.now()} value={-1}>
                            No Filter
                          </Option>
                          {workflowFilterOptions
                          .filter((filter: any) => filter.filter_type === 'global')
                          .map((filter: any) => (
                            <Option key={filter.id} value={filter.id}>
                              {filter.name}
                            </Option>
                          ))}
                        </Select>
                        {!executeFlow && (
                          <Tooltip title="Rename Filter">
                            <EditOutlined
                              style={{ cursor: "pointer", color: "#252963" }}
                              onClick={() => setFilterModal(true)}
                            />
                          </Tooltip>
                        )}
                        {/* <div className="mt-3">
                          <Button onClick={() => setFilterModal(true)}>Rename Filter</Button>
                        </div> */}
                      </div>
                    ) : (
                      <div className="w-56 ml-auto mr-20 mb-3  flex items-center gap-2">
                        <Input
                          placeholder="Enter filter name"
                          value={filterName2}
                          onChange={(e) => setFilterName2(e.target.value)}
                        />
                      </div>
                    )}
                </div>
                
                <div
                  className={`border p-4 rounded ${
                    executeFlow ? "pointer-events-none disable-builder" : ""
                  }`}
                >
                  <ReactQueryBuilder
                    fields={tenantId == 2 ? customFormattedFields : globalFields}
                    query={globalQuery}
                    onQueryChange={setGlobalQuery as any}
                  />
                </div>

                <h3 className="mt-1">Set Target Variable {fileName}</h3>
                <div
                  className={`border p-4 rounded ${
                    executeFlow ? "pointer-events-none disable-builder" : ""
                  }`}
                >
                  <ReactQueryBuilder
                    fields={formattedFields}
                    query={targetQuery}
                    onQueryChange={setTargetQuery as any}
                  />
                </div>
                
              </div>
              {
                  operationType !='rca' &&
                  <>
                  <div className="flex mt-4">
                    <h3 className="mb-1">Custom Query Builder for {fileName}</h3>
                    {/* <div className="w-56 ml-auto mr-20 mb-3 gap-2">
                      <Switch
                        checkedChildren="Save"
                        unCheckedChildren="Update"
                        checked={isCustomSaveMode}
                        onChange={handleToggleCustomChange}
                      />
                    </div> */}
                      <div className="ml-auto mr-20 mb-3 gap-2">
                        <Radio.Group
                          onChange={(e) => setCustomSaveMode(e.target.value)}
                          value={customSaveMode}
                        >
                          <Radio value="create">Edit & Create New</Radio>
                          <Radio value="update">Edit & Update</Radio>
                          <Radio value="default">Default</Radio>
                        </Radio.Group>
                      </div>
                      {customSaveMode != 'create' ? (
                        <div className="w-56 ml-auto mr-20 mb-3  flex items-center gap-2">
                          <Select
                            placeholder="Apply workflow filters"
                            className="select-file w-56"
                            onChange={handleWorkflowCustomSavedFilter}
                            value={selectedCustomFilterId}
                            showSearch={true}
                            disabled={!!executeFlow}
                            optionFilterProp="children"
                            filterSort={(optionA, optionB) => {
                              const labelA = (optionA?.children ?? "")
                                .toString()
                                .toLowerCase();
                              const labelB = (optionB?.children ?? "")
                                .toString()
                                .toLowerCase();
                              if (labelA === "no filter") return -1;
                              if (labelB === "no filter") return 1;
                              return labelA.localeCompare(labelB);
                            }}
                          >
                            <Option key={Date.now()} value={-1}>
                              No Filter
                            </Option>
                            {workflowFilterOptions
                            .filter((filter: any) => filter.filter_type === 'custom')
                            .map((filter: any) => (
                              <Option key={filter.id} value={filter.id}>
                                {filter.name}
                              </Option>
                            ))}
                          </Select>
                          {!executeFlow && (
                            <Tooltip title="Rename Filter">
                              <EditOutlined
                                style={{ cursor: "pointer", color: "#252963" }}
                                onClick={() => setFilterModal(true)}
                              />
                            </Tooltip>
                          )}
                          {/* <div className="mt-3">
                            <Button onClick={() => setFilterModal(true)}>Rename Filter</Button>
                          </div> */}
                        </div>
                      ) : (
                        <div className="w-56 ml-auto mr-20 mb-3  flex items-center gap-2">
                          <Input
                            placeholder="Enter filter name"
                            value={filterName1}
                            onChange={(e) => setFilterName1(e.target.value)}
                          />
                        </div>
                      )}
                  </div>
                  <div className="mb-2 flex items-center bg-[#d5d5e9] p-2 rounded-md">
                    <h2>DateTime: </h2>
                    <RangePicker
                      // showTime
                      onChange={handleDateRangeChange}
                      value={dateRange}
                      className="w-[400px] ml-5"
                      placeholder={['Start Date', 'End Date']} // Placeholder for empty state
                    />
                  </div>
                    <div
                      className={`border p-4 rounded ${
                        false ? "pointer-events-none disable-builder" : ""
                      }`}
                    >
                      <ReactQueryBuilder
                        fields={customFormattedFields}
                        query={query}
                        // onQueryChange={setQuery as any}
                        onQueryChange={(newQuery) => {
                          setQuery(newQuery);
                          if (executeFlow) {
                            saveAtExecuteMode(newQuery);
                          }
                        }}
                      />
                    </div>
    
    
                  {/* Render checkbox if user is a process engineer */}
                  {isAutherizedToEditConfiguration && (
                    <div className="mb-4 mt-4">
                      <Checkbox
                        checked={setAsDefault}
                        disabled={!!executeFlow}
                        onChange={(e) => setSetAsDefault(e.target.checked)}
                      >
                        Set as Default Configuration
                      </Checkbox>
                    </div>
                  )}
                  </>
              }
              {!executeFlow && (
                <button
                  onClick={handleSave}
                  className="btn btn-primary-new mt-4 mb-6"
                >
                  Save
                </button>
              )}
            </>
          )}
        </>
      )}
      <EditConfigSaveModal
        closeFilter={setIsEditModalVisible}
        visible={isEditModalVisible}
        filterDetails={updatedFilterData}
        createNew={createNewFilter}
        onConfirm={handleEditCreate}
        onUpdate={handleFilterUpdate}
        onCancel={handleEditCancel}
        currentConfigName={(filterData as { name?: string } | null)?.name || ""}
      />
      <FilterModal
        visible={filterModal}
        filters={workflowFilterOptions}
        onConfirm={handleFilterEdit}
        onCancel={handleFilterCancel}
        currentFilter={""}
        currentFilterName={""}
      />
    </div>
  );
}

export default QueryBuilder;
