import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
// import { Layout } from "plotly.js";
import { Button, Collapse, Select, Form, Radio, Flex } from "antd";
import { CaretRightOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Notify } from "notiflix";

interface DynamicSubPlotProps {
    dynamicPlotData: any[];
    setDynamicPlotData: any;
    dynamicPlotLayout: any;
    setDynamicPlotLayout: any;
}

const DynamicSubPlot: React.FC<DynamicSubPlotProps> = function ({
    dynamicPlotData,
    dynamicPlotLayout,
    setDynamicPlotData,
    setDynamicPlotLayout
}) {
    const [showPlot, setShowPlot] = useState(true);

    useEffect(() => {
        console.log("Plot Data", dynamicPlotData);
        console.log("Plot Layout", dynamicPlotLayout);
    }, [dynamicPlotData, dynamicPlotLayout]);

    return (
        // <Flex gap={"small"} style={{ width: '100%', height: '100%', padding: '0 20px' }} >
        <>
            {dynamicPlotData.length > 0 && (
                <div className="ml-4">
                    <Button className="mb-4" type="default" icon={showPlot ? <EyeInvisibleOutlined /> : <EyeOutlined />} onClick={() => setShowPlot(!showPlot)}>{showPlot ? 'Hide Plot' : 'View Plot'}</Button>
                    {showPlot && (<Plot
                        data={dynamicPlotData}
                        layout={dynamicPlotLayout}
                        useResizeHandler={true}
                        style={{ width: '100%', height: '100vh' }}
                    />)}
                </div>
            )}
        </>
        // </Flex>
    );
}

interface DynamicSubPlotConfigProps {
    availableColumns: string[];
    originalFileData: any[];
    setDynamicPlotData: any;
    setDynamicPlotLayout: any;
}

const DynamicSubPlotConfig: React.FC<DynamicSubPlotConfigProps> = function ({
    availableColumns,
    originalFileData,
    setDynamicPlotData,
    setDynamicPlotLayout
}) {
    const [plotOptions, setPlotOptions] = useState<any>([]);
    const [dateOptions, setDateOptions] = useState<any>([]);
    const [xaxis, setXaxis] = useState<any>("");
    const [yaxis, setYaxis] = useState<any>("");
    const [filter, setFilter] = useState<any>("");
    const [subplotType, setSubplotType] = useState<"independent" | "shared">("shared");

    useEffect(() => {
        setPlotOptions(availableColumns.map((col) => ({ label: col, value: col })));
        setDateOptions(availableColumns.filter((col) => col.includes("DateTime")).map((col) => ({ label: col, value: col })));

        return () => {
            setPlotOptions([]);
            setDateOptions([]);
        }
    }, [availableColumns]);

    const createPlottingData = (data: any[]) => {
        if (!data.length) {
            return;
        }
        let _timeseries: any = [];
        let _plotConfig: any = {};
        data.map((row) => {
            // `xaxis` - is the col used for plotting the X-axis values.
            // `yaxis` - is the col used for plotting the Y-axis values for all plots.
            // `filter` - is the col used for filtering the plots.

            // Collect datetime values.
            // _timeseries.push(row[xaxis]);

            if (_plotConfig.hasOwnProperty(row[filter])) {
                _plotConfig[row[filter]][xaxis] = [..._plotConfig[row[filter]][xaxis], row[xaxis]];
                _plotConfig[row[filter]][yaxis] = [..._plotConfig[row[filter]][yaxis], row[yaxis]];
                _plotConfig[row[filter]][filter] = [..._plotConfig[row[filter]][filter], row[filter]];
            } else {
                _plotConfig[row[filter]] = {};
                _plotConfig[row[filter]][xaxis] = [row[xaxis]]
                _plotConfig[row[filter]][yaxis] = [row[yaxis]]
                _plotConfig[row[filter]][filter] = [row[filter]];
            }
        });
        const _plotdata: any[] = [];
        const _subplots: any[] = [];
        let _layout: any = {};
        _layout = {
            grid: {
                columns: 1,
                pattern: "independent",
                roworder: "bottom to top"
            },
            title: {
                text: `${xaxis} vs ${yaxis} vs ${filter}`,
                font: {
                    size: 20,
                    color: '#000000'
                },
            },
            // xaxis: {
            //     title: {
            //         text: xaxis,
            //         font: {
            //             size: 18,
            //             color: '#7f7f7f'
            //         }
            //     }
            // }
        }

        let index = 1;
        for (let [key, val] of Object.entries(_plotConfig)) {
            _plotdata.push(
                {
                    x: (val as any)[xaxis],
                    y: (val as any)[yaxis],
                    type: 'scatter',
                    mode: 'lines+markers',
                    xaxis: "independent" === subplotType ? `x1${index}` : "x",
                    yaxis: `y1${index}`
                }
            );

            if ("independent" === subplotType) {
                _subplots.push([`x1${index}y1${index}`]);
            } else {
                _subplots.push([`xy1${index}`]);
            }
            _layout[`yaxis1${key}`] = {
                title: {
                    text: `${filter}: ${key}`,
                    font: {
                        size: 18,
                        color: '#7f7f7f'
                    }
                }
            }
            // _layout[`xaxis1${key}`] = {
            //     title: {
            //         text: xaxis,
            //         font: {
            //             size: 18,
            //             color: '#7f7f7f'
            //         }
            //     }
            // }
            index++;
        }
        _layout['grid'].rows = _subplots?.length;
        _layout['grid'].subplots = _subplots;

        setDynamicPlotData(_plotdata);
        setDynamicPlotLayout(_layout);

        // console.log("dynamicPlotData", _plotdata);
        // console.log("subplots", _subplots);
        // console.log("layout", layout);
    }

    const plotGraph = () => {
        // Validate if all the required fields are selected.
        if (!xaxis || !yaxis || !filter) {
            Notify.failure('Please select all the drop-down fields.');
            return;
        }
        createPlottingData(originalFileData);
    };

    const plotConfigObj = [
        {
            key: '1',
            label: 'Dynamic Plot Options',
            children: (
                <>
                    {/* <Button type="default" icon={showPlot ? <EyeInvisibleOutlined /> : <EyeOutlined />} onClick={() => setShowPlot(!showPlot)}>{showPlot ? 'Hide Plot' : 'View Plot'}</Button> */}
                    {/* <Form>
                        <Form.Item rules={[{ required: true, message: 'Please select X-Axis' }]}>
                            <Select showSearch allowClear onChange={setXaxis} style={{ width: '100%', marginBottom: '10px' }} placeholder="Select X-Axis" options={dateOptions} />
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: 'Please select Y-Axis' }]}>
                            <Select showSearch allowClear onChange={setYaxis} style={{ width: '100%', marginBottom: '10px' }} placeholder="Select Y-Axis" options={plotOptions} />
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: 'Please select Filter' }]}>
                            <Select showSearch allowClear onChange={setFilter} style={{ width: '100%', marginBottom: '10px' }} placeholder="Select Filter" options={plotOptions} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={plotGraph}>Plot</Button>
                        </Form.Item>
                    </Form> */}
                    <Flex vertical>
                        <Select prefixCls="X-Axis" showSearch allowClear onChange={setXaxis} style={{ width: '100%', marginBottom: '10px' }} placeholder="Select X-Axis" options={dateOptions} />
                        <Select prefixCls="Y-Axis" showSearch allowClear onChange={setYaxis} style={{ width: '100%', marginBottom: '10px' }} placeholder="Select Y-Axis" options={plotOptions} />
                        <Select prefixCls="Filter" showSearch allowClear onChange={setFilter} style={{ width: '100%', marginBottom: '10px' }} placeholder="Select Filter" options={plotOptions} />
                        <Radio.Group block buttonStyle="solid" onChange={(e) => setSubplotType(e.target.value)} defaultValue={"independent"} value={subplotType} style={{ marginBottom: '10px' }}>
                            <Radio.Button value={"independent"}>Independent</Radio.Button>
                            <Radio.Button value={"shared"}>Shared</Radio.Button>
                        </Radio.Group>
                        {/* <Select prefixCls="Subplot-Type" showSearch allowClear defaultValue={"independent"} onChange={setSubplotType} style={{ width: '100%', marginBottom: '10px' }} placeholder="Select Subplot Type" options={[{ label: 'Independent', value: 'independent' }, { label: 'Shared', value: 'shared' }]} /> */}
                        <Button className="mt-4" type="primary" onClick={plotGraph}>Plot</Button>
                    </Flex>
                </>
            ),
        }
    ];

    return (
        <>
            <Collapse
                // size="small"
                // accordion
                // bordered
                ghost
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                items={plotConfigObj}
            />

        </>
    );
}
export { DynamicSubPlot, DynamicSubPlotConfig };
