import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, Navigate } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import Dashboard from "../components/Dashboard/Dashboard";
import Login from "../components/Login/login";
import OnboardingLayout from "../components/Onboarding/OnboardingLayout";
import IndustrySelection from "../components/Onboarding/IndustrySelection";
import ProductSelection from "../components/Onboarding/ProductSelection";
import ProcessStages from "../components/Onboarding/ProcessStages";
import UserOverview from "../components/Admin/UserOverview";
import GlobalConfiguration from "../components/Global Configuration";
import KpiTracking from "../components/Kpi Tracking";
import TaskListing from "../components/Tasks/TaskManagement";
import Graph from "../components/charts/graph";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route element={<ProtectedRoute />}>
        <Route element={<OnboardingLayout />}>
          <Route path="onboarding">
            <Route path="industry" element={<IndustrySelection />} />
            <Route path="product/:industry" element={<ProductSelection />} />
            <Route path="process/:industry/:product" element={<ProcessStages />} />
          </Route>
            <Route path="admin/user-overview" element={<UserOverview />} />
        </Route>
        <Route index element={<Dashboard />} />
        <Route path="settings" element={<GlobalConfiguration />} />
        <Route path="kpi-tracking" element={<KpiTracking />} />
        <Route path="tasks" element={<TaskListing />} />
        <Route path="graph" element={<Graph />} />
      </Route>
      <Route
        path="login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Route>
  )
);

const Routes = () => {
  return <RouterProvider router={router} />;
};

export default Routes;
