import React, { useEffect, useState, useRef } from "react";
import { HotTable } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.min.css";
import { Button, Switch, Select, Input, Modal ,DatePicker} from "antd";
import crossIcon from "../../img/date_range.svg"
import dayjs, { Dayjs } from "dayjs";
import {
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  SaveOutlined,
  SaveFilled,
} from "@ant-design/icons";
import Notiflix, { Notify } from "notiflix";
import Plot from 'react-plotly.js';
import ReactDOM from 'react-dom';

registerAllModules();

interface HandsonDataTableProps {
  data: any[];
  onDataChange?: (newData: any[]) => void;
  onSaveAs?: (name: string, data: any[]) => void;
  onSave?: (data: any[]) => void;
}

interface ColumnStats {
  distinctCount: number;
  distinctPercentage: number;
  missingCount: number;
  missingPercentage: number;
  min?: number;
  max?: number;
  mean?: number;
  stdDev?: number;
  histogram?: { x: number; y: number }[];
}

// Add this type definition at the top of the file
type CellRendererParams = [
  instance: Handsontable.Core,
  TD: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: any,
  cellProperties: Handsontable.CellProperties
];

// Add this interface near the other interfaces at the top
interface ChartDivElement extends HTMLDivElement {
  cleanup?: () => void;
}

const HandsonDataTable: React.FC<HandsonDataTableProps> = ({
  data,
  onDataChange,
  onSaveAs,
  onSave,
}) => {
  const hotRef = useRef<any>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isColumnRenameModalVisible, setIsColumnRenameModalVisible] =
    useState(false);
  const [selectedColumn, setSelectedColumn] = useState<string>("");
  const [newColumnName, setNewColumnName] = useState<string>("");
  const [columnStats, setColumnStats] = useState<{
    [key: string]: ColumnStats;
  }>({});
  const [showInsights, setShowInsights] = useState(true);
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const [isSaveAsModalVisible, setIsSaveAsModalVisible] = useState(false);
  const [saveAsFileName, setSaveAsFileName] = useState("");
  const [hiddenColumns, setHiddenColumns] = useState<number[]>([]);
  const [isOpen, setIsOpen] = useState(false); // Control modal visibility
  const { RangePicker } = DatePicker;

  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null] | null>(null);
  const [dateTimeColId , setDateTimeColId] = useState<number>(0)


  useEffect(() => {
    if (data && data.length > 0) {
      // Convert object data to array format for dynamic column support
      const headers = Object.keys(data[0]);
      const formattedData = data.map((row) => {
        if (!row) return new Array(headers.length).fill(null);
        return headers.map((header) => row[header] ?? null);
      });
      setTableData(formattedData);

      // Create columns configuration
      const cols = headers.map((key) => ({
        title: key,
        type: typeof data[0][key] === "number" ? "numeric" : "text",
        readOnly: !isEditMode,
        sortable: true,
        filter: true,
        width: 150,
      }));
      setColumns(cols);

      calculateColumnStats(data);
    }
  }, [data]);

  const exportToCSV = () => {
    try {
      // Get headers from the original data
      const headers = Object.keys(data[0]);

      // Create CSV content
      let csvContent = headers.join(",") + "\n"; // Header row

      // Add all data rows
      data.forEach((row) => {
        const rowValues = headers.map((header) => {
          const value = row[header];
          // Handle special characters and formatting
          if (value === null || value === undefined) return "";
          if (
            typeof value === "string" &&
            (value.includes(",") || value.includes('"') || value.includes("\n"))
          ) {
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        });
        csvContent += rowValues.join(",") + "\n";
      });

      // Create and trigger download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.setAttribute("href", url);
      link.setAttribute("download", "table_export.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      Notiflix.Notify.success("File exported successfully");
    } catch (error) {
      console.error("Export error:", error);
      Notiflix.Notify.failure("Failed to export file");
    }
  };

  // Update getPaginatedData to handle array data
  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = tableData.slice(startIndex, endIndex);

    // Create stats row
    const statsRow = columns.map((col) => {
      const stats = columnStats[col.title];
      if (stats) {
        return `Distinct: ${stats.distinctCount}
Missing: ${stats.missingPercentage.toFixed(1)}%${
          stats.mean !== undefined
            ? `
Min: ${stats.min?.toFixed(1)}
Max: ${stats.max?.toFixed(1)}
Mean: ${stats.mean.toFixed(1)}
StdDev: ${stats.stdDev?.toFixed(1)}`
            : ""
        }`;
      }
      return "";
    });

    return [statsRow, ...paginatedData];
  };

  // Update statsRenderer
  const statsRenderer = (
    instance: any,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: any,
    value: any
  ) => {
    if (row === 0) {
      td.innerHTML = "";

      if (value && value.startsWith("__STATS__")) {
        try {
          const stats = JSON.parse(value.replace("__STATS__", ""));
          const statsDiv = document.createElement("div");
          statsDiv.className = "stats-container";

          statsDiv.innerHTML = `
            <div class="stats-content">
              <div class="stats-row">Distinct: ${stats.distinctCount}</div>
              <div class="stats-row">Missing: ${stats.missingPercentage.toFixed(
                1
              )}%</div>
              ${
                stats.mean !== undefined
                  ? `
                <div class="stats-row">Min: ${stats.min?.toFixed(1)}</div>
                <div class="stats-row">Max: ${stats.max?.toFixed(1)}</div>
                <div class="stats-row">Mean: ${stats.mean.toFixed(1)}</div>
                <div class="stats-row">StdDev: ${stats.stdDev?.toFixed(1)}</div>
              `
                  : ""
              }
            </div>
          `;

          td.appendChild(statsDiv);
        } catch (error) {
          console.error("Error rendering stats:", error);
        }
      }

      return td;
    }

    // For non-stats rows, use default rendering
    // instance.renderer.TextRenderer.apply(this, arguments);
    return td;
  };

  // Handle page change
  const handlePageChange = (pageNum: number) => {
    setCurrentPage(pageNum);
    if (hotRef.current?.hotInstance) {
      hotRef.current.hotInstance.scrollViewportTo(0, 0);
    }
  };

  // Update the cellRenderer to use a regular function
  const cellRenderer = function (
    this: Handsontable,
    instance: Handsontable.Core,
    TD: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: any,
    cellProperties: Handsontable.CellProperties
  ) {
    if (row === 0 && showInsights) {
      const column = columns[col]?.title;
      const stats = columnStats[column];

      // Create stats content container
      const container = document.createElement("div");
      container.className = "flex flex-col p-2";

      // Stats container at the top
      const statsDiv = document.createElement("div");
      statsDiv.className = "text-xs whitespace-pre-line flex-col flex";
      statsDiv.innerHTML = `
        <div class="flex justify-between">
          <span>Missing:</span>
          <span>${stats?.missingCount} (${stats?.missingPercentage.toFixed(1)}%)</span>
        </div>
        <div class="flex justify-between">
          <span>Distinct:</span>
          <span>${stats?.distinctCount} (${stats?.distinctPercentage.toFixed(1)}%)</span>
        </div>
        <div class="flex justify-between">
          <span>Mean:</span>
          <span>${stats?.mean ? stats?.mean?.toFixed(1) : '-'}</span>
        </div>
        <div class="flex justify-between">
          <span>StdDev:</span>
          <span>${stats?.stdDev ? stats?.stdDev?.toFixed(1) : '-'}</span>
        </div>
      `;
      container.appendChild(statsDiv);

      // Add histogram for numeric columns using Plotly
      if (stats?.histogram && stats.histogram.length > 0) {
        const chartDiv = document.createElement("div") as ChartDivElement;
        chartDiv.className = "h-16 w-full mt-1";
        container.appendChild(chartDiv);

        // Min-Max container below chart
        const minMaxDiv = document.createElement("div");
        minMaxDiv.className = "flex justify-between text-xs";
        minMaxDiv.innerHTML = `
          <span>Min ${stats.min?.toFixed(0)}</span>
          <span>Max ${stats.max?.toFixed(0)}</span>
        `;
        container.appendChild(minMaxDiv);

        setTimeout(() => {
          try {
            const histogramData = stats.histogram?.map((h) => h.y) || [];
            const xValues = stats.histogram?.map((h) => h.x) || [];

            const plotData = [{
              type: 'bar',
              x: xValues,
              y: histogramData,
              marker: {
                color: '#38bdf8',
              },
              hoverinfo: 'none'
            }];

            const layout = {
              height: 60,
              width: chartDiv.offsetWidth,
              margin: { t: 0, r: 0, l: 0, b: 0 },
              paper_bgcolor: 'rgba(0,0,0,0)',
              plot_bgcolor: 'rgba(0,0,0,0)',
              xaxis: { visible: false },
              yaxis: { visible: false },
              showlegend: false,
              bargap: 0,
            };

            const config = {
              displayModeBar: false,
              responsive: true,
            };

            // Create a React element for the Plot
            const plotElement = React.createElement(Plot, {
              data: plotData as any,
              layout: layout as any,
              config: config as any,
              style: { width: '100%', height: '100%' }
            });

            // Render the Plot component into the chartDiv
            ReactDOM.render(plotElement, chartDiv);

            // Add cleanup function
            const cleanup = () => {
              ReactDOM.unmountComponentAtNode(chartDiv);
            };

            // Add cleanup to the element
            chartDiv.cleanup = cleanup;
          } catch (error) {
            console.error("Error rendering chart:", error);
          }
        }, 0);
      }

      TD.innerHTML = "";
      TD.appendChild(container);
    } else {
      // Use the built-in text renderer for regular cells
      Handsontable.renderers.TextRenderer.apply(this, [
        instance,
        TD,
        row,
        col,
        prop,
        value,
        cellProperties,
      ] as CellRendererParams);
    }

    return TD;
  };

  const viewingModeConfig: any = {
    readOnly: true,
    contextMenu: false,
    columnSorting: {
      initialConfig: {
        sortEmptyCells: true,
      },
    },
    filters: true,
    dropdownMenu: {
      items: {
        filter_by_condition: {
          name: "Filter by condition",
        },
        filter_by_value: {
          name: "Filter by value",
        },
        filter_action_bar: {
          name: "Filter actions",
        },
        separator4: "---------",
        select_date: {
          name: `<button style="width: 100%; padding: 2px 15px; border: none;    background-color: #1677ff47; cursor: pointer; margin-bottom: 5px; 
          display: flex; align-items: center; justify-content: center; color: black; color: #2b2264;">
      <img src="${crossIcon}" alt="icon" style="width: 16px; height: 16px; margin-right: 7px;"> 
      <span style="color: #2b2264;;">Filter By Date Range</span>
   </button>`,
          callback: function (this: any) {
            setIsOpen(true);
            const hot = this;
            const selectedCol = hot.getSelectedLast(); // Get last selected cell
        
            if (selectedCol) {
              const columnIndex = selectedCol[1]; // Get column index
              const columnName = hot.getColHeader(columnIndex); // Get column name

              if(columnName && columnName.toLowerCase()=='datetime' && !isOpen){
                setIsOpen(true)
                setDateTimeColId(columnIndex);
              }
              if(columnName && columnName.toLowerCase() !='datetime'){
                setIsOpen(false)
              }
            }
          },
          disabled: false,
        },
        reset_filters: {
          name: `<button style=" width : 100%; padding:2px 15px; border:none; background-color:#0F9D58; color:white; cursor:pointer;margin-bottom:5px">Reset</button>`,
          callback: function (this: any) {
            const hot = this;
            const selectedCol = hot.getSelectedLast()[1]; // Get the column where the dropdown was triggered
            if (selectedCol !== undefined) {
              const filtersPlugin = hot.getPlugin('filters');
              filtersPlugin.removeConditions(selectedCol); // Remove conditions for this column
              filtersPlugin.filter(); // Apply the filter (effectively resetting it)
              hot.render(); // Re-render the table
            }
          },
          disabled: false,
        },
        hide_column: {
          name:`<button style=" width : 100%; padding:2px 15px; border:none; background-color:#0F9D58; color:white; cursor:pointer;margin-bottom:5px">Hide Column</button>`,
          callback: function (this: any) {
            const hot = this;
            const selectedCol = hot.getSelectedLast()[1];
            if (selectedCol !== undefined) {
              setHiddenColumns((prev) => [...prev, selectedCol]);
            }
          },
          disabled: false,
        },
        show_column: {
          name:`<button style=" width : 100%; padding:2px 15px; border:none; background-color:#0F9D58; color:white; cursor:pointer; margin-bottom:10px;">Show All Columns</button>`,
          callback: function () {
            setHiddenColumns([]);
          },
          disabled: false,
        },
      },
    },
    cells: (row: number, col: number) => ({
      renderer: cellRenderer,
      readOnly: !isEditMode || (showInsights && row === 0),
    }),
    
    afterFilter: (conditionsStack:any) => {
      addRow()
    },
    beforeFilter : (conditionsStack:any) => {
      const hotInstance = hotRef.current.hotInstance; // Get Handsontable instance
      const originalData = hotInstance.getData(); // Get all table data

      // Find indexes of empty rows
      const emptyRowIndexes = [];
      for (let rowIndex = originalData.length - 1; rowIndex >= 0; rowIndex--) {
        const row = originalData[rowIndex];
        const isEmptyRow = row.every((cell:any) => cell === null || cell === "");
        if (isEmptyRow) {
          emptyRowIndexes.push(rowIndex); // Store row index to delete later
        }
      }
      emptyRowIndexes.forEach((rowIndex) => {
        hotInstance.alter("remove_row", rowIndex);
      });
    },
    afterOnCellMouseDown: function (event: MouseEvent, coords: any) {
      const hotInstance = this;
      
      if (coords.row === -1) {
        const columnIndex = coords.col;
        const columnName = hotInstance.getColHeader(columnIndex);
        if(columnName && columnName.toLowerCase() !='datetime'){
          setIsOpen(false)
          // setDateTimeColId(columnIndex);
        }
    
      }
    }
    
    
    
  };

  let addRow = () => {
    setTimeout(() => {
      const hotInstance = hotRef.current.hotInstance;
      
      const tableData = hotInstance.getData();
      hotInstance.alter('insert_row_above', 0);
      hotInstance.render();
    }, 0);
  };
   
  const editingModeConfig: any = {
    readOnly: false,
    contextMenu: {
      items: {
        // row_above: {
        //   name: "Insert row above",
        //   disabled: false,
        // },
        // row_below: {
        //   name: "Insert row below",
        //   disabled: false,
        // },
        // separator1: "---------",
        // col_left: {
        //   name: "Insert column left",
        //   disabled: false,
        // },
        // col_right: {
        //   name: "Insert column right",
        //   disabled: false,
        // },
        remove_col: {
          name: "Remove column",
          disabled: false,
        },
        separator2: "---------",
        remove_row: {
          name: "Remove row",
          disabled: false,
        },
        separator3: "---------",
        rename_column: {
          name: "Rename Column",
          callback: function (this: any) {
            const hot = this;
            const selectedCol = hot.getSelectedLast()[1];
            if (selectedCol !== undefined) {
              setSelectedColumn(selectedCol.toString());
              setNewColumnName(hot.getColHeader(selectedCol));
              setIsColumnRenameModalVisible(true);
            }
          },
          disabled: false,
        },
        hide_column: {
          name: "Hide Column",
          callback: function (this: any) {
            const hot = this;
            const selectedCol = hot.getSelectedLast()[1];
            if (selectedCol !== undefined) {
              setHiddenColumns((prev) => [...prev, selectedCol]);
            }
          },
          disabled: false,
        },
        show_column: {
          name: "Show All Columns",
          callback: function () {
            setHiddenColumns([]);
          },
          disabled: false,
        },
      },
    },
    allowInsertColumn: true,
    allowRemoveColumn: true,
    allowInsertRow: true,
    allowRemoveRow: true,
    columnSorting: true,
    filters: true,
    manualColumnResize: true,
    manualColumnMove: true,
    dropdownMenu: {
      items: {
        // col_left: {
        //   name: "Insert column left",
        //   disabled: false,
        // },
        // col_right: {
        //   name: "Insert column right",
        //   disabled: false,
        // },
        remove_col: {
          name: `<button style=" width : 100%; padding:2px 15px; border:none; background-color:#0F9D58; color:white; cursor:pointer;margin-bottom:5px">Remove column</button>`,
          callback: function (this: any) {
            const hot = this;
            const selectedCol = hot.getSelectedLast()[1];
            if (selectedCol !== undefined) {
              setHiddenColumns((prev) => [...prev, selectedCol]);
              hot.updateSettings({
                hiddenColumns: {
                  columns: [...hiddenColumns, selectedCol], // Update hidden columns list
                  indicators: true,
                },
              });
            }
          },
          disabled: false,
        },
        separator3: "---------",
        rename_column: {
          name: `<button style=" width : 100%; padding:2px 15px; border:none; background-color:#0F9D58; color:white; cursor:pointer;margin-bottom:5px">Rename Column</button>`,
          callback: function (this: any) {
            const hot = this;
            const selectedCol = hot.getSelectedLast()[1];
            if (selectedCol !== undefined) {
              setSelectedColumn(selectedCol.toString());
              setNewColumnName(hot.getColHeader(selectedCol));
              setIsColumnRenameModalVisible(true);
            }
          },
          disabled: false,
        },
        separator: "---------",
        filter_by_condition: {
          name: "Filter by condition",
        },
        filter_by_value: {
          name: "Filter by value",
        },
        filter_action_bar: {
          name: "Filter actions",
        },
        separator4: "---------",
        select_date: {
          name: `<button style="width: 100%; padding: 2px 15px; border: none;    background-color: #1677ff47; cursor: pointer; margin-bottom: 5px; 
          display: flex; align-items: center; justify-content: center; color: black; color: #2b2264;">
      <img src="${crossIcon}" alt="icon" style="width: 16px; height: 16px; margin-right: 7px;"> 
      <span style="color: #2b2264;;">Filter By Date Range</span>
   </button>`,
          callback: function (this: any) {
            setIsOpen(true);
            const hot = this;
            const selectedCol = hot.getSelectedLast(); // Get last selected cell
        
            if (selectedCol) {
              const columnIndex = selectedCol[1]; // Get column index
              const columnName = hot.getColHeader(columnIndex); // Get column name

              if(columnName && columnName.toLowerCase()=='datetime' && !isOpen){
                setIsOpen(true)
                setDateTimeColId(columnIndex);
              }
              if(columnName && columnName.toLowerCase() !='datetime'){
                setIsOpen(false)
                
              }
            }
          },
          disabled: false,
        },
        reset_filters: {
          name: `<button style=" width : 100%; padding:2px 15px; border:none; background-color:#0F9D58; color:white; cursor:pointer;margin-bottom:5px">Reset</button>`,
          callback: function (this: any) {
            const hot = this;
            const selectedCol = hot.getSelectedLast()[1]; // Get the column where the dropdown was triggered
            if (selectedCol !== undefined) {
              const filtersPlugin = hot.getPlugin('filters');
              filtersPlugin.removeConditions(selectedCol); // Remove conditions for this column
              filtersPlugin.filter(); // Apply the filter (effectively resetting it)
              hot.render(); // Re-render the table
            }
          },
          disabled: false,
        },
        show_column: {
          name: `<button style=" width : 100%; padding:2px 15px; border:none; background-color:#0F9D58; color:white; cursor:pointer;margin-bottom:5px">Show All Columns</button>`,
          callback: function () {
            setHiddenColumns([]);
          },
          disabled: false,
        },
      },
    },
    cells: (row: number, col: number) => ({
      renderer: cellRenderer,
      readOnly: !isEditMode || (showInsights && row === 0),
    }),
  };

  // Modify the afterChange handler to properly update the data
  const afterChange = (changes: any[] | null, source: string) => {
    if (!changes || source === "loadData") return;

    const updatedData = [...data];
    changes.forEach(([row, prop, oldValue, newValue]) => {
      // Adjust row index to account for the stats row
      const dataRow = showInsights ? row - 1 : row;
      if (dataRow >= 0 && dataRow < data.length) {
        const columnName = columns[prop as number].title;
        updatedData[dataRow] = {
          ...updatedData[dataRow],
          [columnName]: newValue,
        };
      }
    });

    // Update the parent component's data
    if (typeof onDataChange === "function") {
      onDataChange(updatedData);
    }
  };

  // Update afterCreateCol handler
  const afterCreateCol = (index: number) => {
    const hot = hotRef.current.hotInstance;
    const colHeaders = [...hot.getColHeader()]; // Create a copy of headers
    const newHeader = `New Column ${index + 1}`;

    // Only update the new column's header
    colHeaders.splice(index, 1, newHeader);

    // Update the columns state
    const updatedColumns = [...columns];
    updatedColumns.splice(index, 0, {
      title: newHeader,
      type: "text",
      readOnly: !isEditMode,
      sortable: true,
      filter: true,
      width: 150,
    });

    // Update both the headers and columns
    hot.updateSettings({
      colHeaders: colHeaders,
    });
    setColumns(updatedColumns);
  };

  // Update the calculateColumnStats function to include histogram calculation
  const calculateColumnStats = (data: any[]) => {
    if (data.length === 0) return;

    const stats: { [key: string]: ColumnStats } = {};
    Object.keys(data[0]).forEach((key) => {
      const columnData = data.map((row) => row[key]);
      const numericData = columnData.filter(
        (val) => typeof val === "number"
      ) as number[];
      const missingCount = columnData.filter(
        (val) => val === null || val === undefined
      ).length;

      stats[key] = {
        distinctCount: new Set(columnData).size,
        distinctPercentage:
          (new Set(columnData).size / columnData.length) * 100,
        missingCount,
        missingPercentage: (missingCount / columnData.length) * 100,
      };

      if (numericData.length > 0) {
        const mean =
          numericData.reduce((a, b) => a + b, 0) / numericData.length;
        const variance =
          numericData.reduce((a, b) => a + Math.pow(b - mean, 2), 0) /
          numericData.length;

        // Add histogram calculation
        const binCount = 10;
        const min = Math.min(...numericData);
        const max = Math.max(...numericData);
        const binWidth = (max - min) / binCount;
        const histogram = Array(binCount).fill(0);

        numericData.forEach((value) => {
          const binIndex = Math.min(
            Math.floor((value - min) / binWidth),
            binCount - 1
          );
          histogram[binIndex]++;
        });

        stats[key] = {
          ...stats[key],
          min: Math.min(...numericData),
          max: Math.max(...numericData),
          mean,
          stdDev: Math.sqrt(variance),
          histogram: histogram.map((count, i) => ({
            x: min + i * binWidth,
            y: count,
          })),
        };
      }
    });
    setColumnStats(stats);
  };

  // Update getTableData function
  // const getTableData = () => {
  //   if (!tableData.length) return [];

  //   const startIndex = (currentPage - 1) * pageSize;
  //   const endIndex = startIndex + pageSize;
  //   const paginatedData = tableData.slice(startIndex, endIndex);

  //   return showInsights
  //     ? [new Array(columns.length).fill(""), ...paginatedData]
  //     : paginatedData;
  // };
  
//Function to get table data without pagination
   const getTableData = () => {
     if (!tableData.length) return [];

     return showInsights
       ? [new Array(columns.length).fill(""), ...tableData]
       : tableData;
   };

  // Add this new handler for column removal
  const afterRemoveCol = (index: number) => {
    // Update columns state
    const updatedColumns = [...columns];
    const removedColumn = updatedColumns[index];
    updatedColumns.splice(index, 1);
    setColumns(updatedColumns);

    // Convert array data back to object format
    const updatedData = data.map((row) => {
      const newRow = { ...row };
      delete newRow[removedColumn.title];
      return newRow;
    });

    // Update parent component
    if (onDataChange) {
      onDataChange(updatedData);
    }

    // Update internal table data
    const updatedTableData = updatedData.map((row) =>
      updatedColumns.map((col) => row[col.title] ?? null)
    );
    setTableData(updatedTableData);

    // Update column stats
    const updatedStats = { ...columnStats };
    delete updatedStats[removedColumn.title];
    setColumnStats(updatedStats);
  };

  // Update the tableConfig to adjust height and row settings based on insights visibility
  // Define removeColumnMenuButton before tableConfig
  // const removeColumnMenuButton = (
  //   col: number,
  //   TH: {
  //     appendChild(customIcon: HTMLSpanElement): unknown;
  //     querySelector: (value: string) => any;
  //   }
  // ) => {
  //   const column = columns[col];
  //   if (column && column.title === "DateTime") {
  //     // Remove existing dropdown icon if already present
  //     const existingIcon = TH.querySelector(".custom-dropdown-icon");
  
  //     if (existingIcon) {
  //       existingIcon.remove(); // Remove the icon if found
  //     }
  
  //     // Select and remove the existing .changeType button
  //     const changeTypeButton = TH.querySelector(".changeType");
  //     if (changeTypeButton) {
  //       changeTypeButton.remove(); // Remove the .changeType button directly
  //     }
  
  //     // Create a new dropdown icon inside a div
  //     const dropdownWrapper = document.createElement("div");
  //     dropdownWrapper.className = "custom-dropdown-wrapper"; // Add a wrapper for better styling
  //     dropdownWrapper.style.display = "inline-block"; // Prevents layout shifting
  
  //     const dropdownIcon = document.createElement("span");
  //     dropdownIcon.className = "custom-dropdown-icon changeType"; // Ensure the correct class
  //     dropdownIcon.style.cursor = "pointer"; // Make it look clickable
  //     dropdownIcon.style.color = "#BBBBBB";
  //     dropdownIcon.style.border = "1px solid";
  //     dropdownIcon.style.position = "absolute";
  //     dropdownIcon.style.top = "4px";
  //     dropdownIcon.style.right = "5px";
  //     dropdownIcon.style.height = "16px";
  //     dropdownIcon.style.width = "16px";
  
  //     // Attach event listener to open dropdown
  //     dropdownIcon.addEventListener("click", (event) => {
  //       event.stopPropagation(); // Prevent Handsontable from handling the click
  //       setIsOpen(true);
  //       setDateTimeColId(col);
  //     });
  
  //     // Append dropdown icon inside the wrapper
  //     dropdownWrapper.appendChild(dropdownIcon);
  
  //     // Append the dropdownWrapper as the last child of the parent
  //     const parentElement = TH.querySelector(".relative"); // Select the parent of the .changeType button
  //     if (parentElement) {
  //       parentElement.appendChild(dropdownWrapper); // Append the dropdownWrapper at the end
  //     }
  //   }
  // };
  

  const tableConfig: any = {
    ...(!isEditMode ? viewingModeConfig : editingModeConfig),
    colHeaders: columns.map((col) => col.title),
    rowHeaders: true,
    manualColumnResize: true,
    manualRowResize: true,
    stretchH: "all",
    autoWrapRow: true,
    height: showInsights ? 715 : 550,
    rowHeights: 50,
    width: "100%",
    licenseKey: "non-commercial-and-evaluation",
    data: getTableData(),
    afterChange,
    afterCreateCol,
    afterRemoveCol,
    fixedRowsTop: showInsights ? 1 : 0,
    allowRemoveColumn: true,
    removeColumnAllowed: true,
    dataSchema: new Array(columns.length).fill(null),
    startRows: 1,
    minSpareRows: 0,
    autoRowSize: true,
    columnHeaderHeight: 40,
    hiddenColumns: {
      columns: hiddenColumns,
      indicators: true,
    },
    // filters: true, // Enable the Filters plugin
    // afterGetColHeader: removeColumnMenuButton, // References the corrected function
    columns: columns.map((col) => {
      return {
        title: col.title,
        type: col.title.toLowerCase() === "datetime" ? 'date' : 'numeric', 
        dateFormat: 'YYYY-MM-DD',
        correctFormat: true,
      };
    }),
  };


  // Update CSS for stats styling
  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      .stats-row td {
        background: #fafafa !important;
        border-bottom: 2px solid #e8e8e8 !important;
      }
      .stats-container {
        padding: 4px;
        height: 100%;
      }
      .stats-content {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
      .stats-row {
        font-size: 12px;
        line-height: 1.4;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Add this new function to generate page numbers with ellipsis
  const getPageNumbers = () => {
    const totalPages = Math.ceil(tableData.length / pageSize);
    const delta = 1; // Number of pages to show before and after current page
    const pages: (number | string)[] = [];

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 || // First page
        i === totalPages || // Last page
        (i >= currentPage - delta && i <= currentPage + delta) // Pages around current
      ) {
        pages.push(i);
      } else if (
        i === currentPage - delta - 1 ||
        i === currentPage + delta + 1
      ) {
        pages.push("...");
      }
    }

    return pages;
  };

  // Replace the existing pagination JSX with this
  const renderPagination = () => (
    <div className="mt-4 flex justify-center gap-2">
      {getPageNumbers().map((pageNum, index) => (
        <React.Fragment key={index}>
          {pageNum === "..." ? (
            <span className="px-3 py-1">...</span>
          ) : (
            <Button
              type={currentPage === pageNum ? "primary" : "default"}
              onClick={() => handlePageChange(pageNum as number)}
            >
              {pageNum}
            </Button>
          )}
        </React.Fragment>
      ))}
    </div>
  );

  // Modify the handleColumnRename function
  const handleColumnRename = () => {
    if (newColumnName.trim() && hotRef.current?.hotInstance) {
      const hot = hotRef.current.hotInstance;
      const colIndex = parseInt(selectedColumn);
      const oldColumnTitle = columns[colIndex].title;

      // Update column header
      const newHeaders = [...hot.getColHeader()];
      newHeaders[colIndex] = newColumnName.trim();

      // Update settings
      hot.updateSettings({
        colHeaders: newHeaders,
      });

      // Update columns state
      const updatedColumns = columns.map((col, index) =>
        index === colIndex ? { ...col, title: newColumnName.trim() } : col
      );
      setColumns(updatedColumns);

      // Update columnStats with new key while preserving stats
      const updatedColumnStats = { ...columnStats };
      if (updatedColumnStats[oldColumnTitle]) {
        updatedColumnStats[newColumnName.trim()] =
          updatedColumnStats[oldColumnTitle];
        delete updatedColumnStats[oldColumnTitle];
        setColumnStats(updatedColumnStats);
      }

      setIsColumnRenameModalVisible(false);
      setNewColumnName("");
    }
  };

  // Optimized Modal component for better input performance
  const renderColumnRenameModal = () => (
    <Modal
      title="Rename Column"
      open={isColumnRenameModalVisible}
      onOk={handleColumnRename}
      onCancel={() => {
        setIsColumnRenameModalVisible(false);
        setNewColumnName("");
      }}
      destroyOnClose
    >
      <Input
        autoFocus
        placeholder="Enter new column name"
        value={newColumnName}
        onChange={(e) => setNewColumnName(e.target.value)}
      />
    </Modal>
  );

  // Add Save handler
  const handleSave = () => {
    setIsSaveModalVisible(true);
  };

  // Add Save confirmation handler
  const handleSaveConfirm = () => {
    // Convert data to proper format
    const formattedData = data.map((row) => {
      // Create a new object with the same structure as original data
      const newRow: { [key: string]: any } = {};
      columns.forEach((col) => {
        newRow[col.title] = row[col.title];
      });
      return newRow;
    });

    if (onSave) {
      onSave(formattedData);
    }

    setIsSaveModalVisible(false);
  };

  // Add Save As handler
  const handleSaveAs = () => {
    setIsSaveAsModalVisible(true);
  };

  // Add Save As confirmation handler
  const handleSaveAsConfirm = () => {
    if (!saveAsFileName.trim()) {
      return;
    }

    // Get the original data structure with headers
    const formattedData = data.map((row) => {
      // Create a new object with the same structure as original data
      const newRow: { [key: string]: any } = {};
      columns.forEach((col) => {
        newRow[col.title] = row[col.title];
      });
      return newRow;
    });

    if (onSaveAs) {
      // Pass the properly formatted data with headers
      onSaveAs(saveAsFileName, formattedData);
    }

    setIsSaveAsModalVisible(false);
    setSaveAsFileName("");
  };

  // Add Save modal render function
  const renderSaveModal = () => (
    <Modal
      title="Confirm Save"
      open={isSaveModalVisible}
      onOk={handleSaveConfirm}
      onCancel={() => setIsSaveModalVisible(false)}
      okText="Save"
      cancelText="Cancel"
    >
      <p>
        Are you sure you want to save changes to the file? This will
        replace the existing file.
      </p>
    </Modal>
  );

  // Add Save As modal render function
  const renderSaveAsModal = () => (
    <Modal
      title="Save As"
      open={isSaveAsModalVisible}
      onOk={handleSaveAsConfirm}
      onCancel={() => {
        setIsSaveAsModalVisible(false);
        setSaveAsFileName("");
      }}
      okText="Save"
      cancelText="Cancel"
    >
      <Input
        placeholder="Enter file name"
        value={saveAsFileName}
        onChange={(e) => setSaveAsFileName(e.target.value)}
        suffix=".csv"
        autoFocus
      />
    </Modal>
  );

  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null, dateStrings: [string, string]) => {
    setDateRange(dates); // Type now matches
  };

  const applyCustomFilterOnhandsonTable = (startDate: any , endDate: any) =>{
    const filters =
    hotRef.current?.hotInstance?.getPlugin('filters');
    filters.clearConditions(dateTimeColId)
    filters.addCondition(dateTimeColId, 'between', [startDate, endDate]);
    filters?.filter();
  }



  const handleApply = () => {
    if (dateRange) {
      const [startDate, endDate] = dateRange;
      const startDateString = startDate?.format('YYYY-MM-DD') || '';
      const endDateString = endDate?.format('YYYY-MM-DD') || '';

      // Add your logic here, e.g., filter Handsontable data with startDateString and endDateString
      applyCustomFilterOnhandsonTable(startDateString , endDateString)
      // setIsOpen(false);
    }
  };

  // Handle Reset button click
  // const handleReset = () => {
  //   setDateRange(null); // Clear the selected dates
  //   // setIsOpen(false)
  //   const filters =
  //   hotRef.current?.hotInstance?.getPlugin('filters');
  //   filters.clearConditions(dateTimeColId)
  //   filters?.filter();
  // };


  const Datepicker = () => {
    return (
<>
      {/* Modal with RangePicker and buttons */}
      { isOpen && 

      <div >
        {/* <Modal 
          title="Select Date Range"
          open={isOpen}
          onCancel={()=>setIsOpen(false)}
          footer={[
            <Button key="reset" onClick={handleReset}>
              Reset
            </Button>,
            <Button key="cancel" onClick={()=>setIsOpen(false)}>
              Cancel
            </Button>,
            <Button key="apply" type="primary" onClick={handleApply} disabled={!dateRange}>
              Apply
            </Button>,
          ]}
        > */}
        <div className="flex p-2 gap-2">
          <RangePicker
            value={dateRange}
            onChange={handleDateChange}
            format="YYYY-MM-DD"
            placeholder={['Start Date', 'End Date']}
            style={{ width: '40%' }}
          />
            <Button key="apply" onClick={handleApply} disabled={!dateRange} className="text-white bg-[#252963] hover:bg-[#1d2050]">
              Apply
            </Button>
            {/* <Button key="reset" onClick={handleReset} disabled={!dateRange}>
              Reset
            </Button> */}
            <Button key="cancel" onClick={()=>setIsOpen(false)}>
              Cancel
            </Button>
        </div>
        {/* </Modal> */}
      </div>
      }
    </>
    );
  }


  return (
    <div className="p-4">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <Switch
              checkedChildren={<EditOutlined />}
              unCheckedChildren={<EyeOutlined />}
              checked={isEditMode}
              onChange={setIsEditMode}
            />
            <span>{isEditMode ? "Editing Mode" : "Viewing Mode"}</span>
          </div>

          <div className="flex items-center gap-2">
            <Switch
              checkedChildren={<span className="px-1">On</span>}
              unCheckedChildren={<span className="px-1">Off</span>}
              checked={showInsights}
              onChange={setShowInsights}
            />
            <span>Insights {showInsights ? "Visible" : "Hidden"}</span>
          </div>

          <Select
            value={pageSize}
            onChange={(value) => {
              setPageSize(value);
              setCurrentPage(1);
            }}
            options={[
              { value: 10, label: "10 entries" },
              { value: 25, label: "25 entries" },
              { value: 50, label: "50 entries" },
              { value: 100, label: "100 entries" },
            ]}
          />
        </div>

        <div className="flex items-center gap-2">
          {isEditMode && (
            <>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                type="primary"
                icon={<SaveFilled />}
                onClick={handleSaveAs}
              >
                Save As
              </Button>
            </>
          )}
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={exportToCSV}
          >
            Export to CSV
          </Button>
        </div>
      </div>

        {Datepicker()}
      <div className="handsontable-container" style={{ minHeight: "400px" }}>
        {data.length > 0 && (
          <>
            <HotTable ref={hotRef} {...tableConfig} />
            {/* {renderPagination()} */}
          </>
        )}
      </div>

      {renderColumnRenameModal()}
      {renderSaveModal()}
      {renderSaveAsModal()}
    </div>
  );
};

export default HandsonDataTable;
