import React from 'react';
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import { Spin } from 'antd';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';

// Initialize dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

interface KpiStatData {
  date: string;
  mean_value: string;
  median_value: string;
  std_dev_value: string;
  count: number;
}

interface KpiStatisticsGraphsProps {
  data: KpiStatData[];
  loading: boolean;
  viewType: 'daily' | 'monthly' | 'weekly';
}

export const KpiStatisticsGraphs: React.FC<KpiStatisticsGraphsProps> = ({
  data,
  loading,
  viewType
}) => {
  // Handle empty or invalid data
  const isValidData = React.useMemo(() => {
    return Array.isArray(data) && data.length > 0 && data.some(item => 
      item?.mean_value && 
      item?.median_value && 
      item?.std_dev_value
    );
  }, [data]);

  const graphs = [
    {
      id: 'mean',
      title: 'Mean Values',
      yAxisTitle: 'Mean Value',
      color: '#1a237e',
      getValue: (item: KpiStatData) => {
        const value = parseFloat(item?.mean_value || '0');
        return isNaN(value) ? 0 : value;
      },
    },
    {
      id: 'median',
      title: 'Median Values',
      yAxisTitle: 'Median Value',
      color: '#1a237e',
      getValue: (item: KpiStatData) => {
        const value = parseFloat(item?.median_value || '0');
        return isNaN(value) ? 0 : value;
      },
    },
    {
      id: 'stddev',
      title: 'Standard Deviation',
      yAxisTitle: 'Standard Deviation',
      color: '#1a237e',
      getValue: (item: KpiStatData) => {
        const value = parseFloat(item?.std_dev_value || '0');
        return isNaN(value) ? 0 : value;
      },
      getYAxisConfig: (values: number[]) => {
        const maxValue = Math.max(...values, 0.1);
        return {
          range: [0, maxValue],
          tickformat: '.3f'
        };
      }
    }
  ];

  if (loading) {
    return (
      <div className="space-y-6">
        {graphs.map(graph => (
          <div key={graph.id} className="bg-white rounded-lg shadow-sm p-4">
            <div className="flex justify-center items-center h-[250px]">
              <Spin size="large" />
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (!isValidData) {
    return (
      <div className="space-y-6">
        {graphs.map(graph => (
          <div key={graph.id} className="bg-white rounded-lg shadow-sm p-4">
            <div className="flex justify-center items-center h-[250px] text-gray-500">
              No {graph.yAxisTitle} data available
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {graphs.map(graph => {
        const values = data.map(item => graph.getValue(item)).filter(val => !isNaN(val));
        
        // Calculate y-axis range
        const minValue = Math.max(0, Math.min(...values) - (Math.max(...values) - Math.min(...values)) * 0.1);
        const maxValue = Math.max(...values) + (Math.max(...values) - Math.min(...values)) * 0.1;
        
        const yAxisConfig = (graph.getYAxisConfig && graph.id === 'stddev')
          ? graph.getYAxisConfig(values)
          : {
              range: [minValue, maxValue],
              tickformat: '.2f'
            };

        // Format x-axis labels based on viewType
        const xValues = data.map(item => {
          if (viewType === 'weekly' && item.date.includes('-')) {
            return item.date;
          }
          return dayjs.utc(item.date).format('YYYY-MM-DD');
        });
        
        const yValues = data.map(item => graph.getValue(item));
        
        // Create Plotly data
        const plotData = [{
          type: 'bar',
          x: xValues,
          y: yValues,
          marker: {
            color: graph.color
          },
          name: graph.yAxisTitle
        }];
        
        // Create Plotly layout
        const layout = {
          title: {
            text: graph.title,
            font: {
              size: 16,
              weight: 600
            }
          },
          height: 250,
          margin: {
            l: 60,
            r: 30,
            b: 60,
            t: 50,
            pad: 4
          },
          xaxis: {
            title: '',
            tickangle: 0,
            tickfont: {
              size: 12
            },
            tickformat: '%b %d\n%Y',
            tickformatstops: [
              {
                dtickrange: [null, null],
                value: '%b %d\n%Y'
              }
            ]
          },
          yaxis: {
            title: {
              text: graph.yAxisTitle,
              font: {
                size: 14,
                weight: 600
              }
            },
            ...yAxisConfig,
            tickfont: {
              size: 12
            }
          },
          bargap: 0.4,
          hovermode: 'closest'
        };
        
        // Plotly config
        const config = {
          responsive: true,
          displayModeBar: false,
          toImageButtonOptions: {
            format: 'png',
            filename: `${graph.id}_chart`,
            height: 250,
            width: 700,
            scale: 2
          }
        };

        return (
          <div key={graph.id} className="bg-white rounded-lg shadow-sm p-4">
            <Plot
              data={plotData as any}
              layout={layout as any}
              config={config as any}
              style={{ width: '100%', height: '250px' }}
            />
          </div>
        );
      })}
    </div>
  );
}; 