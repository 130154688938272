import React from "react";
import { Table, Dropdown, Button, Menu } from "antd";
// import eye from "../assets/eye.svg";
import eye from "../../img/visible.svg";
// import ellipse from "../assets/ellipse.svg";
import ellipse from "../../img/ellipse.svg";
const MaterialValueTable = ({ data ,setDataContentSection , setData}:any) => {
  const handleView = (record:any) => {
  console.log('record :', record);
    setDataContentSection('material-parameters-details')
    setData(record.bucketization_statistical_value)
  };

  const handleBack = () =>{
    setDataContentSection('file-listing')
  }

//   const handleEditGoldenValue = (record) => {
//     console.log("Edit Golden Value", record);
//   };

//   const handleCopyGoldenValue = (record) => {
//     console.log("Copy Golden Value", record);
//   };

//   const handleEdit = (record) => {
//     console.log("Edit", record);
//   };

//   const handleDelete = (record) => {
//     console.log("Delete", record);
//   };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srNo",
      key: "srNo",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text:any, record:any) => (
        <div>
          {text} {record?.workflowFilter?.name && (
            <div className="text text-xs">
              <span className="text text-xs text-gray-500">
                {record?.workflowFilter?.name}
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Workflow ID",
      dataIndex: "workflow_id",
      key: "workflow_id",
      render: (value:any) => (value !== undefined && value !== null ? value : "-"),
    },
    {
      title: "Workflow Name",
      dataIndex: "workflow_name",
      key: "workflow_name",
      render: (_:any, record:any) => (record.workflow_id ? `Workflow ${record.workflow_id}` : "-"),
    },
    {
      title: "Action",
      key: "action",
      render: (_:any, record:any) => {
        const menu = (
          <Menu>
            <Menu.Item key="view" onClick={() => handleView(record)}>
              <img src={eye} className="relative -left-1 h-4 mr-2" alt="View" />
              View
            </Menu.Item>
            {/* <Menu.Item key="editData" onClick={() => handleEditGoldenValue(record)}>
              <img src={EditDoc} className="relative -left-1 h-6 mr-1" alt="Edit" />
              Edit
            </Menu.Item>
            <Menu.Item key="copy" onClick={() => handleCopyGoldenValue(record)}>
              <img src={copy} className="w-4 h-4 mr-2" alt="Copy" />
              Copy
            </Menu.Item>
            <Menu.Item key="edit" onClick={() => handleEdit(record)}>
              <img src={edit} className="w-4 h-4 mr-2" alt="Edit" />
              Rename
            </Menu.Item>
            <Menu.Item key="delete" onClick={() => handleDelete(record)}>
              <img src={dlt} className="w-4 h-4 mr-2" alt="Delete" />
              Delete
            </Menu.Item> */}
          </Menu>
        );

        return (
          <div className="flex justify-center w-full">
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button className="border-none bg-transparent shadow-none p-0">
                <img src={ellipse} className="h-5" alt="Actions" />
              </Button>
            </Dropdown>
          </div>
        );
      },
    },
  ];
  return (
    <div className="golden-values p-5 w-full">
    <div className="relative">
    <div className="flex items-center gap-4 mb-4">
          <button
          onClick={handleBack}
          >
            <svg
              className="h-5 w-5 rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          <h2 className="!font-medium !text-[32px] !text-[#333] worksans">
            Material Parameters
          </h2>
  </div>
    <Table columns={columns} dataSource={data} rowKey="id" />
    </div>
    </div>
  )

};

export default MaterialValueTable;
