"use client"

import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import settingIcon from '../../img/settings.svg'
import { getRequest } from "../../utils/apiHandler";
import shareIcon from '../../img/share.svg';
import TaskStatusModal from "../Modal/shareWorkflowModal";
import { useAuth } from "../../context/AuthContext";
import { Button } from "antd";
import { useSelector } from "react-redux";

export default function ExecuteFlow({ onQueryBuilderOpening, handleOperationConfigOpening, handleFileChange, handleExecuteFile, workflowData }: any) {
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [allFiles, setAllFiles] = useState<any>(null)
  const [openShareModal , setOpenShareModal] = useState<boolean>(false)
  // const [userList , setUserList] = useState<any>([])
  const navigate = useNavigate();
  const location = useLocation()
  const { authState } = useAuth();
  let params = new URLSearchParams(location.search);
  const selectedWorkflowId = params.get('workflowId');
  const selectSystems = useSelector((state: any) => state.systems.systems);
  const handleWorkflowRuns = () => {
    navigate(`/?tab=insight&workflowId=${selectedWorkflowId}&runsListing=true`)
  }

  useEffect(() => {
    getAllFiles();
    // getUsersList();
  }, [workflowData])

  const getAllFiles = async () => {
    try {
            
   const systemIdsString = selectSystems?.length ?  selectSystems[0].systems
   ?.map((system: { systemId: number }) => {
     return system.systemId;
   })
   .join(",") : ''

   const apiUrl = systemIdsString
     ? `/file?systems_id=${systemIdsString}`
     : "/file";
      const response = await getRequest(apiUrl)
      if (response?.data?.data) {
        const filteredData = response?.data?.data?.files?.filter((item: any) => {
          if(item?.compatibility == true || item?.compatibility==null){ 
            return item
          }
        }) || [];
        setAllFiles(filteredData)
        let fileId = workflowData?.workflowComponents?.filter((item: any) => { return item.type == 'file' })
        if (fileId) {
          fileId = fileId[0]?.component
        }
        let savedFile = filteredData?.filter((item: any) => { return item.csv_id == fileId })
        if (savedFile) {
          setSelectedFile(savedFile[0])
        }
      }
    } catch (error) {
      console.log('error', error)

    }
  }

  // const getUsersList = async () =>{
  //   try {
  //     const response = await getRequest('/user/list')
  //     setUserList(response?.data?.data)
      
  //   } catch (error) {
  //   console.log('error :', error);
      
  //   }
  // }

  return (
    <div className="p-4 w-full mx-auto border-b border-[#E7E7E7]">
      <div className="flex items-center mb-4">
        <h1 className="capitalize font-semibold">{workflowData?.name}</h1>
        {/* <div onClick={()=> setOpenShareModal(true)} >
          <img src={shareIcon} alt='share.icon' />
        </div> */}

        {authState?.user?.role?.alias =='process_engineer' && authState?.user?.id == workflowData?.user_id &&
                <div className="p-2">
                <Button
                variant="outlined"
                  // type="text"
                  onClick={() => setOpenShareModal(true)}
                  // className="flex items-center justify-center p-2 hover:bg-[#252963] hover:text-white transition-colors duration-200"
                >
                  <img 
                    src={shareIcon} 
                    alt="share icon" 
                    // className="w-5 h-5 mr-2" 
                  />
                  <span >Share</span>
                </Button>
              </div>
          }
        <div className="flex justify-end ml-auto">
          {workflowData?.sharedWorkflowDetails && workflowData?.sharedWorkflowDetails?.access_level=='view' ? 
          <>
          </> :<>
          <button
            onClick={() => {
              navigate(`/?tab=insight&workflowId=${selectedWorkflowId}`)
            }}
            className="px-3 py-1.5 pr-2 mr-2 bg-primary text-white rounded-md transition-colors"
          >
            Edit Flow
          </button>
          </>}
          <button
            onClick={handleWorkflowRuns}
            className="px-3 py-1.5 bg-primary text-white rounded-md transition-colors"
          >
            View Run Flow
          </button>
        </div>
      </div>
      {authState?.user?.id == workflowData?.user_id && 
      <TaskStatusModal 
        visible={openShareModal} 
        onClose={() => setOpenShareModal(false)} 
        workflowData={workflowData}
        shareType="workflow"
      />
      }
      

      <div className="flex justify-center">
        <div className="inline-flex items-center max-w-[880px] w-full mx-auto">
          {/* CSV Data Source Section */}
          <div className="flex-1">
            <div className="flex items-center gap-1 mb-1 ml-2">
              <span className="size-3 rounded-[3px] bg-[#4AA17F]"></span>
              <span className="text-sm text-black">Connected</span>
            </div>

            <div className="border border-solid max-w-[428px] border-[#E7E7E7] rounded-lg py-2.5 px-4 bg-white shadow-sm relative">
              <span className="size-4 border border-solid border-[#E7E7E7] rounded-sm inline-block absolute top-[42%] -left-2 bg-white"></span>
              <span className="size-4 border border-solid border-[#E7E7E7] rounded-sm inline-block absolute top-[42%] -right-2 bg-white"></span>
              <div className="flex items-center gap-2">
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#2B2264"
                >
                  <path
                    d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 2V8H20"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="font-medium">CSV_Data_Source</span>
                <button
                  className="p-1 rounded-md ml-auto"
                  onClick={onQueryBuilderOpening}
                >
                  <img
                    src={settingIcon}
                    alt="Setting"
                    className="filter-icon ms-auto h-5"
                  />
                </button>
              </div>

              <div className="flex gap-2 w-full items-center mt-2">
                <label className="text-sm text-gray-500">Select File</label>
                <div className="flex-1 relative">
                  <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="w-full px-3 py-2 text-left border rounded-md bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    {selectedFile ? selectedFile?.file_name : "Select File"}
                  </button>
                  {isDropdownOpen && (
                    <div className="absolute w-full mt-1 bg-white border rounded-md shadow-lg z-10 overflow-y-auto max-h-[400px]">
                      {allFiles?.map((file: any) => (
                        <button
                          key={file?.csv_id}
                          onClick={() => {
                            setSelectedFile(file);
                            handleFileChange(file);
                            setIsDropdownOpen(false);
                          }}
                          className="text-sm w-full truncate px-3 py-2 text-left hover:bg-gray-100 focus:outline-none"
                        >
                          {file?.file_name}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="h-[3px] mt-[22px] w-10 bg-[#E7E7E7] "></div>
          {/* Operations Section */}
          <div className="max-w-[300px] w-full">
            <div className="flex items-center gap-1 mb-1 ml-2">
              <span className="size-3 rounded-[3px] bg-[#4AA17F]"></span>
              <span className="text-sm text-black">Connected</span>
            </div>

            <div className="flex items-center justify-between border border-solid max-w-[428px] border-[#E7E7E7] rounded-lg py-2.5 px-4 bg-white shadow-sm relative">
              <span className="size-4 border border-solid border-[#E7E7E7] rounded-sm inline-block absolute top-[32%] -left-2 bg-white"></span>
              <span className="size-4 border border-solid border-[#E7E7E7] rounded-sm inline-block absolute top-[32%] -right-2 bg-white"></span>
              <div className="flex items-center gap-2">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_7790_290"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_7790_290)">
                    <path
                      d="M19 19H8C7.45 19 6.97917 18.8042 6.5875 18.4125C6.19583 18.0208 6 17.55 6 17V3C6 2.45 6.19583 1.97917 6.5875 1.5875C6.97917 1.19583 7.45 1 8 1H15L21 7V17C21 17.55 20.8042 18.0208 20.4125 18.4125C20.0208 18.8042 19.55 19 19 19ZM14 8V3H8V17H19V8H14ZM4 23C3.45 23 2.97917 22.8042 2.5875 22.4125C2.19583 22.0208 2 21.55 2 21V7H4V21H15V23H4Z"
                      fill="#2B2264"
                    />
                  </g>
                </svg>

                <span className="font-medium">Operations</span>
              </div>
              <div className="flex gap-2">
                <button
                  className="p-1 rounded-md"
                  onClick={handleOperationConfigOpening}
                >
                  <img
                    src={settingIcon}
                    alt="Setting"
                    className="filter-icon ms-auto h-5"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="h-[3px] mt-[22px] w-10 bg-[#E7E7E7] "></div>

          <button
            className="px-4 py-2 bg-primary text-white rounded-md transition-colors mt-5"
            onClick={handleExecuteFile}
          >
            Execute
          </button>
        </div>
      </div>
    </div>
  );
}

