import React, { useState } from 'react';
import {  Spin } from 'antd';
import { Report } from 'notiflix/build/notiflix-report-aio';
import { postRequest } from '../../utils/apiHandler';
import { Notify } from 'notiflix';
import Loader from '../Common/Loader';
import { useSelector } from 'react-redux';
interface ClusterSaveFileModalProps {
  clusterAllRun:any;
  openModal:any;
  isOpen: boolean;
  onClose: () => void;
}

export const ClusterSaveFileModal: React.FC<ClusterSaveFileModalProps> = ({clusterAllRun,openModal, isOpen, onClose }) => {
  const [clusterFileName, setclusterFileName] = useState<string>('');
  const [goldenBatchErr, setgoldenBatchErr] = useState<string>('')
  const [isSaved, setIsSaved] = useState(false)
  const selectSystems = useSelector((state: any) => state?.systems?.systems);
  const handleSaveClick = () => {
    if (clusterFileName.trim()) {
      setIsSaved(true)
      // onSave(clusterFileName);
      createAndUploadClusterFile(clusterFileName)
      setclusterFileName('');
      // onClose(); 
      setgoldenBatchErr('')
    } else {
        setgoldenBatchErr('Enter File Name')
    }
  };
  const onCloseModal = () =>{
      onClose()
      setgoldenBatchErr('')
      setclusterFileName('');
  }

  const createAndUploadClusterFile = async (fileName:any) =>{

    if(!fileName.trim() || !clusterAllRun){
      return 
    } 
    let payload = {
      clusterAllRunData : clusterAllRun,
      fileName : fileName,
      systems : selectSystems  && selectSystems[0]?.systems ? selectSystems[0].systems : []
    }
    
    const response = await postRequest('/file/cluster-all-run/create-&-upload' , payload)
    setIsSaved(false)
    openModal(false)
    if(response && response.status==200){
      Notify.success("Cluster all run data file has been created", { timeout: 2000 , distance: '50px',clickToClose:true})
    }else{
      Notify.failure("Failed to create Cluster all run data file", { timeout: 2000 , distance: '50px',clickToClose:true})
    }
  }

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center z-50">
      <div className="bg-white p-6 m-32 rounded-lg w-80 shadow-lg h-48">
      {isSaved ? (
        <div >
          <h2 className="text-lg font-semibold text-center mb-4">Saving file</h2>
          <Spin className='ml-28 mt-6' size="large" />
      </div>
        ) : (
          <div>
            <h2 className="text-lg font-semibold text-center mb-4">Enter File Name</h2>
            <input
              type="text"
              value={clusterFileName}
              onChange={(e) => setclusterFileName(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md"
              placeholder="Enter File Name"
            />
    
            <div className="text-red-700">
            {
            !clusterFileName && 
                <span>
                {goldenBatchErr}
                </span>
            }
            </div>
            <div className="flex justify-between mt-5">
              <button
                onClick={onCloseModal}
                className=" save-section px-4 py-2 bg-gray-300 rounded-md text-sm font-semibold hover:bg-gray-400"
              >
                <span className="save-section">
                Cancel
                </span>
            
              </button>
              <button
                onClick={handleSaveClick}
                className="save-section !h-9 w-16 btn-primary-new"
              >
            <span className="save-section">
              Save
            </span>
              </button>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

