import React, { useEffect, useState } from "react";
import Select from "react-select";
import { message } from "antd";
import { useAuth } from "../../../context/AuthContext";
import { postRequest } from "../../../utils/apiHandler";

interface Option {
  value: string;
  label: string;
}

interface TimeGeneralIdentifiersProps {
  systemName: string;
  identifiers: string[];
  onUpdate: (identifiers: string[]) => void;
}

const TimeGeneralIdentifiers: React.FC<TimeGeneralIdentifiersProps> = ({
  systemName,
  identifiers = [],
  onUpdate,
}) => {
  const user = useAuth();
  const isAutherizedToEditConfiguration =
    user.authState.user?.role.alias == "process_engineer" ? false : true;

  const [timeGeneralOptions, setTimeGeneralOptions] = useState<Option[]>([]);

  useEffect(() => {
    fetchTimeAndGeneralIdentifiers();
  }, []);

  const fetchTimeAndGeneralIdentifiers = async () => {
    try {
      const response = await postRequest("/parameter-mapping", {
        types: ["Time", "GenericIdentifiers"],
      });

      if (response.status === 200) {
        const options: Option[] = response.data.data.map((param: any) => ({
          value: param.name,
          label: param.name,
        }));
        setTimeGeneralOptions(options);
      } else {
        message.error("Failed to fetch parameters");
      }
    } catch (error) {
      console.error("Error fetching parameters:", error);
      message.error("Failed to fetch parameters");
    }
  };

  const handleChange = (selected: readonly Option[]) => {
    onUpdate(selected.map((option) => option.value));
  };

  return (
    <div className="space-y-8">
      <div className="flex items-center space-x-2 mb-6">
        <span className="bg-blue-600 text-white px-2 py-1 rounded-full text-sm">
          Step 6/6
        </span>
        <h2 className="text-xl font-bold">Time & General Identifiers</h2>
      </div>

      <div className="mb-8">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Select Time & General Identifiers
        </label>
        <Select
          isMulti
          options={timeGeneralOptions}
          value={timeGeneralOptions.filter((opt) =>
            identifiers.includes(opt.value)
          )}
          onChange={handleChange}
          className="basic-multi-select"
          classNamePrefix="select"
          isDisabled={isAutherizedToEditConfiguration}
          placeholder="Select identifiers..."
        />
      </div>
    </div>
  );
};

export default TimeGeneralIdentifiers;
