import React, { useState } from 'react'
import icon from '../../img/Icon.svg'
import { postRequest } from '../../utils/apiHandler';
import { useNavigate } from "react-router-dom";
import Notiflix from 'notiflix';
import { validateInput } from '../../utils/validator';
import { useAuth } from '../../context/AuthContext';

const Login = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    const [formData, setFormData] = useState<any>({ email: "", password: "" });
    const [formError, setFormError] = useState<any>({ email: "", password: "" });

    const handleChange = (e: any) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
        setFormError({ ...formError, [id]: validateInput(id, value) });
    }


    const handleLogin = async (event: any) => {
        event.preventDefault();

        // Validation
        Object.keys(formError).forEach((id) => {
            const error = validateInput(id, formData[id]);
            if (error) {
                setFormError((prevFormError: any) => ({
                    ...prevFormError,
                    [id]: error
                }));
            }
        });

        if (Object.values(formError).filter(err => err !== "").length > 0 || !formData.email || !formData.password) {
            return;
        }

        try {
            const response = await postRequest('/auth/login', formData);
            const { status, message, data } = response.data;
            if (status === 200) {
                Notiflix.Notify.success('Logged in successfully', { timeout: 2000 , distance: '50px',clickToClose:true});

                // Call AuthContext login with token and user data
                await login(data.token, {
                  id: data.id,
                  first_name: data.first_name || "User",
                  last_name: data.last_name || "User",
                  onboarding: data.onboarding || "not_started",
                  role: data.role,
                  selected_systems: data.selected_systems || [],
                  tenant_id: data?.tenant_id,
                });

                // Navigation is now handled by ProtectedRoute based on onboarding status
                // The route will automatically redirect to the correct page
            } else {
                Notiflix.Notify.failure(message, { timeout: 2000 , distance: '50px',clickToClose:true});
            }
        } catch (error: any) {
            if (error.response) {
                Notiflix.Notify.failure(error.response.data.message, { timeout: 2000 , distance: '50px',clickToClose:true});
            } else {
                Notiflix.Notify.failure(error.message, { timeout: 2000 , distance: '50px',clickToClose:true});
            }
        }
    };

    return (
        <React.Fragment>
            <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
                <div className="w-full max-w-sm p-6 bg-white rounded-md shadow-md">
                    <div className="flex justify-center mb-8">
                        <img src={icon} className='h-20' alt='logo'/>
                    </div>
                    <h5 className='font-semibold mb-3'>Sign In with your email and password</h5>
                    <form onSubmit={handleLogin}>
                        <label htmlFor="email" className="block text-gray-700 font-medium mb-1">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={formData.email}
                            onBlur={handleChange}
                            onChange={(e) => handleChange(e)}
                            className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400"
                            placeholder="Enter your email"
                        />
                        <div className="text-red-700">{formError.email}</div>
                        
                        <label htmlFor="password" className="block text-gray-700 font-medium mb-1">
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={formData.password}
                            onBlur={handleChange}
                            onChange={(e) => handleChange(e)}
                            className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400"
                            placeholder="Enter your password"
                        />
                        <div className="text-red-700">{formError.password}</div>
                        
                        <button
                            type="submit"
                            className="w-full py-2 bg-primary text-white rounded-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary"
                        >
                            Sign in
                        </button>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Login;