"use client";

import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../utils/apiHandler";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "../Common/dateFormatter";
import { Button, DatePicker, Pagination, Table } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs, { Dayjs } from "dayjs";
import arrowleft from "../../img/arrowleftblue.svg";
import { set } from "react-datepicker/dist/date_utils";
import TaskStatusModal from "../Modal/shareWorkflowModal";
import shareIcon from '../../img/share.svg';
import crossIcon from "../../img/white-cross.svg";
import ticksvg from "../../img/tick.svg";
import { Notify } from "notiflix";

interface WorkflowRun {
  _id: string;
  fileName: string;
  parentFolder: string;
  date: string;
  time: string;
  createdBy: string;
  status: "completed" | "processing";
}

export default function WorkflowTable({ workflowData }: any) {
  const [workflowRuns, setWorkflowRuns] = useState<WorkflowRun[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [selectedRun, setSelectedRun] = useState<any>(null);
  const [isShareMode, setIsShareMode] = useState(false);
  const [selectedRunIds, setSelectedRunIds] = useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerContent, setDrawerContent] = useState<any>(null);
  
  const { RangePicker } = DatePicker;
  // const defaultDateRange: [Dayjs, Dayjs] = [
  //   dayjs().startOf("day"), // Current day's start
  //   dayjs().endOf("day"),
  // ];
  const defaultDateRange: [Dayjs, Dayjs] = [
    dayjs().subtract(1, "month").startOf("day"), // Start of the day a month ago
    dayjs().endOf("day"),
  ];
  const [dateRanges, setDateRanges] =
    useState<[Dayjs, Dayjs]>(defaultDateRange);

  const navigate = useNavigate();
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const workflowId = params.get("workflowId");

  // Add state to track latest run
  const [latestRun, setLatestRun] = useState<any>(null);

  // Add interface for MLJob
  interface MLJob {
    _id: string;
    status: string;
    workflow_id: string;
    file_name: string;
    created_at: string;
    executed_by: string;
  }

  // Regular data fetch
  useEffect(() => {
    console.log("Fetching workflows...");
    getWorkflows(currentPage, pageSize);
  }, [currentPage, pageSize, dateRanges, setDateRanges]);

  // Interval effect for checking latest run status
  useEffect(() => {
    const checkLatestRunStatus = async () => {
      try {
        console.log("Checking latest run status...");
        // Only make API call if we have a latest run that's in progress
        if (latestRun && latestRun.status === "in_progress") {
          const response = await postRequest('/workflow/workflow-runs/in-progress', {
            runId: latestRun._id
          });

          if (response?.data?.data) {
            const updatedRun = response.data.data;
            console.log("Updated run status:", updatedRun);
            
            // Update the run in the list
            setWorkflowRuns(prevRuns => 
              prevRuns.map(run => 
                run._id === updatedRun._id ? updatedRun : run
              )
            );

            // Update latest run state
            setLatestRun(updatedRun);

            // Return true to continue interval if still in progress
            return updatedRun.status === "in_progress";
          }
        }
        return false;
      } catch (error) {
        console.error('Error checking run status:', error);
        return false;
      }
    };

    let intervalId: NodeJS.Timeout;

    // Only start interval if latest run is in progress
    if (latestRun?.status === "in_progress") {
      console.log("Starting interval to check run status...");
      intervalId = setInterval(async () => {
        const shouldContinue = await checkLatestRunStatus();
        if (!shouldContinue) {
          console.log("Stopping interval as run is no longer in progress.");
          clearInterval(intervalId);
        }
      }, 15000);
    }

    return () => {
      if (intervalId) {
        console.log("Cleaning up interval.");
        clearInterval(intervalId);
      }
    };
  }, [latestRun]);

  const getWorkflows = async (page: number, size: number) => {
    try {
      setLoading(true);
      console.log("Fetching workflows with pagination:", { page, size });
      const [startDate, endDate] = dateRanges;

      let payload: any = {
        workflowId,
        page,
        size,
        range: {
          start: startDate.startOf("day").toISOString(),
          end: endDate.endOf("day").toISOString(),
        },
      };
      if (searchQuery) {
        payload.searchFile = searchQuery;
      }

      const response = await postRequest(`/workflow/workflow-runs`, payload);
      
      if (response?.data?.data) {
        const runs = response.data.data;
        console.log("Fetched workflow runs:", runs);
        setWorkflowRuns(runs);
        setTotal(response?.data?.total);
        
        // Set latest run if we have runs
        if (runs.length > 0) {
          console.log("Setting latest run:", runs[0]);
          setLatestRun(runs[0]); // Assuming runs are sorted with latest first
        }
      }
      setLoading(false);
    } catch (error) {
      console.log('Error fetching workflows:', error);
      setLoading(false);
    }
  };

  const handlePageChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const handleOpenWorkflow = (run: any) => {
    console.log("run", run);
    let params = new URLSearchParams(location.search);
    const selectedWorkflowId = params.get("workflowId");
    // navigate('/?tab=insight&workflowId=' + selectedWorkflowId);
    navigate(
      `/?tab=insight&workflowId=${workflowId}&subTab=result&mlJobId=${run._id}`
    );
  };

  const handleDateRangeChange: RangePickerProps["onChange"] = (dates) => {
    if (dates) {
      const [start, end] = dates as [Dayjs, Dayjs];
      setDateRanges([start, end]); // Keep local dates for datepicker
    } else {
      // Reset to default date range
      const defaultStart = defaultDateRange[0];
      const defaultEnd = defaultDateRange[1];

      setDateRanges([defaultStart, defaultEnd]); // Keep local dates for datepicker
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getWorkflows(currentPage, pageSize);
    }
  };

  const handleBackButton = () => {
    let params = new URLSearchParams(location.search);
    const selectedWorkflowId = params.get("workflowId");
    navigate(
      "/?tab=insight&workflowId=" + selectedWorkflowId + "&execute=true"
    );
  };

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: any[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const runIds = selectedRows.map((row) => row._id);
    setSelectedRunIds(runIds);
  };

  const handleShareRuns = () => {
    if (selectedRunIds.length === 0) {
      Notify.warning("Please select at least one run to share", {
        timeout: 2000,
        distance: "50px",
        clickToClose: true,
      });
      return;
    }
    setOpenShareModal(true);
  };

  const handleRowClick = (record: any) => {
    setDrawerContent(record);
    setDrawerVisible(true);
  };

  const columns = [
    {
      title: "Run ID",
      dataIndex: "runId",
      key: "runId",
      render: (_: any, record: any, index: number) => (
        <span
          onClick={() => handleOpenWorkflow(record)}
          className="text-blue-600 cursor-pointer hover:underline"
        >
          R_{index + 1}
        </span>
      ),
    },
    {
      title: "Data Source",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "Date & Time",
      key: "dateTime",
      render: (record: any) => (
        <>
          <span>{formatDate(record?.created_at)}</span>{" "}
          <span className="text-gray-400">|</span>{" "}
          <span>
            {new Date(record?.created_at).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        </>
      ),
    },
    {
      title: "Executed By",
      key: "executedBy",
      render: (record: any) => (
        <div
          className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-100"
          title={record?.executed_by}
        >
          {record?.executed_by
            ?.split(" ")
            .map((word: any) => word[0]?.toUpperCase())
            .join("")}
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (record: any) =>
        record.status === "completed" ? (
          <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
            Completed
          </span>
        ) : record.status === "in_progress" ? (
          <div className="flex-col items-center space-x-2">
            <span className="text-gray-600 text-xs">
              Execution Processing...
            </span>
          </div>
        ) : record.status === "failed" ? (
          <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800">
            Failed
          </span>
        ) : (
          <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
            Pending
          </span>
        ),
    },
  ];

  return (
    <div className="w-full mx-auto p-6 relative">
      <div
        className="flex font-medium items-center line-clamp-1 gap-2 !text-sm !text-[#252963] mb-2 cursor-pointer"
        onClick={handleBackButton}
      >
        <Button type="text" className="p-0 !bg-transparent">
          <img src={arrowleft} />
        </Button>
        <span>Back</span>
      </div>
      <div className="space-y-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl">
            Work Flow Runs for{" "}
            <span className="capitalize font-semibold">
              {workflowData?.name}
            </span>
          </h1>
          <Button
            className="bg-primary text-white p-1 px-3 rounded"
            onClick={() => setIsShareMode(true)}
          >
            Share Runs
          </Button>
        </div>

        {isShareMode && (
          <div className="absolute top-0 left-0 right-0 bg-[#0000005c] z-50 p-4">
            <div className="bg-white p-4 rounded-lg shadow-lg text-center max-w-md mx-auto">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-2">
                  <img src={ticksvg} alt="tick svg" className="w-5 h-5" />
                  <p className="text-gray-700">
                    Please select the runs you want to share.
                  </p>
                </div>
                <img
                  src={crossIcon}
                  alt="cross icon"
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => {
                    setIsShareMode(false);
                    setSelectedRowKeys([]);
                    setSelectedRunIds([]);
                  }}
                />
              </div>
              <div className="flex gap-3 justify-center">
                <button
                  className="bg-primary text-white px-4 py-2 rounded hover:bg-opacity-90"
                  onClick={handleShareRuns}
                >
                  Share
                </button>
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-opacity-90"
                  onClick={() => {
                    setIsShareMode(false);
                    setSelectedRowKeys([]);
                    setSelectedRunIds([]);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="relative">
          <input
            type="text"
            placeholder="Search file name"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            className="w-full px-10 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <svg
            className="absolute left-3 top-3 h-4 w-4 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>

        <div>
          <RangePicker
            showTime
            onChange={handleDateRangeChange}
            value={dateRanges}
            className="w-[400px]"
            defaultValue={defaultDateRange}
          />
        </div>

        <Table
          columns={columns}
          dataSource={workflowRuns}
          rowSelection={
            isShareMode
              ? {
                  type: "checkbox",
                  selectedRowKeys,
                  onChange: onSelectChange,
                  columnWidth: 48,
                  fixed: true,
                }
              : undefined
          }
          rowKey="_id"
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: handlePageChange,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
          }}
          className="border rounded-lg"
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            style: { cursor: 'pointer' }
          })}
        />
      </div>

        <div className={`top-[54px] fixed flex justify-end task-sidebar ${drawerVisible ? 'right-0' : '-right-full' } 50 z-50 !mt-0`}>
          <div className=" bg-white h-full shadow-lg overflow-y-auto w-[32rem]">
            <div className="flex items-center justify-between p-4 border-b bg-gray-50 sticky top-0">
              <h2 className="text-lg font-semibold">Run Detail</h2>
              <button className="text-gray-500 hover:text-gray-800" onClick={() => setDrawerVisible(false)}>
                ✕
              </button>
            </div>
            <div className="p-6 space-y-6">
                <h1 className="text-2xl font-normal">Custom Query</h1>
              <div className="space-y-4">

                <div className="flex-1 p-4 overflow-y-auto">
                  <div className="space-y-2">
                    {Object.entries(drawerContent?.actual_settings?.rules || {}).map(([key, item]: any) => (
                      <div key={key} className="py-2 px-3 bg-gray-100 rounded-md">
                        <span className="grid grid-cols-3 gap-3">
                          <span className="font-bold">{item?.field}</span> 
                          <span className="text-center">{item?.operator}</span> 
                          <span className="text-center">
                            {item?.operator === 'between'
                              ? typeof item?.value === 'string'
                                ? item?.value.replace(/,/g, ' & ') // Replace commas with '&'
                                : item?.value
                              : item?.value}
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      {openShareModal && (
        <TaskStatusModal
          visible={openShareModal}
          onClose={() => {
            setOpenShareModal(false);
            setIsShareMode(false);
            setSelectedRowKeys([]);
            setSelectedRunIds([]);
          }}
          workflowData={workflowData}
          resultData={{ runIds: selectedRunIds }}
          shareType="runs"
        />
      )}
    </div>
  );
}
