import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest } from '../../utils/apiHandler';
interface CompareGoldenValuesProps {
  setGoldenValueSection: any;
}

const CompareGoldenValues: React.FC<CompareGoldenValuesProps> = ({ setGoldenValueSection }) => {
  const location = useLocation();
  const [comparedGoldenValues , setCompareGoldenValues] = useState<any>([])
  const [columnsData, setColumns] = useState<any>([]);

  let goldenValuscolumns: any = [];

  useEffect(() => {

    const currentUrl = window.location.pathname;
    const queryParams = new URLSearchParams(window.location.search);

    const goldenValueIds = queryParams.get('goldenValueIds');

    if(goldenValueIds?.split(',').length){
      getGoldenValues(goldenValueIds)
    }

  }, [location , location.search])

  const getGoldenValues = async (goldenValuesIds:any) =>{

    const response = await getRequest(`/golden-data/values/compare?ids=${goldenValuesIds}`)

    setCompareGoldenValues(response?.data?.data.goldenValues)
    setTableColumns(response?.data?.data.columns)
  }

  const setTableColumns = (columnsData:any) =>{
    if(!columnsData) return 

    const data =columnsData.map((column: any, index: number) => {
      let columns = {
        title: (
          <span style={{fontWeight: 'bold'}}>
             {column.name}
          </span>
        ),
        dataIndex: column.key,
        key: column.key,
        textAling: 'center',
        render: (text: any, record: any, index: number) => {
          const goldenKeys = Object.keys(record).filter((key) =>
              key.startsWith("golden_value_")
          );
      
          if (goldenKeys.length === 2) {
              const [key1, key2] = goldenKeys; 
              console.log('key2 :', key2);
              const value1 = record[key1];
              const value2 = record[key2];
      
              const diff = value2 - value1; 
              const percentageDeviation = (diff / (value1 || value2)) * 100;
      
              // Only show percentage deviation in the second key's column
              if (column.key === key2) {
                  return (
                      <div>
                          {text} 
                          <span className='ml-3'>
                          {diff !== 0 ? `( ${percentageDeviation.toFixed(2)} % )` : ""}
                          </span>
                      </div>
                  );
              }
          }
      
          return <div>{text}</div>;
      },
        onHeaderCell: () => ({
          style: { backgroundColor: '#f0f0f0', fontWeight: 'bold', },
        }),
      }
      return columns
    })
    goldenValuscolumns=data
    setColumns(data)

  }

  const isGoldenValuesSame = (record:any) =>{
    const keys = Object.keys(record).filter(k => k !== 'key');

    // Check if all values are the same
    const allValuesSame = keys.every((k, _, arr) => record[k] === record[arr[0]]);
    return allValuesSame ? allValuesSame : false
  }

  const handleChangeParam = () => {
    window.history.replaceState({}, "", '/?tab=data&golden-parameter=true');
    setGoldenValueSection('golden-parameters')
  }

  return (
    <div className="p-5 w-full">
      <div className="p-5 w-full">
        <div className="flex items-center gap-4 mb-4">
          <button
            onClick={() => { handleChangeParam(); }}
            className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-50"
          >
            <svg
              className="h-5 w-5 rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          <h2 className="!font-medium !text-[32px] !text-[#333] worksans">
            Compare Golden Values
          </h2>

        </div>
        <Table className="common-table" 
          dataSource={comparedGoldenValues}
          rowClassName={(record) => {
            return isGoldenValuesSame(record) ? '' : 'compare-row';
          }} 
          columns={columnsData} 
          pagination={false}
          />
      </div>
    </div>
  );
};

export default CompareGoldenValues;
