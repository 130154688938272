import React from 'react';
import Plot from 'react-plotly.js';

interface TargetCorrelationData {
    col: string;
    corr: number;
    p_val: number;
}

interface TargetCorrelationHeatmapProps {
    data: TargetCorrelationData[];
    fontSize: number;
    width: string;
}

export const TargetCorrelationHeatmap: React.FC<TargetCorrelationHeatmapProps> = ({ 
    data, 
    fontSize = 11, 
    width = 1000 
}) => {
    // Sort data by correlation value
    const sortedData = [...data]?.sort((a, b) => Math.abs(b.corr) - Math.abs(a.corr));

    // Calculate the max absolute correlation value for symmetric axis
    const maxAbsCorr = Math.max(
        ...sortedData.map(d => Math.abs(d.corr))
    );
    // Round up to the nearest 0.1
    const axisLimit = Math.ceil(maxAbsCorr * 10) / 10;

    // Create color array based on correlation values
    const colors = sortedData.map(d => d.corr >= 0 ? '#0eb8bf' : '#fb746b');
    
    // Create Plotly data
    const plotData = [{
        type: 'bar',
        orientation: 'h',
        x: sortedData.map(d => d.corr),
        y: sortedData.map(d => d.col),
        marker: {
            color: colors
        },
        text: sortedData.map(d => d.corr.toFixed(3)),
        textposition: 'inside',
        insidetextanchor: 'middle',
        hoverinfo: 'x+y',
        hovertemplate: '<b>%{y}</b><br>Correlation: %{x:.3f}<extra></extra>'
    }];
    
    // Calculate an appropriate height based on the number of features
    const height = sortedData.length * 30 || 450;
    
    // Fix for width type error: convert width properly for Plotly layout
    const layoutWidth = width === '100%' ? null : Number(width);
    
    // Create Plotly layout
    const layout = {
        title: 'Feature Correlation with Target',
        width: layoutWidth,
        height: height,
        margin: {
            l: 150, // Increased left margin for feature names
            r: 30,
            b: 50,
            t: 50,
            pad: 4
        },
        xaxis: {
            title: 'Correlation',
            range: [-axisLimit, axisLimit],
            zeroline: true,
            zerolinecolor: '#888',
            zerolinewidth: 1,
            tickfont: {
                size: fontSize
            },
            gridcolor: '#eee'
        },
        yaxis: {
            tickfont: {
                size: fontSize
            },
            automargin: true
        },
        showlegend: false,
        annotations: [
            {
                x: -axisLimit/2,
                y: sortedData.length + 1,
                text: 'Negative Correlation',
                showarrow: false,
                font: {
                    color: '#fb746b',
                    size: fontSize + 2
                }
            },
            {
                x: axisLimit/2,
                y: sortedData.length + 1,
                text: 'Positive Correlation',
                showarrow: false,
                font: {
                    color: '#0eb8bf',
                    size: fontSize + 2
                }
            }
        ]
    };
    
    // Plotly config
    const config = {
        responsive: true,
        displayModeBar: false
    };

    return (
        <div style={{ minWidth: '600px' }}>
            <Plot
                data={plotData as any}
                layout={layout as any}
                config={config}
                style={{ width: '100%', height: `${height + 100}px` }}
            />
        </div>
    );
};