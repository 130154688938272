import { useState, useEffect } from "react";
import {
  Table,
  Button,
  Select,
  DatePicker,
  Radio,
  Row,
  Col,
  Typography,
  Space,
  Switch,
  Menu,
  Dropdown
} from "antd";
import { QueryBuilder } from "react-querybuilder";
import { Dayjs } from "dayjs";
import close from "../../img/close-gray.svg";
import eye from "../../img/visible.svg";
import EditDoc from "../../img/edit document.svg";
import ellipse from "../../img/ellipse.svg";
import copy from "../../img/copy.svg";
import edit from "../../img/edit.svg";
import dlt from "../../img/dlt.svg";
import arrow from "../../img/arrowdownlight.svg";
import ConfirmDeleteModal from '../Modal/ConfirmDeleteModal';
import EditNameModal from '../Modal/EditNameModal';
import { useLocation, useNavigate } from "react-router-dom";
import { deleteRequest, getRequest, putRequest } from "../../utils/apiHandler";
import Notiflix, { Notify } from "notiflix";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedBatchDetails } from '../../Redux/slices/goldenBatchSlice';
import type { TableColumnsType, TableProps } from 'antd';
import CompareGoldenValues from "./CompareGoldenValues";
import crossIcon from "../../img/white-cross.svg";
import ticksvg from "../../img/tick.svg"
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title } = Typography;
type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];

const GoldenValueTable = ({
  goldenBatchNames,
  setDataContentSection,
  setgoldenBatchNames,
  uploadedFiles,
  getGolenBatchData,
  totalGoldenDataCount,
  goldenDataPageNo,
  setIsEdit
}: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const goldenParameter = params.get('golden-parameter');
    const compareGoldenValues = params.get('compare-golden-parameters')
    if (compareGoldenValues == 'true') {
      setGoldenValueSection('compare-golden-parameters')
    }
    else {
      setGoldenValueSection('golden-parameters')
    }


  }, [location, location.search])
  // Table configuration
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srNo",
      key: "srNo",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => {
        return (
          <div>
            {text} {record?.workflowFilter?.name ? <div className="text text-xs">
              <span>
                {record?.workflowFilter?.name && (
                  <>
                    <span className="text text-xs text-gray-500">{record?.workflowFilter?.name}</span>
                  </>
                )}
              </span>
            </div>
              : ''}
          </div>
        )
      },
    },
    {
      title: "Workflow ID",
      dataIndex: "workflow_id",
      key: "workflow_id",
      render: (value: any) => (value !== undefined && value !== null ? value : "-"),
    },
    {
      title: "Workflow Name",
      dataIndex: "workflow_name",
      key: "workflow_name",
      render: (_: any, record: any) => record.workflow_id ? `Workflow ${record.workflow_id}` : "-",
    },
    {
      title: "Custom Parameter",
      dataIndex: "custom_parameter",
      key: "custom_parameter",
      render: (value: any) => (value == true ? 'True' : 'False'),
    },
    // {
    //   title: "Min",
    //   dataIndex: "min",
    //   key: "min",
    //   render: (value: any) => (value !== undefined && value !== null ? value : "-"),
    // },
    // {
    //   title: "Max",
    //   dataIndex: "max",
    //   key: "max",
    //   render: (value: any) => (value !== undefined && value !== null ? value : "-"),
    // },
    {
      title: "Total Sample",
      key: "totalSample",
      render: (_: any, record: any) =>
        record.cluster_data?.totalSamples !== undefined &&
          record.cluster_data?.totalSamples !== null
          ? record.cluster_data.totalSamples
          : "-",
    },
    {
      title: "Good Sample",
      key: "goodSample",
      render: (_: any, record: any) =>
        record.cluster_data?.goodSamples !== undefined &&
          record.cluster_data?.goodSamples !== null
          ? record.cluster_data.goodSamples
          : "-",
    },
    {
      title: "Bad Sample",
      key: "badSample",
      render: (_: any, record: any) =>
        record.cluster_data?.badSamples !== undefined &&
          record.cluster_data?.badSamples !== null
          ? record.cluster_data.badSamples
          : "-",
    },
    {
      title: "Good Sample Percentage",
      key: "totalSample",
      render: (_: any, record: any) =>
        record.cluster_data?.goodSamplePercentage !== undefined &&
          record.cluster_data?.goodSamplePercentage !== null
          ? `${record.cluster_data.goodSamplePercentage} %`
          : "-",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => {
        const menu = (
          <Menu>
            <Menu.Item key="view" onClick={() => handleView(record)}>
              <img src={eye} className="relative -left-1 h-4 mr-2" alt="View" />
              View
            </Menu.Item>
            <Menu.Item key="editData" onClick={() => handleEditGoldenValue(record)}>
              <img src={EditDoc} className="relative -left-1 h-6 mr-1" alt="View" />
              Edit
            </Menu.Item>
            <Menu.Item key="copy" onClick={() => handleCopyGoldenValue(record)}>
              <img src={copy} className="w-4 h-4 mr-2" alt="Copy" />
              Copy
            </Menu.Item>
            <Menu.Item key="edit" onClick={() => handleEdit(record)}>
              <img src={edit} className="w-4 h-4 mr-2" alt="Edit" />
              Rename
            </Menu.Item>
            <Menu.Item key="delete" onClick={() => handleDelete(record)}>
              <img src={dlt} className="w-4 h-4 mr-2" alt="Delete" />
              Delete
            </Menu.Item>
          </Menu>
        );

        return (
          <div className="flex justify-center w-full">
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button className="border-none bg-transparent shadow-none p-0">
                <img src={ellipse} className="h-5" alt="Actions" />
              </Button>
            </Dropdown>
          </div>
        );
      },
    },
  ];
  const { goldenBatches } = useSelector((state: any) => state.goldenBatch);
  // File selection and basic states
  const [selectedFile, setSelectedFile] = useState<string | undefined>(undefined);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);

  // UI visibility states
  const [showColumnList, setShowColumnList] = useState(false);
  const [showTargetVariableSettings, setShowTargetVariableSettings] = useState(false);
  const [showFilterBasedOnColumn, setShowFilterBasedOnColumn] = useState(false);

  // Target variable filter states
  const [targetVariableOption, setTargetVariableOption] = useState<string>("only");
  const [targetVariableOnly, setTargetVariableOnly] = useState<string[]>([]);
  const [targetVariableQueryFilter, setTargetVariableQueryFilter] = useState<any>(null);

  // Column-based filter state
  const [columnBasedFilter, setColumnBasedFilter] = useState<any>(null);

  // Delete confirmation states
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);

  // Edit confirmation states
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState<any>(null);
  const [columnOptions, setcolumnOptions] = useState<any>([]);

  const [workflowFilters, setWorkflowFilters] = useState<any>([]);
  const [workflowFilterdSelected, setWorkflowFilterdSelected] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(100);
  const [filterId, setFilterId] = useState<{ id: number; name: string } | null>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [compareGoldenValue, setCompareGoldenValues] = useState<any>(false)
  const [goldenValueSection, setGoldenValueSection] = useState<"golden-parameters" | "compare-golden-parameters">("golden-parameters")
  const [selectedGoldenValueIds, setSelectedGoldenValueIds] = useState<any>([])
  // Clear all filters
  const clearFilters = () => {
    setSelectedColumns([]);
    setDateRange(null);
    setTargetVariableOption("only");
    setTargetVariableOnly([]);
    setTargetVariableQueryFilter(null);
    setColumnBasedFilter(null);
    setShowColumnList(false)
    setShowTargetVariableSettings(false)
    setShowFilterBasedOnColumn(false)
    setFilterId(null)
    setSelectedFile(undefined)
    // if(!selectedFile){
    //   const combinedFilters = {
    //     file: selectedFile
    //   };
    // }
    getGolenBatchData({
      file: undefined
    });
  };

  // Reset filters when file changes
  useEffect(() => {
    clearFilters();
  }, []);

  useEffect(() => {
    try {
      const fetchData = async () => {
        try {
          const response = await getRequest(`/filter`);
          if (response?.data?.data) {
            setWorkflowFilters(response.data.data)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    } catch (error) {
      console.log('error :', error);

    }

  }, [])

  const handleWorkflowSavedFilter = async (value: any) => {
    const filter = workflowFilters.filter((item: { id: number }) => item.id === value);
    if (filter && filter.length) setFilterId(filter[0]);
    // if(!filter.length){
    //   setWorkflowFilterdSelected(false)
    //   setSelectedFile(undefined);
    //   return
    // }
    // await getFileColumns(filter[0]?.csv_id)
    // await setSelectedFile(filter[0]?.csv_id);
    // setWorkflowFilterdSelected(true)

    // await getAndSetSavedFilters(filter[0]?.id)

  }

  const getAndSetSavedFilters = async (id: any) => {
    try {

      const response = await getRequest(`/filter/${id}`)
      console.log('response :', response);
      let excludedColumns = response?.data?.data[0]?.filters?.file?.exclude_features
      setSelectedColumns(excludedColumns)
      setTargetVariableQueryFilter(response?.data?.data[0]?.filters?.file?.target_variable_settings)
      if (response.data.data[0].filters.file?.rules) {
        setColumnBasedFilter(response.data.data[0].filters.file)
      }

    } catch (error) {

    }

  }
  const handlePagination = (pagination: any) => {
    handleFilterClick(undefined, pagination.current, pagination.pageSize)

  }

  // Handlers for filter actions
  const handleFileChange = (value: string) => {
    // getFileColumns(value)
    setSelectedFile(value);
  };

  const handleDateChange = (
    dates: [Dayjs, Dayjs] | null,
    _dateStrings: [string, string]
  ) => {
    setDateRange(dates);
  };

  const handleTargetVariableChange = (e: any) => {
    setTargetVariableOption(e.target.value);
  };

  const handleView = (record: any) => {
    dispatch(setSelectedBatchDetails(record));
    setDataContentSection("golden-parameters-details");
  };

  const handleEditGoldenValue = (record: any) => {
    setIsEdit(true)
    dispatch(setSelectedBatchDetails(record));
    setDataContentSection("create-parameters");
  };

  const handleCopyGoldenValue = (record: any) => {
    setIsEdit(false)
    dispatch(setSelectedBatchDetails(record));
    setDataContentSection("create-parameters")
  };

  // Prepare data for filters
  // const columnOptions = selectedFile
  // ? uploadedFiles.find((file: any) => file.csv_id === selectedFile)?.columns
  // : [];
  // console.log('columnOptions :', columnOptions);

  // let columnOptions = ['fileColumn-1','fileColumn-2' ,'fileColumn-3','fileColumn-4','fileColumn-5','fileColumn-6','fileColumn-7','fileColumn-8']
  const fields = columnOptions?.map((column: any) => ({
    name: column,
    label: column.charAt(0).toUpperCase() + column.slice(1),
  })) || [];

  const getFileColumns = async (csvId: any) => {
    if (csvId) {
      try {
        let response = await getRequest(`/file/columns/${csvId}`)
        if (response?.data?.data)
          setcolumnOptions(response.data.data.columns)
      } catch (error) {
        console.log('error', error)

      }
    }
  }

  // Handle filter application
  const transformFeatureFilters = (filterData: any) => {
    if (!filterData || !filterData.rules) return {};

    return filterData.rules.reduce((acc: any, rule: any) => {
      // Skip if required fields are missing
      if (!rule.field || !rule.operator || !rule.value) return acc;

      // Add the transformed rule
      acc[rule.field] = {
        operator: rule.operator,
        value: rule.value
      };

      return acc;
    }, {});
  };

  const handleFilterClick = (event?: React.MouseEvent<HTMLElement>, page: number | null = null, pageSize: number | null = null) => {
    // const transformedFeatureFilters = transformFeatureFilters(columnBasedFilter);

    const combinedFilters = {
      file: selectedFile,
      filter_id: filterId ? filterId.id : null,
      // exclude_features: selectedColumns,
      // dateRange: dateRange,
      // target_variable_settings: targetVariableOption === 'only' 
      //   ? targetVariableOnly 
      //   : targetVariableQueryFilter,
      // feature_filters: transformedFeatureFilters,
      page: page ? page : 1,
      pageSize: pageSize ? pageSize : 100
    };

    getGolenBatchData(combinedFilters);
    setShowColumnList(false)
    setShowTargetVariableSettings(false)
    setShowFilterBasedOnColumn(false)
  };


  // Handlers for delete confirmation
  const handleDelete = (record: any) => {
    setSelectedRecord(record);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteRequest(`/golden-data/${selectedRecord.id}`);

      // Update the table data after successful deletion
      setgoldenBatchNames(goldenBatchNames.filter((item: any) => item.id !== selectedRecord.id));
      setIsDeleteModalVisible(false);
      setSelectedRecord(null);
      Notiflix.Notify.success('Golden batch deleted successfully');
    } catch (error) {
      console.error('Error deleting golden batch:', error);
      Notiflix.Notify.failure('Failed to delete golden batch');
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedRecord(null);
  };

  // Handlers for edit confirmation
  const handleEdit = (record: any) => {
    setRecordToEdit(record);
    setIsEditModalVisible(true);
  };

  const handleEditConfirm = async (newName: string) => {
    try {
      await putRequest(`/golden-data/${recordToEdit.id}`, { name: newName });

      // Update the table data after successful edit
      setgoldenBatchNames(goldenBatchNames.map((item: any) =>
        item.id === recordToEdit.id ? { ...item, name: newName } : item
      ));

      setIsEditModalVisible(false);
      setRecordToEdit(null);
      Notiflix.Notify.success('Golden batch name updated successfully');
    } catch (error) {
      console.error('Error updating golden batch name:', error);
      Notiflix.Notify.failure('Failed to update golden batch name');
    }
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
    setRecordToEdit(null);
  };

  const handleChangeParam = () => {
    const currentUrl = window.location.pathname;
    const search = window.location.search;
    const queryParams = new URLSearchParams(search);

    if (
      queryParams.has("golden-parameter") &&
      queryParams.get("golden-parameter") === "true"
    ) {
      queryParams.delete("golden-parameter");

      const updatedSearch = queryParams.toString();
      const newUrl = `${currentUrl}${updatedSearch ? `?${updatedSearch}` : ""}`;

      window.history.replaceState({}, "", newUrl);
    }
  }

  const handleCompareGoldenValues = () => {
    const currentUrl = window.location.pathname;
    const queryParams = new URLSearchParams(window.location.search);
  
    // Check if selectedGoldenValueIds is valid (needs at least 2 selected values)
    if (selectedGoldenValueIds && selectedGoldenValueIds.length < 2) {
      Notify.warning("Please select two golden values to compare", { timeout: 2000, distance: '50px', clickToClose: true });
      return;
    }
  
    const goldenValueIds = selectedGoldenValueIds.join(',');

    queryParams.set('compare-golden-parameters', 'true');
    queryParams.set('goldenValueIds', goldenValueIds); 
    queryParams.set('tab', 'data'); 
    queryParams.set('golden-parameter', 'true');
  
    const newUrl = `${currentUrl}?${queryParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  
    // Update the section state for comparing golden values
    setGoldenValueSection('compare-golden-parameters');
  };
  
  


  const onSelectChange = (newSelectedRowKeys: any, selectedRows: any[]) => {

    if (newSelectedRowKeys.length <= 2) {
      setSelectedRowKeys(newSelectedRowKeys);
      const goldenValuesIds = selectedRows.map((item: { id: any; }) => item.id);
      setSelectedGoldenValueIds(goldenValuesIds)
    } else {
      Notify.warning("You can only select upto 2 golden rows", { timeout: 2000, distance: '50px', clickToClose: true })
    }
  }

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };



  const goldenValuesParameters = () => {
    return (
      <div className="golden-values p-5 w-full">
        <div className="relative">

        <div className="flex items-center gap-4 mb-4">
          <button
            onClick={() => { setDataContentSection("file-listing"); handleChangeParam(); }}
            className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-50"
          >
            <svg
              className="h-5 w-5 rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          <h2 className="!font-medium !text-[32px] !text-[#333] worksans">
            Golden Parameters
          </h2>
          <div className="ml-auto flex gap-4">
            {compareGoldenValue ? (
              <></>
            ) : (
              <div>
                <button
                  className="bg-primary text-white p-1 px-3 rounded"
                  onClick={() => {
                    setCompareGoldenValues(true);
                  }}
                >
                  Compare Golden Values
                </button>
              </div>
            )}

            <button
              className="bg-primary text-white p-1 px-3 rounded"
              onClick={() => {
                setDataContentSection("create-parameters");
                dispatch(setSelectedBatchDetails(null));
                setGoldenValueSection('golden-parameters');
              }}
            >
              Create Parameter
            </button>
          </div>
        </div>

        <div className="flex flex-wrap gap-3 justify-between bg-[#F7F7F7] p-2.5 border-t border-solid border-[#CCCCCC] border-b">
          <div className="flex flex-wrap gap-3 gap-y-3 items-center main-header flex-1">
            <div className="flex gap-1 gap-y-1 items-center">
              <div className="w-52">
                <Select
                  placeholder="Select CSV File"
                  className="select-file"
                  onChange={handleFileChange}
                  value={selectedFile}
                  // disabled={workflowFilterdSelected}
                  style={{
                    borderColor: workflowFilterdSelected ? '' : '#252963',
                    borderWidth: '1px',
                    borderRadius: '6px',
                  }}
                >
                  <Option key={1111} value={null}>
                    All Files
                  </Option>
                  {uploadedFiles.map((file: any) => (
                    <Option key={file.csv_id} value={file.csv_id}>
                      {file.file_name}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className="w-56 ml-2">
                <Select
                  placeholder="Apply workflow filters"
                  className="select-file"
                  onChange={handleWorkflowSavedFilter}
                  value={filterId?.name || 'Apply workflow filters'}
                  // disabled={!workflowFilterdSelected}
                  // style={{
                  //   borderColor: !workflowFilterdSelected ? '' : '#252963',
                  //   borderWidth: '1px', 
                  //   borderRadius:'6px',
                  // }}
                  showSearch={true}
                  optionFilterProp="children"
                  filterSort={(optionA, optionB) => {
                    const labelA = (optionA?.children ?? '').toString().toLowerCase();
                    const labelB = (optionB?.children ?? '').toString().toLowerCase();
                    return labelA.localeCompare(labelB);
                  }}
                >
                  {/* <Option key={1111} value={null}>
                  No workflow filter
                </Option> */}
                  {workflowFilters
                  .filter((filter: any) => filter.filter_type === 'global')
                  .map((filter: any) => (
                    <Option key={filter.id} value={filter.id}>
                      {filter.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap gap-4 items-center">
            <Button
              className="!bg-transparent !border-none shadow-none underline text-primary worksans p-0 hover:no-underline"
              onClick={handleFilterClick}
            // disabled={!selectedFile}
            >
              Apply Filters
            </Button>

            <Button
              className="!bg-transparent !border-none shadow-none underline text-[#999999] worksans p-0 hover:no-underline"
              onClick={clearFilters}
            // disabled={!selectedFile}
            >
              Clear Filters
            </Button>
          </div>
        </div>

        {compareGoldenValue && (
        <div className="absolute top-[-40px] flex flex-col justify-center w-full bg-[#0000005c] p-14 items-center">
          <div className="bg-white p-2 flex rounded text-center shadow-[0_0_10px_rgba(0,_0,_0,_0.3)] mr-4 mb-4 max-w-max">
            <img src={ticksvg} alt="tick svg" />
            <p className="px-3">Please select two golden values to proceed.</p>
          </div>
          <div className="flex gap-3 align-middle">
                <button
                  className="bg-primary text-white p-1 px-3 rounded h-9"
                  onClick={() => {
                    handleCompareGoldenValues();
                  }}
                >
                  Compare Now
                </button>
                <button
                  className="bg-primary text-white p-1 px-3 rounded h-9"
                  onClick={() => {
                    setCompareGoldenValues(false);
                    setSelectedRowKeys([]);
                    setSelectedGoldenValueIds([]);
                  }}
                >
                  Cancel
                </button>
              </div>
                <img src={crossIcon} alt="cross icon" className="absolute right-4 top-7 cursor-pointer"
                 onClick={() => {setCompareGoldenValues(false);
                  setSelectedRowKeys([]);
                  setSelectedGoldenValueIds([]);
                  }}/>
        </div>
      )}
        </div>
        <Table
          columns={columns}
          className="common-table"
          dataSource={goldenBatchNames.map((item: any, index: number) => ({
            key: index,
            ...item,
          }))}
          // rowSelection={compareGoldenValue ? rowSelection : undefined}
          rowSelection={
            compareGoldenValue ? {
              ...rowSelection,
              columnTitle: ' ',
            } : undefined
          }
          pagination={{ current: goldenDataPageNo, pageSize: pageSize, total: totalGoldenDataCount }}
          onChange={handlePagination}
        />

        <ConfirmDeleteModal
          type="Golden Batch"
          visible={isDeleteModalVisible}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          name={selectedRecord?.name || ''}
        />

        <EditNameModal
          type="Golden Batch"
          visible={isEditModalVisible}
          onConfirm={handleEditConfirm}
          onCancel={handleEditCancel}
          currentName={recordToEdit?.name || ''}
        />
      </div>
    )
  };

  return (
    <>
      {goldenValueSection === "golden-parameters" && goldenValuesParameters()}
      {goldenValueSection === "compare-golden-parameters" && <CompareGoldenValues setGoldenValueSection={setGoldenValueSection} />}
    </>
  );
};

export default GoldenValueTable;
