export const formatDate = (date: string | Date, options?: Intl.DateTimeFormatOptions): string => {
    if (!date) return '';
    try {
        const defaultOptions: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'short', 
            day: 'numeric',
        };
        const formatter = new Intl.DateTimeFormat('en-US', { ...defaultOptions, ...options });
        const dateObj = typeof date === 'string' ? new Date(date) : date;
        return formatter.format(dateObj);
    } catch (error) {
        console.error('Invalid date:', date, error);
        return '';
    }
};
