import { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../utils/apiHandler";
import { SaveGoldenBatchModal } from "../../Modal/goldenBatchModal";
import { Notify } from "notiflix";
import {
    QueryBuilder as ReactQueryBuilder,
} from "react-querybuilder";
import { Select } from "antd";
import { useSelector } from "react-redux";

interface Parameter {
    id: number
    name: string
}

interface FormData {
    [key: string]: string
}

const CreateParameter = ({ nodes = null, workflowData = null, setNewParameterPage, setDataContentSection = null, isEdit = false, setIsEdit }: any) => {
    const [parameters, setParameters] = useState<Parameter[]>([])
    const [formData, setFormData] = useState<FormData>({});
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [query, setQuery] = useState({ combinator: "and", rules: [] });
    const [targetQuery, setTargetQuery] = useState({ combinator: "and", rules: [] });
    // var formattedFields = workflowData?.columns_order?.map((field: string) => ({
    //     name: field,
    //     label: field,
    // }));
    const [workflowFilterOptions, setWorkflowFilterOptions] = useState<any>([])
    const [formattedFields, setFormattedFields] = useState<any>(null)
    const [selectedOption, setSelectedOption] = useState("");
    const [dropdownFilterName, setDropdownFilterName] = useState("");
    const [columns, setcolumns] = useState<any>([])
    
    const { selectedBatchDetails } = useSelector((state: any) => state.goldenBatch);
    
    useEffect(() => {
        getAllParameters();
        fetchWorkflowFilterData();

        if (workflowData && workflowData?.columns_order) {
            let fields = workflowData?.columns_order?.map((field: string) => ({
                name: field,
                label: field,
            }));
            console.log('fieldsssss', fields)
            setFormattedFields(fields)
        }
        if(workflowData && workflowData.filter_id){
            setSelectedOption(workflowData.filter_id)
        }
    }, [])

    const fetchWorkflowFilterData = async () => {
        try {
            const response = await getRequest(`/filter`);
            if (response?.data?.data) {
                setWorkflowFilterOptions(response?.data?.data)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (workflowData?.workflowComponents) {
            const savedQueries: Record<string, any> = {};
            let fileName;
            workflowData.workflowComponents.forEach((component: any) => {
                if (component.type === 'file') {
                    const node = nodes.find((n: any) => n.data.id === component.component);
                    if (node) {
                        savedQueries[node.data.label] = component.settings;
                        fileName = node.data.label
                    }
                }
            });
            if (fileName) {
                setQuery(savedQueries[fileName]);
                setTargetQuery(savedQueries[fileName]?.target_variable_settings);
            }
        }
    }, [workflowData]);


    const getAllParameters = async () => {
        const response = await getRequest('/configurations/parameters');
        setParameters(response?.data?.data)

        const initialFormData = response?.data?.data.reduce((acc: FormData, param: Parameter) => {
            acc[param.name] = ''
            return acc
        }, {})
        setFormData(initialFormData)

        if(selectedBatchDetails?.golden_value){
        
            const transformedGoldenValues = selectedBatchDetails?.golden_value.map((item: any, index: any) => ({
                id: index + 1,
                name: item.key
              }));

              setParameters(transformedGoldenValues)

              const initialFormData = transformedGoldenValues.reduce((acc: FormData, param: Parameter) => {
                acc[param.name] = ''
                return acc
            }, {})

            const updatedForm = updateFormWithGoldenValues(initialFormData, selectedBatchDetails);
            setFormData(updatedForm);
            // let transformedData: any = transformData(selectedBatchDetails?.settings?.datasource?.feature_filters, selectedBatchDetails?.settings?.datasource?.target_variable_settings)
                        // setQuery(transformedData);
            // setTargetQuery(transformedData.target_variable_settings);


            // console.log('selectedBatchDetails :', selectedBatchDetails);
            // const featureFilters = Object.entries(selectedBatchDetails?.settings?.datasource?.feature_filters || {}).map(([key, value]) => ({
            //     name: key,
            //     label: key,
            // }));
            
            // const targetVariableSettings = Object.entries(selectedBatchDetails?.settings?.datasource?.target_variable_settings || {}).map(([key, value]) => ({
            //     name: key,
            //     label: key,
            // }));
            
            // // Combine both arrays if needed
            // const combinedData = [...featureFilters, ...targetVariableSettings];
            // console.log('combinedData :', combinedData);
            // setFormattedFields(combinedData)

            setSelectedOption(selectedBatchDetails?.workflowFilter?.id)
            setDropdownFilterName(selectedBatchDetails?.workflowFilter?.name)
            handleWorkflowSavedFilter(selectedBatchDetails?.filter_id)
        }

    }

    function transformData(mainData: any, targetData: any) {
        return {
            "combinator": "and",
            "rules": Object.entries(mainData).map(([key, obj]: any) => ({
                "field": key,
                "operator": obj.operator === "==" ? "=" : obj.operator,
                "valueSource": "value",
                "value": obj.value
            })),
            "target_variable_settings": {
                "combinator": "and",
                "rules": Object.entries(targetData).map(([key, obj]: any) => ({
                    "field": key,
                    "operator": obj.operator,
                    "valueSource": "value",
                    "value": Array.isArray(obj.value) ? obj.value.join(",") : obj.value
                })),
            },
            "exclude_features": []
        };
    }

    const updateFormWithGoldenValues = (initialForm: any, selectedBatchDetails: any) => {
        if (!selectedBatchDetails?.golden_value?.length) {
            return initialForm; // Return the original form if no golden values exist
        }
    
        const goldenValuesMap = new Map(
            selectedBatchDetails?.golden_value.map((item: any) => [item.key, item.golden_value])
        );
    
        return Object.fromEntries(
            Object.entries(initialForm).map(([key, value]) => [
                key,
                goldenValuesMap.has(key) ? (goldenValuesMap.get(key) == 0 ? '0' : goldenValuesMap.get(key)) : value
            ])
        );
    };

    const handleInputChange = (name: string, value: string) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if(isEdit){
                handleModalSave(selectedBatchDetails?.name)
            }
            else{
                const allFieldsFilled = parameters.every((param) => formData[param.name]?.toString().trim() !== '');

                if (!allFieldsFilled) {
                    Notify.failure('All the fields are required')
                    return;
                }
    
                // Open the modal upon successful form submission
                setIsModalOpen(true);
            }

        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleModalSave = async (parameterName: any) => {
        try {
            // Prepare data for saving
            const formattedFormData = Object.entries(formData).map(([key, value]) => ({
                key,
                golden_value: parseInt(value, 10),
            }));


            type FeatureFilters = {
                [key: string]: { value: string; operator: string };
            };

            const newVariable = {
                datasource: {
                    feature_filters: {} as FeatureFilters,
                    target_variable_settings: {} as FeatureFilters,
                },
            };

            const targetVariable = {
                datasource: {
                    target_variable_settings: {} as FeatureFilters,
                },
            };

            query.rules.forEach((rule: any) => {
                newVariable.datasource.feature_filters[rule.field] = {
                    value: rule.value,
                    operator: rule.operator === "=" ? "==" : rule.operator,
                };
            });
            targetQuery.rules.forEach((rule: any) => {
                targetVariable.datasource.target_variable_settings[rule.field] = {
                    value: rule.operator == 'between' ? [rule.value] : rule.value,
                    operator: rule.operator === "=" ? "==" : rule.operator,
                };
            });



            newVariable.datasource.target_variable_settings = targetVariable.datasource.target_variable_settings;
            let manipulateQuery = {
                ...query,
                target_variable_settings: targetQuery
            }
            const payload: any = {
                golden_values: formattedFormData,
                golden_name: parameterName,
                settings: manipulateQuery,
                filterId: selectedOption,
            };
            if(isEdit){
                payload.goldenId = selectedBatchDetails?.id
            }

            const response = await postRequest('/golden-data/create-parameter', payload);
            console.log('Data saved successfully:', response);
            const clearedFormData = Object.keys(formData).reduce((acc, key) => {
                acc[key] = ''; // Set all fields to empty
                return acc;
            }, {} as FormData);

            setFormData(clearedFormData);
            setQuery({ combinator: "and", rules: [] })
            setTargetQuery({ combinator: "and", rules: [] })
            setSelectedOption("")
            Notify.success('Values saved successfully')
            // Close the modal after saving
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const formatLabel = (name: string): string => {
        return name
            // Split on capital letters and join with space
            .replace(/([A-Z])/g, ' $1')
            // Capitalize first letter
            .replace(/^./, str => str.toUpperCase())
            // Handle "In" and "RPM" cases
            .replace(' In ', ' in ')
            .replace(' Rpm', ' RPM')
    }

    const handleWorkflowSavedFilter = async (id: any) => {
        if (id !== "-1") {
            await getAndSetSavedFilters(id);
            return;
        }
        // When no filter is selected, reset excluded features, filter features, and target variables
        let targetData = { ...targetQuery };
        targetData.rules = [];
        let data = { ...query };
        data.rules = [];
        setTargetQuery(targetData);
        setQuery(data);
    };

    const getAndSetSavedFilters = async (id: any) => {
        try {
            const response = await getRequest(`/filter/${id}`);
            let excludedColumns = response.data.data[0].filters.file.exclude_features;
            let newColumns: any;
            if (response.data.data[0]) {
                // newColumns = await getFileColumns(response.data.data[0].csv_id)
            }
            let targetVariableSettings = []
            if (response?.data?.data[0]?.filters?.file?.target_variable_settings?.rules) {
                let targetRules = response.data.data[0].filters.file?.target_variable_settings;
                // const filteredTargetRules =
                //   response.data.data[0].filters.file?.target_variable_settings.rules.filter(
                //     (rule: { field: string }) =>
                //       workflowData?.columns_order.includes(rule.field)
                // );
                const filteredTargetRules =
                    response.data.data[0].filters.file?.target_variable_settings.rules.filter(
                        (rule: { field: string }) =>
                            // newColumns.includes(rule.field)
                            rule.field
                    );
                targetRules.rules = filteredTargetRules.length
                    ? filteredTargetRules
                    : [];
                     targetVariableSettings = filteredTargetRules.map((res:any) => ({
                        name: res.field,
                        label: res.field,
                    }));
                setTargetQuery(targetRules);
            }

            let data = { ...query };
            data.rules = [];
            let featureFilters = []
            if (response.data.data[0].filters.file?.global.rules) {
                const filteredRules =response.data.data[0].filters.file?.global.rules.filter((rule: { field: string }) =>
                            // newColumns.includes(rule.field)
                            rule.field
                    );

                data.rules = filteredRules ? filteredRules : [];

                featureFilters = filteredRules.map((res:any) => ({
                    name: res.field,
                    label: res.field,
                }));
            }
            setQuery(data);
            const combinedData = [...featureFilters, ...targetVariableSettings];
            setFormattedFields(combinedData)

        } catch (error) {
            console.log("Error:", error);
        }
    };


    // Handle dropdown change event
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = event.target.value;
        setSelectedOption(selectedId);
        handleWorkflowSavedFilter(selectedId);
    };

    const getFileColumns = async (csvId: any) => {
        try {
            let response = await getRequest(`/file/columns/${csvId}`)
            if (response?.data?.data) {
                setcolumns(response.data.data.columns)
                let formattedFields = response.data.data.columns?.map((field: string) => ({
                    name: field,
                    label: field,
                }))
                setFormattedFields(formattedFields)
                return response.data.data.columns
            }
        } catch (error) {
            console.log('error', error)

        }
    }

    return (
        <div className="flex-1 min-h-screen bg-gray-50 py-5 px-4 sm:px-5 lg:px-5 sm:pt-5">
            <div className="w-full mx-auto">
                <h1 className="text-[32px]  font-medium items-center  text-[#333] worksans mb-4 flex gap-2 ">
                    <button
                        onClick={() => {
                            if (workflowData) {
                                setNewParameterPage(false);
                            } else {
                                setDataContentSection('golden-parameters');
                                setIsEdit(false);
                            }
                        }}
                        className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-50"
                    >
                        <svg
                            className="h-5 w-5 rotate-90"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </button>
                    {isEdit ? 'Edit Parameter' : 'Create Parameter'}
                </h1>
                <div className="bg-white shadow-sm rounded-lg">
                    <div className="px-4 py-5 sm:p-6  ">
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 border p-4 rounded mb-4">
                                {parameters.map((param: any) => (
                                    <div key={param.id} className="flex flex-col">
                                        <label
                                            htmlFor={param.name}
                                            className="block text-sm font-medium text-gray-700 mb-1"
                                        >
                                            {/* {formatLabel(param.name)} */}
                                            {param.name}
                                        </label>
                                        <input
                                            type="text"
                                            id={param.name}
                                            name={param.name}
                                            value={formData[param.name] || param?.golden_value || ''}
                                            onChange={(e) => handleInputChange(param.name, e.target.value)}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm
                                            px-3 py-2 border focus:outline-none"
                                            required
                                        />
                                    </div>
                                ))}
                            </div>


                            <div className="border p-4 rounded mb-4">
                                <div className="flex justify-between gap-2 mb-4">
                                    <h2 className="text-lg font-medium">Settings</h2>
                                    <select
                                        id="workflowFilterDropdown"
                                        value={selectedOption}
                                        onChange={handleChange}
                                        className="border text-left border-[#B3B3B3] text-[#4D4D4D] worksans
                                        bg-[#F7F7F7] text-md h-[32px] rounded-md min-w-40 px-3 py-1"
                                    >
                                        <option value="-1">No Filter</option>
                                        {workflowFilterOptions
                                        .filter((filter: any) => filter.filter_type === 'global')
                                        .map((option: { id: any; name: string }) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="mb-4 pointer-events-none disable-builder">
                                    <h3>Query Builder</h3>
                                    <ReactQueryBuilder
                                        fields={formattedFields}
                                        query={query}
                                        onQueryChange={setQuery as any}
                                    />
                                </div>
                                <div className="pointer-events-none disable-builder">
                                    <h3>Set Target Variable</h3>
                                    <ReactQueryBuilder
                                        fields={formattedFields}
                                        query={targetQuery}
                                        onQueryChange={setTargetQuery as any}
                                    />
                                </div>
                            </div>

                            <div className="mt-6 flex">
                                <button
                                    type="submit"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-primary px-6 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <SaveGoldenBatchModal isOpen={isModalOpen} onClose={() => { setIsModalOpen(false) }} onSave={handleModalSave} />
        </div>
    )
};

export default CreateParameter;
