import React, { useState, useEffect } from 'react';
import { Modal, Input, Button } from 'antd';

interface EditConfigSaveModalProps {
  closeFilter: React.Dispatch<React.SetStateAction<boolean>>
  visible: boolean;
  filterDetails:any;
  createNew:boolean;
  onConfirm: (filterDetails?: any) => void;
  onUpdate: (filterDetails?: any) => void;
  onCancel: () => void;
  currentConfigName: string;
}

const EditConfigSaveModal: React.FC<EditConfigSaveModalProps> = ({
  closeFilter, 
  visible, 
  filterDetails,
  createNew,
  onConfirm,
  onUpdate, 
  onCancel, 
  currentConfigName 
}) => {
  const [configName, setConfigName] = useState(currentConfigName);
  const [error, setError] = useState('');
  const [isCreating, setIsCreating] = useState(true);

  useEffect(() => {
    setConfigName(currentConfigName);
    setError('');
  }, [currentConfigName, visible]);

  const handleConfirm = () => {
    if (!configName.trim()) {
      setError('Filter name cannot be empty');
      return;
    }
    filterDetails.filter.type='create'
    filterDetails.filter.name=configName.trim()
    onConfirm(filterDetails);
    setError('');
  };
  const handleUpdate = () => {
    filterDetails.filter.type='update'
    onUpdate(filterDetails);
  };
  const handleCancel = () =>{
    closeFilter(false)
  }

  return (
    <Modal
      title={createNew ? 'Enter Filter Name' : 'You have made some changes in the filter would you like to update it or create new filter'}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      maskClosable={false}
      closable={false}
    >
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          {
            !createNew &&
            <div>
          <Button style={{margin:'8px'}} type={isCreating ? "default" : "primary"} onClick={() => setIsCreating(false)}>
            Update
          </Button>
          <Button type={isCreating ? "primary" : "default"} onClick={() => setIsCreating(true)}>
            Create
          </Button>
            </div>
          }
        </div>

        {isCreating && (
          <>
            <Input
              value={configName}
              onChange={(e) => {
                setConfigName(e.target.value);
                setError('');
              }}
              placeholder="Enter Filter Name"
              className="w-full px-4 py-2"
            />
            {error && <span className="text-red-500 text-sm">{error}</span>}
            <Button type="primary" onClick={handleConfirm} className="mt-2">
              Create Filter
            </Button>
            <Button style={{width:'100%'}}  onClick={handleCancel} className="mt-2">
              Cancel
            </Button>
          </>
        )}
        {!isCreating && (
          <>
            {/* <Input
              value={configName}
              onChange={(e) => {
                setConfigName(e.target.value);
                setError('');
              }}
              placeholder="Enter Filter Name"
              className="w-full px-4 py-2"
            /> */}
            {/* {error && <span className="text-red-500 text-sm">{error}</span>} */}
            <div className='mt-12 w-full'>
            <Button style={{width:'100%'}} type="primary" onClick={handleUpdate} className="mt-2">
              Update Filter
            </Button>
            <Button style={{width:'100%'}}  onClick={handleCancel} className="mt-2">
              Cancel
            </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default EditConfigSaveModal;