import { Flex } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import data from "../../img/data.svg";
import insight from "../../img/insight.svg";
import arrowleft from "../../img/arrow-left.svg";

import ParallelTimeSeriesChart from "../charts/ParallelTimeSeriesChart";
import DataTable from "../tables/DataTable";
import ReactFlow, { Node } from "reactflow";
import "reactflow/dist/style.css";
import FlowCanvas from "./FlowCanvas";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import QueryBuilder from "./QueryBuilder";
import OperationConfig from "./OperationConfig";
import file from "../../img/file.svg";
import box from "../../img/box.svg";
import { getRequest } from "../../utils/apiHandler";
import Notiflix from "notiflix";
import InsightTabContent from './Insight Section/InsightTabContent';
import { useLocation, useNavigate } from 'react-router-dom';  // Import useLocation from React Router
import { Histogram } from "../charts/Histogram";
import { Menu } from 'antd';
import { useAuth } from '../../context/AuthContext';
import DataTab from "./Data Section/DataTab";
import InsightContent from "./Insight Section/InsightTab";
import InsightTab from "./Insight Section/InsightTab";
import AlertTab from "./Alert Section/AlertTab";
import AlertTabContent from "./Alert Section/AlertTabContent";
import DataTabContent from "./Data Section/DataTabContent";
import FolderStructure from "./FolderStructure";

interface FileData {
  csv_id: string;
  file_name: string;
  file_path: string;
  version: number;
  created_at?: string;
  updated_at?: string;
}

function WorkbookContainer() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [collapse, setCollapse] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<FileData[]>([]);
  const [originalChartData, setOriginalChartData] = useState<any>({
    timeSeries: { categories: [], series: [] },
  });
  const [filteredChartData, setFilteredChartData] =
    useState<any>(originalChartData);
  const [originalFileData, setOriginalFileData] = useState<any>([]);
  const [filteredFileData, setFilteredFileData] = useState<any[]>([]);
  const [showVisualisation, setShowVisualization] = useState<Boolean>(true);
  const [dataContentSection, setDataContentSection] = useState<"file-listing" | "data-visualisation" | "golden-parameters" | "golden-parameters-details" | "create-parameters">("file-listing");
  const [nodes, setNodes] = useState<Node[]>([]);
  const [fileQueries, setFileQueries] = useState<Record<string, any>>({});
  const [currentFile, setCurrentFile] = useState<string | null>(null);
  const [showQueryBuilder, setShowQueryBuilder] = useState(false);
  const [showOperationConfig, setShowOperationConfig] = useState(false);
  const [queries, setQueries] = useState({});
  const [nodeConfigurations, setNodeConfigurations] = useState<Record<string, any>>({});
  const [workbookId, setWorkbookId] = useState(1);
  const [activeDashboard, setActiveDashboard] = useState<string>("");

  // Dynamic SubPlot Data & Layout State
  const [dynamicPlotData, setDynamicPlotData] = useState<any>([]);
  const [dynamicPlotLayout, setDynamicPlotLayout] = useState<any>([]);
  
  // const [selectSystems,setSelectSystems] = useState<any>([]);
  // const [reloadSelectSystems,setReloadSelectSystems] = useState<boolean>(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { authState } = useAuth();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const goldenParameter = params.get('golden-parameter');
    if (goldenParameter == 'true') {
      setDataContentSection('golden-parameters')
    }
    else {
      setDataContentSection('file-listing')
    }
  }, [])

  useEffect(() => {
    const currentUrl = window.location.pathname;
    const search = window.location.search;
    const queryParams = new URLSearchParams(search);

    if (
      queryParams.has("golden-parameter") &&
      queryParams.get("golden-parameter") === "true" &&
      queryParams.get("tab") !== "data"
    ) {
      setDataContentSection('file-listing')
      queryParams.delete("golden-parameter");

      const updatedSearch = queryParams.toString();
      const newUrl = `${currentUrl}${updatedSearch ? `?${updatedSearch}` : ""}`;

      window.history.replaceState({}, "", newUrl);
    }
  }, [window.location.pathname, window.location.search]);

  // Handler to save the query for a specific file
  const handleSaveQuery = (query: any, fileName: string) => {
    setQueries((prevQueries) => ({
      ...prevQueries,
      [fileName]: query,
    }));
    setShowQueryBuilder(false);
    console.log(`Query for ${fileName} saved:`, query);
  };

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    // setReloadSelectSystems(false)
  }, []);

  const onDrop = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    // setReloadSelectSystems(false)
    const data = JSON.parse(
      event.dataTransfer.getData("application/reactflow")
    );
    const dropPosition = {
      x: event.nativeEvent.offsetX,
      y: event.nativeEvent.offsetY,
    };

    const newNode: Node = {
      id: `${data.type}-${Date.now()}`,
      type: "custom",
      position: dropPosition,
      data: {
        label: data?.name,
        id: data?.id,
        type: data?.type,
        file_path: data?.data?.file_path,
        columns: data?.data?.columns,
        isConnected: false,
        onFilterClick: () =>
          handleNodeFilterClick(data.name, data.type, data.columns),
      },
    };

    setNodes((prevNodes) => [...prevNodes, newNode]);
  }, []);

  const handleNodeFilterClick = (
    fileName: string,
    nodeType: string,
    columns: []
  ) => {
    if (nodeType === "file") {
      if (currentFile && currentFile !== fileName && fileQueries[currentFile]) {
        const shouldSave = window.confirm(
          "Do you want to save changes to the current query?"
        );
        if (shouldSave) {
          handleSaveQueryBuilder();
        }
      }
      setCurrentFile(fileName);
      setShowQueryBuilder(true);
      setShowOperationConfig(false);
    } else if (nodeType === "operation") {
      setShowOperationConfig(true);
      setShowQueryBuilder(false);
    }
  };

  const renderTabs = () => {
    const params = new URLSearchParams(location.search);
    const workflowId = params.get('workflowId');
    let showFolderComponent = false;
    if (workflowId === '0') {
      showFolderComponent = true
    }
    console.log('showFolderComponent', showFolderComponent)
    const updateFilelist = (draggedFile: any) => {
      if ((draggedFile as any)?.csv_id) {
        setUploadedFiles((prevFiles: any[]) =>
          prevFiles.map((file: any) =>
            file.csv_id == (draggedFile as any)?.csv_id
              ? { ...file, compatibility: true }
              : file
          )
        );
      }
    }
    return (
      <>
        <div className={activeTab == 'tab1' ? "visible" : "hidden"}>
          <DataTab
            workbookId={workbookId}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            originalChartData={originalChartData}
            setOriginalChartData={setOriginalChartData}
            setFilteredChartData={setFilteredChartData}
            originalFileData={originalFileData}
            setOriginalFileData={setOriginalFileData}
            filteredChartData={filteredChartData}
            setFilteredFileData={setFilteredFileData}
            setShowVisualization={setShowVisualization}
            setActiveTab={setActiveTab}
            setDataContentSection={setDataContentSection}
            setDynamicPlotData={setDynamicPlotData}
            setDynamicPlotLayout={setDynamicPlotLayout}
          />
        </div>

        <div className={activeTab == 'tab2' && showFolderComponent ? "visible" : "hidden"}>
          <FolderStructure />
        </div>
        <div className={activeTab == 'tab2' && !showFolderComponent ? "visible" : "hidden"}>
          <InsightTab nodes={nodes} updateFilelist={updateFilelist} />
        </div>
        <div className={activeTab == 'tab3' ? "visible" : "hidden"}>
          <AlertTab activeDashboard={activeDashboard} setActiveDashboard={setActiveDashboard} />
        </div>


      </>

    )
    switch (activeTab) {
      case "tab1":
        return (
          <DataTab
            workbookId={workbookId}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            originalChartData={originalChartData}
            setOriginalChartData={setOriginalChartData}
            setFilteredChartData={setFilteredChartData}
            originalFileData={originalFileData}
            setOriginalFileData={setOriginalFileData}
            filteredChartData={filteredChartData}
            setFilteredFileData={setFilteredFileData}
            setShowVisualization={setShowVisualization}
            setActiveTab={setActiveTab}
            setDataContentSection={setDataContentSection}
          />
        );
      case "tab2":
        // return <InsightTab />;
        // return workflowId === '0' ? <FolderStructure /> : <InsightTab />;
        return (
          <>
            <div className={showFolderComponent ? "visible" : "hidden"}>
              <FolderStructure />
            </div>
            <div className={!showFolderComponent ? "visible" : "hidden"}>
              <InsightTab />
            </div>
          </>
        );
      case "tab3":
        return <AlertTab activeDashboard={activeDashboard} setActiveDashboard={setActiveDashboard} />
      default:
        return null;
    }
  };

  // Update active tab and workflowId based on URL params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get('tab');
    const workflowIdParam = params.get('workflowId');
    const fileParam = params.get('file');
    const savedFile = localStorage.getItem("selectedFile");

    if (!tabParam) {
      navigate("/?tab=data", { replace: true });
      return; // Exit early since `navigate` will trigger the useEffect again
    }
    // Handle tab switching
    if (tabParam) {
      switch (tabParam) {
        case "insight":
          setActiveTab("tab2");
          break;
        case "data":
          setActiveTab("tab1");
          if (fileParam && !savedFile) {
            navigate("/?tab=data", { replace: true });
          }
          break;
        case "dashboard":
          setActiveTab("tab3");
          break;
      }
    }

    // Set the workflow ID based on the URL param `workflowId`
    if (workflowIdParam) {
      const workflowId = Number(workflowIdParam);
      if (workflowId === 0) {
        setWorkbookId(0);  // Indicate new workflow
      } else {
        setWorkbookId(workflowId);  // Set existing workflow ID
        fetchWorkflowData(workflowId);  // Fetch existing workflow data
      }
    }
    setShowOperationConfig(false)
    setShowQueryBuilder(false)
  }, [location.search]);  // Re-run effect whenever URL changes

  // Fetch workflow data from the API if the workflowId is greater than 0
  const fetchWorkflowData = async (workflowId: number) => {
    try {
      if (workflowId) {
        // You can update state with the fetched workflow data here
        // For example, setting files or other workflow-related data:
        // setUploadedFiles([]);
        // set([]);

        // Handle other state updates as needed based on the fetched data
      }
    } catch (error) {
      console.error("Error fetching workflow data:", error);
      Notiflix.Notify.failure("Failed to fetch workflow data");
    }
  };

  const handleFileDoubleClick = async (csvId: string) => {
    try {
      const response = await getRequest(`/file/${csvId}`);
      if (response.data.status === 200) {
        const fileData = response.data.data;
        setOriginalFileData(fileData);
        setFilteredFileData(fileData);
        setDataContentSection("data-visualisation");

        // Update URL to include the file ID
        // navigate(`/?tab=data&file=${csvId}`);
      }
    } catch (error) {
      console.error('Error fetching file data:', error);
      Notiflix.Notify.failure('Failed to fetch file data');
    }
  };

  const backToFileListing = async () => {
    setDataContentSection("file-listing");
    // Remove file parameter from URL
    navigate('/?tab=data');
  };

  const handleSaveQueryBuilder = (queryData?: any) => {
    if (currentFile && queryData) {
      setNodes(prevNodes =>
        prevNodes.map(node => {
          if (node.data.label === currentFile) {
            return {
              ...node,
              data: {
                ...node.data,
                query: queryData,
                hasQueryConfig: true
              }
            };
          }
          return node;
        })
      );
      console.log('Saving query for file:', currentFile, queryData);
    }
    setShowQueryBuilder(false);
  };

  // Add this function to check if user has access to dashboard
  const hasDashboardAccess = () => {

    const allowedUsers = [
      "coca",
      "chemical",
      "dairy",
      'carbonblack_processengineer',
      'cement'
    ];
    return allowedUsers.includes(authState?.user?.first_name?.toLowerCase() || '');
  };

  // Update the tab click handlers to only include workflowId for insight tab
  const handleTabClick = (tab: string, urlParam: string) => {
    setActiveTab(tab);

    if (urlParam === 'insight') {
      // For insight tab, include workflowId
      const params = new URLSearchParams(location.search);
      const workflowId = params.get('workflowId') || '0';
      navigate(`/?tab=insight&workflowId=${workflowId}`);
    } else {
      // For data and dashboard tabs, only include tab parameter
      navigate(`/?tab=${urlParam}`);
      setDataContentSection('file-listing')
    }
  };

  const handleTabChange = (tabId: any) => {
    setActiveTab(tabId)

    const params = new URLSearchParams(window.location.search);
    let currentTab = tabId == 'tab1' ? 'data' : tabId == 'tab2' ? 'insight' : tabId == 'tab3' ? 'dashboard' : 'insight';
    params.set("tab", currentTab);
    window.history.pushState({}, "", `?${params.toString()}`);
  }

  return (
    <Flex
      flex={1}
      align="start"
      className={
        collapse
          ? "sidebar-remove relative sidebar-outer"
          : "relative sidebar-outer"
      }
    >
      {/* Tabs Section */}
      <div className="insight-tabs">
        {/* Tab Buttons */}
        <Flex justify="space-between" className="tabs-header">
          <button
            onClick={() => handleTabClick("tab1", "data")}
            style={
              activeTab === "tab1"
                ? {
                  color: "#000",
                  background: "#E9E9F5",
                  borderBottomColor: "#32377F",
                }
                : {}
            }
          >
            <img src={data} alt="data" /> Data
          </button>
          <button
            onClick={() => handleTabClick("tab2", "insight")}
            style={
              activeTab === "tab2"
                ? {
                  color: "#000",
                  background: "#E9E9F5",
                  borderBottomColor: "#32377F",
                }
                : {}
            }
          >
            <img src={insight} alt="insight" /> Insight
          </button>
          {/* Only show dashboard button for authorized users */}
          {hasDashboardAccess() && (
            <button
              onClick={() => handleTabClick("tab3", "dashboard")}
              style={
                activeTab === "tab3"
                  ? {
                    color: "#000",
                    background: "#E9E9F5",
                    borderBottomColor: "#32377F",
                  }
                  : {}
              }
            >
              <img src={box} alt="dashboard" /> Dashboard
            </button>
          )}
        </Flex>

        {/* Tabs Body */}
        <Flex vertical className="tab-content">
          <button
            className={collapse ? "active arrow-btn1" : "arrow-btn1"}
            onClick={() => setCollapse(!collapse)}
          >
            <img src={arrowleft} alt="arrow left" />
          </button>
          <div className="tab-inner">
            {renderTabs()}
          </div>
        </Flex>
      </div>

      {/* Tabs Content */}
      <Flex className="common-box !p-0" flex={1} vertical>
        {/* {activeTab === "tab1" && ( */}
        <div className={`${activeTab === "tab1" ? "block" : "hidden"}`}>
          <DataTabContent
            dataContentSection={dataContentSection}
            setDataContentSection={setDataContentSection}
            setUploadedFiles={setUploadedFiles}
            handleFileDoubleClick={handleFileDoubleClick}
            file={file}
            uploadedFiles={uploadedFiles}
            filteredFileData={filteredFileData}
            setFilteredFileData={setFilteredFileData}
            dynamicPlotData={dynamicPlotData}
            setDynamicPlotData={setDynamicPlotData}
            dynamicPlotLayout={dynamicPlotLayout}
            setDynamicPlotLayout={setDynamicPlotLayout}
          />
        </div>
        {/* {activeTab === "tab2" && ( */}
        <div className={`${activeTab === "tab2" ? "block" : "!hidden"} work-book-box !border-none`} style={{ height: '100%' }}>
          <div className="component-data" style={{ height: 'calc(100% - 40px)' }}>
            <InsightTabContent
              nodes={nodes}
              setNodes={setNodes}
              onDrop={onDrop}
              onDragOver={onDragOver}
              showQueryBuilder={showQueryBuilder}
              setShowQueryBuilder={setShowQueryBuilder}
              onSaveQuery={handleSaveQuery}
              showOperationConfig={showOperationConfig}
              setShowOperationConfig={setShowOperationConfig}
              handleSaveQueryBuilder={handleSaveQueryBuilder}
            // setSelectSystems={setSelectSystems}
            // setReloadSelectSystems={setReloadSelectSystems}
            />
          </div>
        </div>

        <div className={`${activeTab == "tab3" ? "block" : "!hidden"}`}>
          <AlertTabContent activeDashboard={activeDashboard} />
        </div>

        {/* {activeTab === "tab3" && (
          <>
            <AlertTabContent activeDashboard={activeDashboard}/>
          </>
        )} */}
      </Flex>
      <div className="overlay-text">R64.3.1-v202405071618-SNAPSHOT</div>
    </Flex>
  );
}

export default WorkbookContainer;
