
import { useEffect, useRef, useState } from "react"
import { Select, Table, Tooltip, Badge ,Button,Input, Space, Modal,} from "antd"
import type { ColumnsType } from "antd/es/table"
import type {  TableColumnType } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { postRequest } from "../../utils/apiHandler";
import SaveStatisticalDataModal from "../Modal/SaveStatisticalDataModal";
import { Notify } from "notiflix";
const StaticalAnalysisTable: any = (StaticalAnalysisData:any ) =>{
console.log('StaticalAnalysisData :', StaticalAnalysisData);
  const location = useLocation()
 const [isModalVisible , setIsModalVisible] = useState<boolean>(false)
 const [filterValue, setFilterValue] = useState("mean")
    
 const  firstKey =  Object.keys(StaticalAnalysisData.clustered_agg[0])[0]
 const objectKeys = Object.keys(StaticalAnalysisData.clustered_agg[0][firstKey])

 let obj : Record<string, string> = {
    mean:'Mean',
    median:'Median',
    min:'Minimum',
    max:'Maximum',
    std:'Standard Deviation',
    var:'Varience'
 }

 let StatisticalMethodOption = objectKeys.map((res)=>{
    return{
        value : res,
        label : obj[res] || res 
    }
 })


  useEffect(()=>{

    handleFilterChange(filterValue)
  },[StaticalAnalysisData])



  const extractValuesByType = (data: any[], type: string) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
        return [];
    }
    const extractedResults: Record<string, string>[] = [];
    for (const entry of data) {
        const extractedData: Record<string, string> = {};
        for (const key in entry) {
            if (key !== "Cluster" && typeof entry[key] === "object") {
                if (entry[key][type] !== undefined) {
                    extractedData[key] = entry[key][type].toFixed(2).toString();
                }
            } else if (key === "Cluster") {
                extractedData[key] = entry[key].toString();
            }
        }

        extractedResults.push(extractedData);
    }
    return extractedResults;
};




  const [data, setData] = useState<any>([{}])

  const columnsData = Object.keys(StaticalAnalysisData.clustered_agg[0])
  const columns: ColumnsType<any> = [
    {
        title: () => (
            <div className="flex items-center ml-3 gap-1">
              Cluster
            </div>
          ),
        dataIndex: "Cluster",
        key: "Cluster",
        className: "font-medium",
        render: (text, record) => (
          <div className="pl-4 flex items-center gap-2">
            {record.status === "active" ? (
              <Badge status="processing" color="#10b981" />
            ) : (
              <Badge status="default" color="#e5e7eb" />
            )}
            <span className={record.status === "active" ? "text-gray-900" : "text-gray-500"}>{text}</span>
          </div>
        ),
    },
      ...columnsData.filter(res=> res !='Cluster').map((res: string) => ({
        title: () => (
            <div className="flex items-center justify-center gap-1">
              {res}
            </div>
          ),
        
        dataIndex: res,
        key: res,
        render: (value:any) => renderCell(value),
        // ...getColumnSearchProps(res)
      })),
  ]

  const renderCell = (value: string | null) => {
    if (value === null) return <div className="text-gray-300 flex items-center justify-center gap-1">-</div>
    return <div className="font-medium text-gray-900 flex items-center justify-center gap-1">{value}</div>
  }

  const handleFilterChange = (value: string) => {
    setFilterValue(value)
    const requiredData = extractValuesByType(StaticalAnalysisData.clustered_agg , value)
    // console.log('requiredData :', requiredData);
    setData(requiredData)
  }

  const saveStastisticalData = async (name :string) =>{
    const params = new URLSearchParams(location.search);
    const workflowId = params.get('workflowId');
    setIsModalVisible(false)

    let payload = {
      name : name , 
      workflow_id : workflowId ,
      bucketization_statistical_value : StaticalAnalysisData.clustered_agg
    }

    const response = await postRequest('/bucketization/save-statistical-data',payload)
    if(response.data.status == 200){
      Notify.success("Material statistical data saved successfully")
    }
    
  }

  return (
    <div className="p-0">
      <div className="bg-white rounded-2xl p-8 shadow-lg border border-gray-100">
        <div className="mb-0">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Data Analysis</h2>
          <p className="text-gray-500 mb-6">View and analyze the data with different statistical methods</p>

          <div className="flex flex-wrap items-center justify-between gap-4 mb-6">
            <div className="flex items-center gap-3">
              <label htmlFor="filter-select" className="text-sm font-medium text-gray-700">
                Statistical Method:
              </label>
              <Select
                id="filter-select"
                value={filterValue}
                onChange={handleFilterChange}
                className="w-40"
                options={StatisticalMethodOption}
                dropdownStyle={{ borderRadius: "0.5rem" }}
              />
            {
              !StaticalAnalysisData?.isMaterial &&
            <div className="flex">
              <Button onClick={()=>setIsModalVisible(true)}>Save</Button>
            </div>
            }
            </div>
          </div>
        </div>

        <div className="overflow-hidden rounded-xl border border-gray-200 bg-gradient-to-b from-gray-50/50 to-white">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            // bordered={false}
            className="custom-table"
            rowClassName={(record) =>
              `transition-colors ${record.status === "active" ? "bg-green-50/30" : ""} hover:bg-blue-50/30`
            }
            // size="middle"
            scroll={{
                x: columnsData.length * 100,
                y: 60 * 10,
            }}
          />
        </div>
        {
          isModalVisible &&
          <SaveStatisticalDataModal visible={true}
            onConfirm={(name) => {
              saveStastisticalData(name)
                  // setIsFileModalVisible(false);
            }}
            onCancel={() => setIsModalVisible(false)}
          />
        }
      </div>
    </div>
  )
}
export default StaticalAnalysisTable;