import React, { useState } from "react";
import { Table } from "antd";
import { useSelector } from 'react-redux';

interface GoldenBatchDetails {
  name: string;
  settings: {
    datasource: {
      exclude_features: string[];
      target_variable_settings: Record<string, { operator: string; value: string | string[] }>;
      feature_filters: Record<string, { operator: string; value: string | string[] }>;
    };
    golden_cluster: {
      clustering_model: string;
    };
    rca: {
      rca_runs: boolean;
    };
  };
  golden_value: Array<{
    key: string;
    golden_value: number;
  }>;
}

interface GoldenValueDetailProps {
  setDataContentSection: (section: string) => void;
}

const capitalizeFirstLetter = (str: string) => {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const GoldenValueDetail: React.FC<GoldenValueDetailProps> = ({
  setDataContentSection
}) => {
  const { selectedBatchDetails } = useSelector((state: any) => state.goldenBatch);
  console.log('selectedBatchDetails', selectedBatchDetails)

  const [activeIndex, setActiveIndex] = useState<number | null>();

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Helper function to check if object is empty
  const isEmptyObject = (obj: any) => {
    if(!obj) return true
    return obj && Object.keys(obj).length === 0;
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srNo",
      key: "srNo",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Parameter",
      dataIndex: "key",
      key: "parameter",
    },
    {
      title: "Golden Value",
      dataIndex: "golden_value",
      key: "goldenValue",
      render: (value: any) => typeof value === 'number' && !isNaN(value) ? value.toFixed(2) : value,
    },
  ];

  return (
    <React.Fragment>
      <div className="p-5 w-full">
        <div className="flex items-center gap-4 mb-4">
          <button 
            onClick={() => setDataContentSection("golden-parameters")}
            className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-50"
          >
            <svg
              className="h-5 w-5 rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          <h2 className="!font-medium !text-[32px] !text-[#333] worksans">
            Golden Parameter {selectedBatchDetails?.name}
          </h2>
        </div>

        <div className="w-full mx-auto bg-white shadow-md rounded-lg flex flex-col gap-6 p-[30px]">
          {/* Excluded Columns Accordion */}
          <div className="">
            <button
              className="w-full flex justify-between bg-[#F3F3F3] items-center border border-solid border-[#00000026] py-3 px-5 text-left text-black focus:outline-none hover:bg-gray-100"
              onClick={() => toggleAccordion(0)}
            >
              <span className="font-medium text-lg">Excluded Column</span>
              <svg
                className={`h-5 w-5 transform transition-transform ${
                  activeIndex === 0 ? "rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            <div
              className={`${
                activeIndex === 0 ? "block" : "hidden"
              } text-gray-600 p-5 border border-solid border-[#00000026] rounded-b-[10px] border-t-0`}
            >
              {selectedBatchDetails?.settings?.datasource?.exclude_features?.length > 0 ? (
                <div className="flex flex-wrap gap-2.5">
                  {selectedBatchDetails?.settings?.datasource?.exclude_features.map(
                    (feature:any, index:any) => (
                      <span key={index} className="px-3 py-[7px] border border-solid border-[#D4D4D4] bg-[#F3F3F3] rounded-[5px] text-sm text-black">
                        {feature}
                      </span>
                    )
                  )}
                </div>
              ) : (
                <div className="text-center py-2 text-gray-500">Excluded Columns not selected</div>
              )}
            </div>
          </div>

          {/* Target Variable Setting Accordion */}
          <div>
            <button
              className="w-full flex justify-between bg-[#F3F3F3] items-center border border-solid border-[#00000026] py-3 px-5 text-left text-black focus:outline-none hover:bg-gray-100"
              onClick={() => toggleAccordion(1)}
            >
              <span className="font-medium text-lg">Target Variable Setting</span>
              <svg
                className={`h-5 w-5 transform transition-transform ${
                  activeIndex === 1 ? "rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            <div
              className={`${
                activeIndex === 1 ? "block" : "hidden"
              } text-gray-600 p-5 border border-solid border-[#00000026] rounded-b-[10px] border-t-0`}
            >
              {!isEmptyObject(selectedBatchDetails?.settings?.datasource?.target_variable_settings) ? (
                <div className="flex flex-col gap-3">
                  {Object.entries(
                    selectedBatchDetails?.settings?.datasource?.target_variable_settings
                  ).map(([variable, settings]:any, index:any) => (
                    <div key={index} className="flex py-2.5 text-sm bg-[#F3F3F3] text-black">
                      <span className="px-4 flex items-center border-r border-solid border-[#00000066]">
                        {variable}
                      </span>
                      <span className="px-4 flex items-center border-r border-solid border-[#00000066]">
                        {settings.operator}
                      </span>
                      <span className="px-4 flex items-center">
                        {Array.isArray(settings.value) 
                          ? settings.value.join(' - ') 
                          : settings.value}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-2 text-gray-500">Target Variable not selected</div>
              )}
            </div>
          </div>

          {/* Filters on CSV Accordion */}
          <div>
            <button
              className="w-full flex justify-between bg-[#F3F3F3] items-center border border-solid border-[#00000026] py-3 px-5 text-left text-black focus:outline-none hover:bg-gray-100"
              onClick={() => toggleAccordion(2)}
            >
              <span className="font-medium text-lg">Filters on CSV</span>
              <svg
                className={`h-5 w-5 transform transition-transform ${
                  activeIndex === 2 ? "rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            <div
              className={`${
                activeIndex === 2 ? "block" : "hidden"
              } text-gray-600 p-5 border border-solid border-[#00000026] rounded-b-[10px] border-t-0`}
            >
              {!isEmptyObject(selectedBatchDetails?.settings?.datasource?.feature_filters) ? (
                <div className="flex flex-col gap-3">
                  {Object.entries(
                    selectedBatchDetails?.settings?.datasource?.feature_filters
                  ).map(([feature, settings]:any, index:any) => (
                    <div key={index} className="flex py-2.5 text-sm bg-[#F3F3F3] text-black">
                      <span className="px-4 flex items-center border-r border-solid border-[#00000066]">
                        {feature}
                      </span>
                      <span className="px-4 flex items-center border-r border-solid border-[#00000066]">
                        {settings.operator}
                      </span>
                      <span className="px-4 flex items-center">
                        {Array.isArray(settings.value) 
                          ? settings.value.join(' - ') 
                          : settings.value}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-2 text-gray-500">CSV Filters not selected</div>
              )}
            </div>
          </div>

          {/* Operations on CSV Accordion */}
          <div>
            <button
              className="w-full flex justify-between bg-[#F3F3F3] items-center border border-solid border-[#00000026] py-3 px-5 text-left text-black focus:outline-none hover:bg-gray-100"
              onClick={() => toggleAccordion(3)}
            >
              <span className="font-medium text-lg">Operations</span>
              <svg
                className={`h-5 w-5 transform transition-transform ${
                  activeIndex === 3 ? "rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            <div
              className={`${
                activeIndex === 3 ? "block" : "hidden"
              } text-gray-600 p-5 border border-solid border-[#00000026] rounded-b-[10px] border-t-0`}
            >
              {Object.entries(selectedBatchDetails?.settings || {}).some(([key]) => 
                !['datasource', 'workflowId'].includes(key)
              ) ? (
                <div className="flex flex-col gap-3">
                  {Object.entries(selectedBatchDetails?.settings || {}).map(([key, value]) => {
                    // Skip datasource and workflowId
                    if (['datasource', 'workflow_id'].includes(key)) return null;

                    return (
                      <div key={key} className="flex flex-col gap-2.5">
                        <h5 className="m-0 text-black font-medium text-base">
                          {capitalizeFirstLetter(key)}
                        </h5>
                        {Object.entries(value as any).map(([subKey, subValue], index) => (
                          <div key={`${key}-${subKey}-${index}`} className="flex py-2.5 text-sm bg-[#F3F3F3] text-black">
                            <span className="px-4 flex items-center border-r border-solid border-[#00000066]">
                              {capitalizeFirstLetter(subKey)}
                            </span>
                            <span className="px-4 flex items-center">
                              {typeof subValue === 'object' 
                                ? JSON.stringify(subValue)
                                : String(subValue)
                              }
                            </span>
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-center py-2 text-gray-500">Operations not selected</div>
              )}
            </div>
          </div>

           {/* Golden Value Table */}
          <div className="mt-6">
            <h2 className="mb-4 font-medium text-lg text-black">Golden Values:</h2>

            <>
            <div className="mb-4">
              Minimum Value: <strong>{selectedBatchDetails?.min}</strong>
              {'\u00A0\u00A0\u00A0'} {/* Adds spaces using non-breaking spaces */}
              Maximum Value: <strong>{selectedBatchDetails?.max}</strong>
              {
                selectedBatchDetails?.cluster_data && 
                <> 
                {'\u00A0\u00A0\u00A0'} {/* Adds spaces using non-breaking spaces */}
                Total Samples: <strong>{selectedBatchDetails?.cluster_data?.totalSamples ? selectedBatchDetails?.cluster_data?.totalSamples : 'NA'}</strong>
                {'\u00A0\u00A0\u00A0'} {/* Adds spaces using non-breaking spaces */}
                Good Samples: <strong>{selectedBatchDetails?.cluster_data?.goodSamples ? selectedBatchDetails?.cluster_data?.goodSamples : 'NA'}</strong>
                {'\u00A0\u00A0\u00A0'} {/* Adds spaces using non-breaking spaces */}
                Bad Samples: <strong>{selectedBatchDetails?.cluster_data?.badSamples ? selectedBatchDetails?.cluster_data?.badSamples : 'NA'}</strong>
                {'\u00A0\u00A0\u00A0'} {/* Adds spaces using non-breaking spaces */}
                Good Sample Percentage: <strong>{selectedBatchDetails?.cluster_data?.goodSamplePercentage ? selectedBatchDetails?.cluster_data?.goodSamplePercentage + '%' : 'NA'}</strong>
                </>
              }
            </div>
            </> 
            {selectedBatchDetails?.golden_value && selectedBatchDetails.golden_value.length > 0 ? (
              <Table
                columns={columns}
                dataSource={selectedBatchDetails.golden_value.map((item:any) => ({
                  ...item,
                  key: item.key
                }))}
                className="common-table"
                pagination={{ 
                  pageSize: 100,
                  showSizeChanger: false
                }}
              />
            ) : (
              <div className="text-center py-4 bg-white rounded-lg border border-solid border-[#00000026]">
                <p className="text-gray-500">No golden values available</p>
              </div>
            )}
          </div>
        </div>       
      </div>
    </React.Fragment>
  );
};

export default GoldenValueDetail;
