import React from 'react';
import Plot from 'react-plotly.js';

interface CorrelationData {
    [key: string]: {
        [key: string]: number;
    };
}

interface CorrelationHeatmapProps {
    data: CorrelationData;
    fontSize: number;
    width: string;
}

export const CorrelationHeatmap: React.FC<CorrelationHeatmapProps> = ({ 
    data, 
    fontSize = 11, 
    width = 1000 
}) => {
    // Process data for Plotly format
    const processData = () => {
        if (!data) return { x: [], y: [], z: [] };
        
        const features = Object.keys(data);
        
        // For Plotly, we need a 2D array for z values
        const zValues: number[][] = [];
        
        features.forEach(rowFeature => {
            const rowData: number[] = [];
            features.forEach(colFeature => {
                rowData.push(data[rowFeature][colFeature]);
            });
            zValues.push(rowData);
        });
        
        return {
            x: features,
            y: features,
            z: zValues
        };
    };

    const plotData = processData();
    
    // Define color scale similar to the original
    const colorscale = [
        [0.0, '#E34234'], // Strong Negative (-1.0 to -0.8)
        [0.1, '#FF6B6B'], // Moderate Negative (-0.8 to -0.6)
        [0.2, '#FFA07A'], // Weak Negative (-0.6 to -0.4)
        [0.4, '#FFFFFF'], // Weak/No Correlation (-0.4 to 0.4)
        [0.6, '#ADD8E6'], // Weak Positive (0.4 to 0.6)
        [0.8, '#4F97D3'], // Moderate Positive (0.6 to 0.8)
        [1.0, '#1434A4']  // Strong Positive (0.8 to 1.0)
    ];

    // Calculate an appropriate height based on the number of features
    const height = data ? Object.keys(data).length * 40 : 450;
    
    // Fix for hovertemplate: define data with proper type annotation
    const plotlyData: any[] = [{
        type: 'heatmap',
        x: plotData.x,
        y: plotData.y,
        z: plotData.z,
        colorscale: colorscale,
        zmin: -1,
        zmax: 1,
        hoverongaps: false,
        showscale: true,
        colorbar: {
            title: 'Correlation',
            titleside: 'right',
            titlefont: {
                size: fontSize
            },
            tickfont: {
                size: fontSize
            }
        }
    }];

    // Fix for width type error: convert width properly for Plotly layout
    const layoutWidth = width === '100%' ? null : Number(width);
    
    const layout = {
        title: 'Correlation Heatmap',
        width: layoutWidth, // Now correctly typed
        height: height,
        margin: {
            l: 80,
            r: 30,
            b: 80,
            t: 50,  // Increased top margin for legend
            pad: 4
        },
        xaxis: {
            tickangle: -45,
            tickfont: {
                size: fontSize
            }
        },
        yaxis: {
            tickfont: {
                size: fontSize
            }
        },
        hoverlabel: {
            bgcolor: "white",
            font: {
                size: fontSize
            },
            bordercolor: "#666"
        },
        hovermode: 'closest',
        annotations: [
            {
                x: 0.15,
                y: 1.1,
                xref: 'paper',
                yref: 'paper',
                text: 'Strong Negative',
                showarrow: false,
                font: {
                    color: '#E34234',
                    size: fontSize
                }
            },
            {
                x: 0.35,
                y: 1.1,
                xref: 'paper',
                yref: 'paper',
                text: 'Moderate Negative',
                showarrow: false,
                font: {
                    color: '#FF6B6B',
                    size: fontSize
                }
            },
            {
                x: 0.55,
                y: 1.1,
                xref: 'paper',
                yref: 'paper',
                text: 'Weak/No Correlation',
                showarrow: false,
                font: {
                    color: '#000000',
                    size: fontSize
                }
            },
            {
                x: 0.75,
                y: 1.1,
                xref: 'paper',
                yref: 'paper',
                text: 'Moderate Positive',
                showarrow: false,
                font: {
                    color: '#4F97D3',
                    size: fontSize
                }
            },
            {
                x: 0.95,
                y: 1.1,
                xref: 'paper',
                yref: 'paper',
                text: 'Strong Positive',
                showarrow: false,
                font: {
                    color: '#1434A4',
                    size: fontSize
                }
            }
        ]
    };

    const config = {
        responsive: true,
        displayModeBar: false
    };

    // Custom hover template - now works because plotlyData is typed as any[]
    if (plotlyData[0]) {
        plotlyData[0].hovertemplate = 
            '<b>%{y} → %{x}</b><br>' +
            'Correlation: %{z:.3f}<extra></extra>';
    }

    return (
        <div className="correlation-heatmap">
            <Plot
                data={plotlyData}
                layout={layout as any}
                config={config}
                style={{ width: '100%', minHeight: '450px' }}
            />
        </div>
    );
};