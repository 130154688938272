import { Button, Drawer, Flex, Space, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { getOperationsRequest, getRequest } from '../../../utils/apiHandler';  // Assuming an API function to fetch uploaded files
import Notiflix from 'notiflix';
import FileMapping from '../../tables/FileMapping';
import file from '../../../img/file.svg';
import play from '../../../img/play.svg';
import exclametryMark from '../../../img/exclametrymark.svg'
import prediction from '../../../img/prediction.svg';
import feature from '../../../img/feature.svg';
import rca from '../../../img/rca.svg';
import share1 from '../../../img/share1.svg';
import copy from '../../../img/copy.svg';
import arrowleft from "../../../img/arrowleftblue.svg";
import { useLocation, useNavigate } from 'react-router-dom';  // Import useLocation from React Router
import { useAuth } from '../../../context/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { setData, setLoading, setError } from '../../../Redux/slices/dataSlice';
const { Title, Paragraph } = Typography;

function InsightTab({ nodes ,updateFilelist}: any) {
  const location = useLocation()
  const [operations, setOperations] = useState<any[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [workflowData, setWorkflowData] = useState<any>(null);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(true);
  const params = new URLSearchParams(location.search);
  const workflowId = params.get('workflowId');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const auth = useAuth()
  const [showResultsButton, setShowResultsButton] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  const [visibility, setVisibility] = useState({
    file: true,
    operation: true,
    result: true,
  });

  const userType = auth.authState.user?.first_name?.toLowerCase() || '';
  const { data } = useSelector((state: any) => state.data);
  const selectSystems = useSelector((state: any) => state.systems.systems);
  const [draggedFile, setDraggedFile] = useState(null)
  // Fetch operations on component mount
  useEffect(() => {

    getWorkflowData();

  }, [location, dispatch]);

  useEffect(() => {
    if (workflowData) {
      fetchOperations();
      fetchUploadedFiles();
      // if (workflowData?.workflowComponents?.length > 0) {
      //   setShowResultsButton(false);
      // } else {
      //   setShowResultsButton(true);
      // }
    }
  }, [workflowData]);

  useEffect(() => {
    const visibilityMap = ['file', 'operation', 'result'].reduce((acc: any, type: string) => {
      acc[type] = !nodes.some((node: any) => node?.data?.type === type);
      return acc;
    }, {});
    setVisibility(visibilityMap);
  }, [nodes]);

  const fetchOperations = async () => {
    try {
      const response = await getOperationsRequest();
      if (response.data && response.data.data) {
        const mappedOperations = response.data.data.map((op: any) => ({
          id: op.id,
          alias: op.alias,
          name: op.name,
          type: op.type || 'operation',
          icon: getOperationIcon(op.name),
          description: op.description,
          category: op.category,
          config: op.config || {}
        }));
        console.log('workflowData', workflowData)
        // if (workflowId == "0") {
        console.log('data?.workflowComponents?.length > 0', data?.workflowComponents?.length > 0)
        console.log('datassss', data)
        if (workflowData?.workflowComponents?.length > 0) {
          setOperations([])
        } else {
          setOperations(mappedOperations);
        }
      }
    } catch (error) {
      console.error('Error fetching operations:', error);
      Notiflix.Notify.failure('Failed to fetch operations');
    }
  };

  const getWorkflowData = async () => {
    try {
      const params = new URLSearchParams(location.search);
      const workflowId = params.get('workflowId');

      if (workflowId && parseInt(workflowId) > 0) {
        const response = await getRequest(`/workflow/${workflowId}`);
        if (response?.data?.data) {
          let workFlowData = response?.data?.data
          if(response.data?.sharedWorkflowDetails){
            //This is added to check the access level of shared workflow
            workFlowData.sharedWorkflowDetails = response.data?.sharedWorkflowDetails 
          }
          dispatch(setData(workFlowData));
          setWorkflowData(response?.data?.data)
        }
        else
          setWorkflowData(null)
      }
      // await fetchOperations();
      // await fetchUploadedFiles();
    } catch (error) {
      dispatch(setError(error));
      console.log('error', error)
    }
  }

  const fetchUploadedFiles = async () => {
    try {
      setLoadingFiles(true)
      const systemIdsString = selectSystems.length ?  selectSystems[0].systems
      ?.map((system: { systemId: number }) => {
        return system.systemId;
      })
      .join(",") : ''
      const apiUrl = systemIdsString ? `/file?systems_id=${systemIdsString}` : '/file';
      const response = await getRequest(apiUrl);
      if (response.data && response.data.data.files) {
        // if (workflowId == "0") {
        console.log('workflowData', workflowData)
        if (workflowData?.workflowComponents?.length > 0) {
          console.log("lgogogoogog");

          setUploadedFiles([])
        } else {
          setUploadedFiles(response.data.data.files);
        }
        setLoadingFiles(false)
      }
    } catch (error: any) {
      console.error('Error fetching uploaded files:', error);
      if (error.status === 403) {
        auth.logout()
        Notiflix.Notify.failure(error.response.data.message);
        return
      }
      Notiflix.Notify.failure('Failed to fetch uploaded files');
    }
  };

  // Fetch uploaded files from the API
  // useEffect(() => {


  //   fetchUploadedFiles();
  // }, [location]);

  //UseEffect for results button
  // useEffect(() => {
  //   if (data?.workflowComponents?.length > 0) {
  //     setShowResultsButton(false);
  //   } else {
  //     setShowResultsButton(true);
  //   }
  // }, [location]);

  const getOperationIcon = (name: string) => {
    const iconMap: { [key: string]: any } = {
      'Golden Batch': copy,
      'Co-relations': share1,
      'RCA': rca,
      'Feature Importance': feature,
      'Prediction Model': prediction,
      'Identification Model' : prediction
    };
    return iconMap[name] || file;
  };

  const handleDragStart = (e: any, data: any) => {
    if (data?.compatibility && data?.compatibility == false) {
      return
    }
    e.dataTransfer.setData("application/reactflow", JSON.stringify(data));
    setIsDragging(true);

    // if (data.type === 'operation') {
    //   setTimeout(() => {
    //     setOperations([])
    //   }, 100)
    // } else if (data.type === 'file') {
    //   setTimeout(() => {
    //     setUploadedFiles([])
    //   }, 100)
    // } else if (data.type === 'result') {
    //   setTimeout(() => {
    //     setShowResultsButton(false)
    //     //change vivibilty here 
    //   }, 100)
    // }
    setTimeout(() => {
      setVisibility((prevVisibility) => ({
        ...prevVisibility,
        [data.type]: false, // Hide the dragged type
      }));
    }, 100)
  };

  const handleResults = () => {
    navigate(`/?tab=insight&workflowId=${workflowId}&subTab=result`)
  };

  const handleBackClick = () => {
    navigate(`/?tab=insight&workflowId=0`)
  };

  const showDrawer = (file: any) => {
    setOpen(true);
    setDraggedFile(file)
  };
  const onClose = () => {
    setOpen(false);
  };
  const fetchUploadedFilesdata = () =>{
    fetchUploadedFiles()
    updateFilelist(draggedFile)
  }


  return (
    <React.Fragment>
      <div className="flex font-medium items-center line-clamp-1 gap-2 !text-sm !text-[#252963] mb-2 cursor-pointer"
        onClick={handleBackClick}
      >
        <Button
          type="text"
          className="p-0 !bg-transparent"
        >
          <img src={arrowleft} />
        </Button>
        <span>Back</span>
      </div>
      <Title level={4} className='heading-bold'>Available Data Source</Title>
      <div className="available-data data-source">
        <ul>

          {loadingFiles ? (
            <>
              <li className='flex items-center'>
                <Space>
                  <span>
                    <img src={file} alt="file" />
                  </span>
                  <Paragraph className='!leading-none'>Loading ...</Paragraph>
                </Space>
              </li>
            </>

          ) : (
            <>
              {visibility.file && (
                <>
                  {uploadedFiles && uploadedFiles.length > 0 ? (
                    uploadedFiles.map((fileData, index) => (
                      <div>
                        <li
                          key={index}
                          style={{ cursor: 'pointer' }}
                          draggable
                          onDragStart={(e) => {
                            if (fileData.compatibility === false) {
                              e.preventDefault();
                              showDrawer(fileData)  // Prevent dragging if compatibility is false
                            } else {
                              handleDragStart(e, {
                                name: fileData.file_name,
                                id: fileData.csv_id,
                                type: 'file',
                                data: fileData,
                              });
                            }
                          }}
                        >
                          <Space>
                            {
                              fileData.compatibility != false &&
                              <span >
                                <img src={file} alt="file" onDragStart={(e) => e.preventDefault()} />
                              </span>
                            }
                            {
                              fileData.compatibility === false &&
                              <span >
                                <img src={exclametryMark} alt="file" onDragStart={(e) => e.preventDefault()} />
                              </span>
                            }
                            <Paragraph
                              style={{
                              }}>{fileData.file_name}</Paragraph>
                          </Space>
                        </li>
                      </div>
                    ))
                  ) : (
                    <li>
                      <Space>
                        <span>
                          <img src={file} alt="file" />
                        </span>
                        <Paragraph>No files uploaded</Paragraph>
                      </Space>
                    </li>
                  )}
                </>
              )}
            </>
          )}
          <Drawer width={'40%'} title={
            <div className="flex flex-col gap-2">
              Match Missing Fields
              <span style={{ fontSize: '12px', color: '#888' }}> Map your CSV column with required fields</span>
              <div className="h-[5px] mt-2 bg-slate-600 bg-opacity-10 w-full rounded-md"></div>
            </div>
          } onClose={onClose} open={open} className="[&_.ant-drawer-body]:px-[30px] [&_.ant-drawer-header-title]:flex-row-reverse">
            {open && <FileMapping configurations={selectSystems} file={draggedFile} fetchUploadedFiles={fetchUploadedFilesdata} setOpen={setOpen} />}
          </Drawer>
        </ul>
      </div>
      <div className="seprater"></div>

      <div className='insight-common-ul'>
        <Title level={4} className='heading-bold'>Operations </Title>
        <ul>
          {
            visibility.operation && operations.map((operation) => (
              <li
                key={operation.id}
                draggable
                onDragStart={(e) =>
                  handleDragStart(e, {
                    name: operation.name,
                    id: operation.alias,
                    type: operation.type,
                    config: operation.config,
                    description: operation.description,
                  })
                }
              >
                <Flex justify="space-between" align="center" gap={10}>
                  <Space>
                    <span>
                      <img src={operation.icon} alt={operation.name.toLowerCase()} />
                    </span>
                    <Paragraph>{operation.name}</Paragraph>
                  </Space>
                </Flex>
              </li>
            ))
          }
        </ul>
      </div>
      <div className="seprater"></div>

      {showResultsButton && visibility.result && (
        <button
          draggable
          onDragStart={(e) => handleDragStart(e, {
            name: 'Workflow Results',
            id: 'workflow-results',
            type: 'result',
            icon: share1,
            config: {},
            description: 'View workflow results',
            handleClick: handleResults
          })}
          // onClick={handleResults}
          className={`w-full text-left font-semibold text-primary flex gap-2 items-center `}
        >
          <img src={play} alt="play" className='relative -top-px' />
          Workflow Results
        </button>
      )}
    </React.Fragment>
  );
}

export default InsightTab;
