import React, { useState, useEffect } from 'react';
import { Modal, Input, Select } from 'antd';

interface FilterModalProps {
  visible: boolean;
  filters: any;
  onConfirm: (selectedFilter: string, filterName: string) => void;
  onCancel: () => void;
  currentFilter?: string;
  currentFilterName?: string;
}

const FilterModal: React.FC<FilterModalProps> = ({
  visible,
  filters,
  onConfirm,
  onCancel,
  currentFilter = '',
  currentFilterName = ''
}) => {
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(undefined);
  const [filterName, setFilterName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setSelectedFilter(currentFilter);
    setFilterName(currentFilterName);
    setError('');
  }, [currentFilter, currentFilterName, visible]);

  const handleConfirm = () => {
    if (!selectedFilter) {
      setError('Please select a filter');
      return;
    }
    if (!filterName) {
      setError('Filter name cannot be empty');
      return;
    }
    onConfirm(selectedFilter, filterName.trim());
    setError('');
  };

  return (
    <Modal
      title="Select Filter and Enter Name"
      visible={visible}
      onOk={handleConfirm}
      onCancel={onCancel}
      okText="Apply"
      cancelText="Cancel"
    >
      <div className="flex flex-col gap-2">
        <Select
          value={selectedFilter}
          onChange={(value) => {
            setSelectedFilter(value);
            setError('');
          }}
          placeholder="Select a filter"
          className="w-full"
        >
            <Select.Option key={Date.now()} value={undefined || ''}>
                Select Filter
            </Select.Option>
          {filters.map((filter:any) => (
            <Select.Option key={filter.id} value={filter.id}>
              {filter.name}
            </Select.Option>
          ))}
        </Select>
        <Input
          value={filterName}
          onChange={(e) => {
            setFilterName(e.target.value);
            setError('');
          }}
          placeholder="Enter filter name"
          className="w-full px-4 py-2"
        />
        {error && (
          <span className="text-red-500 text-sm">{error}</span>
        )}
      </div>
    </Modal>
  );
};

export default FilterModal;