import React, { useEffect, useState } from 'react';
import { Select, Button, Space, Row, Col, Form, Skeleton } from 'antd';
import { getRequest, postRequest, putRequest } from '../../utils/apiHandler';
import { Notify } from 'notiflix';
import "../../styles/flow.css";

const { Option } = Select;

interface Key {
  key: string;
  title: string;
}

interface FileMappingProps {
  configurations: any;
  file: any;
  fetchUploadedFiles: any;
  setOpen: any;
}

interface Rule {
  id: string;
  field: string;
  value: string;
  operator: string;
  valueSource: string;
}

interface Configurations {
  id: string;
  rules: Rule[];
  combinator: string;
}

interface Config {
  ByProduct?: Record<string, any>;
  Processes?: Record<string, any>;
  InputMaterial?: Record<string, any>;
  OutputMaterial?: Record<string, any>;
  configurations?: Configurations;
  TimeGeneralIdentifiers?: string[];
}

interface ConfigItem {
  name: string;
  config: Config;
}

interface DataItem {
  systems: string[];
  config: ConfigItem[];
}

interface FieldOutput {
  key: string;
  title: string;
}

const FileMapping: React.FC<FileMappingProps> = ({
  configurations,
  file,
  fetchUploadedFiles,
  setOpen,
}) => {

const extractFieldsFromConfigurations = async (data: DataItem[], fileTypes: string[]) => {
  const globalConfig = await getRequest('/configurations/get-configuration');
  const globalConfigData = globalConfig.data.data;
  const fields = new Set<string>();
  
  const config = data[0]?.systems?.map((system: any) => ({
    name: system?.systemName,
    config: globalConfigData[system?.systemName] || {}
  })) || [{ name: '', config: {} }];

  config.forEach((system) => {
    // Extract Process parameters if 'processes' is in the fileTypes array
    if (fileTypes.includes('processes') && system.config?.Processes) {
      Object.values(system.config.Processes).forEach((process: any) => {
        Object.keys(process.parameters || {}).forEach((param) => {
          fields.add(param);
        });
      });
    }

    // Extract Material parameters if 'material' is in the fileTypes array
    if (fileTypes.includes('material') && system.config?.InputMaterial) {
      Object.entries(system.config.InputMaterial).forEach(([material, details]: [string, any]) => {
        if (details.quality && Array.isArray(details.quality)) {
          details.quality.forEach((param: string) => {
            fields.add(param);
          });
        }
      });
    }
  });

  // Convert the set to an array of FieldOutput objects
  return Array.from(fields).map(field => ({
    key: field,
    title: field,
  }));
};

  const [dropdownValues, setDropdownValues] = useState<{ [key: string]: string }>({});
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [dataFetched ,setDataFteched] = useState(false);
  const [keys, setKeys] = useState<Key[]>([]);
  const [form] = Form.useForm();  // Get the form instance

  useEffect(() => {
    getFileColumns(file.csv_id);
  }, [configurations, file]);

  const getFileColumns = async (csvId: any) => {
    const response = await getRequest(`/file/columns/${csvId}`);
    setDropdownOptions(response.data.data.columns);
    
    // Get the file type from the response - handle as array
    const fileTypes = Array.isArray(response.data.data.file.type_of_file) 
      ? response.data.data.file.type_of_file 
      : JSON.parse(response.data.data.file.type_of_file || '["processes"]');
          
    const config = await extractFieldsFromConfigurations(configurations, fileTypes);
    
    const missingCol = await validateColumns(response.data.data.columns, config);
    setKeys(missingCol);

    if(response?.data?.data?.columns) {
      const payload = {
        columns: response?.data?.data?.columns
      };
      const result = await postRequest(`/file/csv-mapping-history`, payload);

      if(result) {
        if(result.data.data[0]) {
          let mappingResult = previousCSVmapping(missingCol, result.data.data[0]);
          form.setFieldsValue(mappingResult);
          setDropdownValues(mappingResult);
        }
        setDataFteched(true);
      }
    }
  };


  const validateColumns = (
    fileColumns: string[],
    requiredColumns: any,
  ) => {
    const missingColumns = requiredColumns.filter(
      (col: any) =>
        !fileColumns.some(
          (fileCol) => fileCol.toLowerCase() === col?.key?.toLowerCase()
        )
    );

    if (missingColumns.length > 0) {
      return missingColumns;
    }
    return missingColumns;
  };

  const previousCSVmapping = (firstArray: Key[], secondArray: any): { [key: string]: string } => {
    const result: { [key: string]: string } = {};
  
    firstArray.forEach(item => {
      for (const [key, value] of Object.entries(secondArray)) {
        if (value === item.key) {
          result[item.key] = key;
        }
      }
    });
  
    return result;
  };

  const handleDropdownChange = (value: string, key: string) => {
    if (!key) return; // Ignore undefined or invalid keys
    setDropdownValues((prev) => ({
      ...prev,
      [key]: value, // Update key value based on selected dropdown option
    }));

    console.log('dropdownValues dropdwnValues dropdownValues :', dropdownValues);
  };


  const handleSave = async () => {
    try {
      const values = await form.validateFields(); // Trigger validation manually
      const flippedObj = Object.fromEntries(Object.entries(dropdownValues).map(([key, value]) => [value, key]));

      const payload = {
        file_path: file.file_path,
        headerMapping: flippedObj,
      };

      const response = await putRequest(`/file/map-csv/${file.csv_id}`, payload);

      if (response.status === 200) {
        setOpen(false);
        Notify.success('CSV file is mapped successfully', { timeout: 2000 , distance: '50px',clickToClose:true});
        fetchUploadedFiles();
      }
    } catch (error) {
      console.log('Validation failed:', error);
      Notify.failure('Failed to map CSV file', { timeout: 2000 , distance: '50px',clickToClose:true});
    }
  };

  return (
    <div>
      {dataFetched && 
            <Form form={form} layout="vertical" onFinish={handleSave} onFinishFailed={(errorInfo) => console.log('Failed:Please select file mapping', errorInfo)}>
            <Row gutter={16} >
              {keys.map((item) => (
                
                  <Col span={12} key={item.key}>
                    <Form.Item
                      name={item.title}
                      label={item.title}
                      rules={[
                        {
                          required: true,
                          message: `Please select a column for ${item.title}`,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select File Column"
                        value={dropdownValues[item.key] || undefined}
                        onChange={(value) => handleDropdownChange(value, item.key)}
                      >
                        {dropdownOptions.map((option, index) => (
                          <Option key={index} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
              
              ))}
                </Row>
              <Space style={{ marginTop: 16, display:"flex", justifyContent:"flex-end" }}>
                <Button type="primary" htmlType="submit" className='py-4 px-5'>
                  Save
                </Button>
              </Space>
            </Form>
      }
      {
        !dataFetched && 
        <Form layout="vertical">
          <Row gutter={16}>
            {[1,2,3,4,5,6].map((item) => (
              <Col span={12} key={item} style={{ marginBottom: '15px' }}>
                <Form.Item
                  name={item}
                  label={<Skeleton.Input active size="small" style={{ height: 20}} />}
                >
                  <Skeleton.Input active size="large" style={{ height: 35}} />
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Space style={{ marginTop: 16, display: "flex", justifyContent: "flex-end" }}>
            <Skeleton.Button active size="default" style={{ height: 40 }} />
          </Space>
        </Form>
      }
    </div>
  );
};

export default FileMapping;
