import React from 'react';
import Plot from 'react-plotly.js';

// Dummy data structure that might come from ML processing
interface DataPoint {
    PCA2: any;
    PCA1: any;
    Good: any;
    // x: number;
    // y: number;
    Cluster: 'Cluster 1' | 'Cluster 3' | 'Cluster 4' | 'Cluster 5' | 'Cluster 6' | 'Cluster 7' | 'Cluster 0' | 'Cluster 2' | 'golden' | 'normal' | 'outlier';  
}

interface GoldenBatchData {
    dataPoints: DataPoint[];
    goldenCentroid?: { x: number; y: number };
    featureX: string;
    featureY: string;
}

interface GoldenBatchScatterPlotProps {
    data: GoldenBatchData;
}

// Create dummy data
const dummyGoldenBatchData: GoldenBatchData = {
    dataPoints: [
        // Golden cluster points
        {
            "Good": 0,
            "PCA1": 23274158.554443337,
            "PCA2": -34749.05194010615,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.703988243,
            "PCA2": -34614.704063463214,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.704377126,
            "PCA2": -34615.00057418257,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.70439903,
            "PCA2": -34615.069004033045,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.70418003,
            "PCA2": -34614.87347025067,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.704000615,
            "PCA2": -34614.71985019205,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.704008676,
            "PCA2": -34614.73553885993,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.70382707,
            "PCA2": -34614.56694068446,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.704322364,
            "PCA2": -34614.785867533436,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.706169903,
            "PCA2": -34616.48686456051,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.706333723,
            "PCA2": -34616.66560792704,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.706236638,
            "PCA2": -34616.72750369111,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.70635224,
            "PCA2": -34616.81127539017,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.7065368,
            "PCA2": -34616.8846115431,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.706489965,
            "PCA2": -34616.87583324334,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.706642043,
            "PCA2": -34616.92022548973,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.706586163,
            "PCA2": -34616.91338530505,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.70667667,
            "PCA2": -34616.96132970259,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.706920207,
            "PCA2": -34617.10352181161,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.70664735,
            "PCA2": -34617.14895149433,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.707034323,
            "PCA2": -34617.34518966717,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.70709157,
            "PCA2": -34617.41360493672,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.70716664,
            "PCA2": -34617.475393229324,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936847.70737773,
            "PCA2": -34617.566489494726,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.179906763,
            "PCA2": -27071.678762324736,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.179929946,
            "PCA2": -27071.712429094372,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.180055168,
            "PCA2": -27071.785856375835,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18007099,
            "PCA2": -27071.818237102005,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.180102527,
            "PCA2": -27071.823105678523,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.17998179,
            "PCA2": -27071.77476627369,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.17994403,
            "PCA2": -27071.750623308515,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18018587,
            "PCA2": -27071.83250769123,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18022244,
            "PCA2": -27071.88952488282,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.180103287,
            "PCA2": -27071.923373916972,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.180262454,
            "PCA2": -27071.964679103883,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.180203192,
            "PCA2": -27071.93769868586,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.180352688,
            "PCA2": -27072.003048615425,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18023133,
            "PCA2": -27071.999239436896,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18053178,
            "PCA2": -27072.10643161428,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18021176,
            "PCA2": -27072.056187661063,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.180341426,
            "PCA2": -27072.12638498259,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18053537,
            "PCA2": -27072.195074240408,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.180519283,
            "PCA2": -27072.188318442146,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18044734,
            "PCA2": -27072.141279547606,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18044197,
            "PCA2": -27072.122755592667,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18060529,
            "PCA2": -27072.167984277774,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.180340305,
            "PCA2": -27072.153778291296,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18067723,
            "PCA2": -27072.294037898802,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18076335,
            "PCA2": -27072.356732343214,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18063898,
            "PCA2": -27072.408264503298,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.180860322,
            "PCA2": -27072.50910497967,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.180896837,
            "PCA2": -27072.53159691417,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18086509,
            "PCA2": -27072.511772320908,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.181061324,
            "PCA2": -27072.554214974487,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.180647075,
            "PCA2": -27072.479263098463,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.1809948,
            "PCA2": -27072.608181349107,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18100909,
            "PCA2": -27072.61940771627,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.181067757,
            "PCA2": -27072.687108809558,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18136217,
            "PCA2": -27072.891912301355,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.18140505,
            "PCA2": -27072.95752886566,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.181496035,
            "PCA2": -27073.05228877941,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.181852452,
            "PCA2": -27073.230684744434,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.1818848,
            "PCA2": -27073.37139727065,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.181970328,
            "PCA2": -27073.514159648825,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.182270043,
            "PCA2": -27073.73186984425,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.182386477,
            "PCA2": -27073.862298342876,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.182563357,
            "PCA2": -27073.991929134325,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.1829261,
            "PCA2": -27074.18641776789,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.182873618,
            "PCA2": -27074.258047541152,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.183187187,
            "PCA2": -27074.459723124713,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.183241166,
            "PCA2": -27074.569000519776,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.183509186,
            "PCA2": -27074.77088437322,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936846.183358047,
            "PCA2": -27074.805263973853,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936845.392175503,
            "PCA2": -23154.12694205575,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936845.39238534,
            "PCA2": -23154.246262624234,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936845.392131727,
            "PCA2": -23154.254342946122,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936845.392157555,
            "PCA2": -23154.304923402084,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936845.392409883,
            "PCA2": -23154.37818988921,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936845.392266396,
            "PCA2": -23154.31981440785,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936845.392331492,
            "PCA2": -23154.332415809422,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 21709583.51525951,
            "PCA2": -23589.442616862983,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 5955312.591670143,
            "PCA2": -26778.050890000417,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": -500764.5395549537,
            "PCA2": -28084.923061693677,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500764.5393187944,
            "PCA2": -28085.0184025275,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500764.539544272,
            "PCA2": -28084.99608537681,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500764.5394981011,
            "PCA2": -28084.989862296985,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500764.53950872796,
            "PCA2": -28084.972781654044,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500764.53935430275,
            "PCA2": -28085.037570214055,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500764.53932672425,
            "PCA2": -28085.089608613816,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500764.5394013095,
            "PCA2": -28085.103636762276,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": 23936844.50325793,
            "PCA2": -18772.151239382198,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.503562424,
            "PCA2": -18772.38788127479,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.503471214,
            "PCA2": -18772.530059726294,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.504128654,
            "PCA2": -18772.863104033295,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.503800247,
            "PCA2": -18772.901494920097,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.50443895,
            "PCA2": -18773.135260787498,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.50541542,
            "PCA2": -18773.524321624496,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.281295832,
            "PCA2": -17667.53413938688,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.281425763,
            "PCA2": -17667.66329900746,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.256941732,
            "PCA2": -17545.00886572234,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.256977815,
            "PCA2": -17545.051923273477,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.01383645,
            "PCA2": -16344.579007411374,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.01366894,
            "PCA2": -16344.471781465152,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.01356499,
            "PCA2": -16344.316309659342,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936844.01256943,
            "PCA2": -16343.603187863308,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": -500766.25691184175,
            "PCA2": -19600.717840129182,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.2567087793,
            "PCA2": -19600.804070283364,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.2567077597,
            "PCA2": -19600.917536563837,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.2563750196,
            "PCA2": -19601.117224013236,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.25584837236,
            "PCA2": -19601.371301448507,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.254324087,
            "PCA2": -19601.904832246342,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.25421570265,
            "PCA2": -19602.011798207033,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.2539534308,
            "PCA2": -19602.174476058564,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.2537247383,
            "PCA2": -19602.426850062493,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.25352400367,
            "PCA2": -19602.587217907516,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.2533939122,
            "PCA2": -19602.722208068808,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.2532296518,
            "PCA2": -19602.827082126656,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.25317220774,
            "PCA2": -19602.93166538244,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.25290884,
            "PCA2": -19603.10224377853,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.25278708304,
            "PCA2": -19603.256555754895,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.2527726358,
            "PCA2": -19603.308167903124,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.25265360234,
            "PCA2": -19603.32841024174,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.2527943587,
            "PCA2": -19603.264647614953,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.2526657817,
            "PCA2": -19603.282754231976,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.25278825907,
            "PCA2": -19603.249412092475,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.25280033244,
            "PCA2": -19603.190892471273,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.2529491519,
            "PCA2": -19603.121031883642,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.2531407482,
            "PCA2": -19603.049410344047,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9616323809,
            "PCA2": -16102.132677000009,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9614313197,
            "PCA2": -16102.158034054532,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9614505051,
            "PCA2": -16102.181799849655,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9615979419,
            "PCA2": -16102.166277257578,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.961543689,
            "PCA2": -16102.141332318975,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.96187191544,
            "PCA2": -16101.937282904251,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9619794938,
            "PCA2": -16101.809795256266,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9620722025,
            "PCA2": -16101.730748502307,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.962434379,
            "PCA2": -16101.567576258729,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.96243708365,
            "PCA2": -16101.460674513628,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9627190412,
            "PCA2": -16101.194520048846,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9631398704,
            "PCA2": -16100.864526507725,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9630415442,
            "PCA2": -16100.71993908215,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.963683911,
            "PCA2": -16100.443173246862,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.963822717,
            "PCA2": -16100.265021464695,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9638759673,
            "PCA2": -16100.092208595885,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9639232728,
            "PCA2": -16100.010420580766,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9643696702,
            "PCA2": -16099.828452110476,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9643570365,
            "PCA2": -16099.750539628738,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9645256134,
            "PCA2": -16099.590217555437,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9647518719,
            "PCA2": -16099.422844338156,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9649069106,
            "PCA2": -16099.248674310456,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.96475011366,
            "PCA2": -16099.22906831656,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.96520780015,
            "PCA2": -16099.089520940048,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.96485979686,
            "PCA2": -16099.182848426122,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9646444317,
            "PCA2": -16099.283099411345,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9648833017,
            "PCA2": -16099.211927131593,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.96515688946,
            "PCA2": -16099.075009412278,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.96518954734,
            "PCA2": -16099.01377803955,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9647008425,
            "PCA2": -16099.109355098954,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9653966212,
            "PCA2": -16098.842188362647,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.964544376,
            "PCA2": -16099.050506261048,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.96518207743,
            "PCA2": -16098.826655058409,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9647671172,
            "PCA2": -16098.957867878085,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9655051611,
            "PCA2": -16098.80374812989,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9656901034,
            "PCA2": -16098.717786519654,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.9658901018,
            "PCA2": -16098.600432626366,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.96612096514,
            "PCA2": -16098.473979077968,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500766.96638114576,
            "PCA2": -16098.356962060858,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500768.0540823274,
            "PCA2": -10725.376042958362,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500768.0543590398,
            "PCA2": -10725.237103529726,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500768.05455741344,
            "PCA2": -10725.10499976381,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500768.0547087781,
            "PCA2": -10724.97114909876,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 1,
            "PCA1": -500768.05434187397,
            "PCA2": -10724.996675372091,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500768.0549663343,
            "PCA2": -10724.704325974537,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500768.0553598604,
            "PCA2": -10724.512134420731,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500768.265975302,
            "PCA2": -9685.37231948348,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500768.26599601726,
            "PCA2": -9685.285529903365,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500768.26640057255,
            "PCA2": -9685.07575700714,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500768.2666273319,
            "PCA2": -9684.891034092223,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500768.2672222265,
            "PCA2": -9684.630446060288,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500768.3899315713,
            "PCA2": -9074.826678390462,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500768.39039606124,
            "PCA2": -9074.574768831171,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -5022201.463584962,
            "PCA2": -9699.609836521491,
            "Cluster": "Cluster 7"
        },
        {
            "Good": 0,
            "PCA1": -20342908.460247017,
            "PCA2": -12801.0091155278,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.63339208,
            "PCA2": -13525.033102941505,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.63252023,
            "PCA2": -13525.199279574517,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.633526213,
            "PCA2": -13524.790434418761,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.63471968,
            "PCA2": -13524.323641657536,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.635298647,
            "PCA2": -13524.06859187548,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.635805372,
            "PCA2": -13523.86147547065,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.63613892,
            "PCA2": -13523.704013401904,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.636068773,
            "PCA2": -13523.683685691523,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.635669496,
            "PCA2": -13523.792102610845,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.63547884,
            "PCA2": -13523.85207523293,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.635422196,
            "PCA2": -13523.864025623483,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.635500193,
            "PCA2": -13523.873315830948,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.635511644,
            "PCA2": -13523.9165935164,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.63559812,
            "PCA2": -13523.938981945515,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.63464094,
            "PCA2": -13524.282621263254,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.635351356,
            "PCA2": -13524.11594118484,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.63556957,
            "PCA2": -13524.101009514683,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.63551988,
            "PCA2": -13524.139282352668,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.635572657,
            "PCA2": -13524.118296902205,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.63560659,
            "PCA2": -13524.086465480787,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.635680426,
            "PCA2": -13524.035410912487,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.635807004,
            "PCA2": -13523.979546310313,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.635759722,
            "PCA2": -13523.995673935824,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.63540118,
            "PCA2": -13524.127819602738,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.643279724,
            "PCA2": -13520.551785477539,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.66313552,
            "PCA2": -13514.294881965989,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.66733874,
            "PCA2": -13512.893349360636,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -23920144.664225932,
            "PCA2": -13514.280773202305,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -31811072.446457658,
            "PCA2": -10643.649504482451,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247995.982057184,
            "PCA2": -11348.43082826255,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247995.97977879,
            "PCA2": -11348.86308774087,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247995.98272041,
            "PCA2": -11347.843653438695,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247995.990593724,
            "PCA2": -11345.746960610853,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247995.984952666,
            "PCA2": -11347.942035507647,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247995.97547374,
            "PCA2": -11350.796473677476,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247995.97576282,
            "PCA2": -11350.5011218812,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247995.97607704,
            "PCA2": -11350.202219449804,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247995.97636437,
            "PCA2": -11349.916159366267,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247995.976718865,
            "PCA2": -11349.669029200608,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247995.97683079,
            "PCA2": -11349.559376419173,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247995.97650221,
            "PCA2": -11349.64647992212,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.46196151,
            "PCA2": -8952.250239048932,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.46257074,
            "PCA2": -8952.078282636938,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.46280819,
            "PCA2": -8952.045412541283,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.463950925,
            "PCA2": -8951.778078954916,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.46362936,
            "PCA2": -8951.992554222938,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.46335498,
            "PCA2": -8952.19009722967,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.463089176,
            "PCA2": -8952.352594002288,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66906481,
            "PCA2": -7934.449668122436,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.6687562,
            "PCA2": -7934.6417233462835,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66863269,
            "PCA2": -7934.7971904350525,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66854752,
            "PCA2": -7934.965581061087,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.668635115,
            "PCA2": -7935.060298101957,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66870181,
            "PCA2": -7935.189908019089,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66853799,
            "PCA2": -7935.404339757192,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66859793,
            "PCA2": -7935.532469713748,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66832653,
            "PCA2": -7935.793197805065,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.668033876,
            "PCA2": -7936.045160850644,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66790546,
            "PCA2": -7936.255397057362,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.666312255,
            "PCA2": -7936.879782081732,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.666244105,
            "PCA2": -7937.00686734905,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.666081026,
            "PCA2": -7937.179904590542,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66611805,
            "PCA2": -7937.27318463465,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.666148424,
            "PCA2": -7937.360689780366,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66604604,
            "PCA2": -7937.4768126911895,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.666143395,
            "PCA2": -7937.540292969052,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.666154005,
            "PCA2": -7937.607909051905,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66629455,
            "PCA2": -7937.6402706458275,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66525948,
            "PCA2": -7938.022773883858,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66500459,
            "PCA2": -7938.077994915879,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.664874144,
            "PCA2": -7938.087824642563,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66471064,
            "PCA2": -7938.092753607178,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66452315,
            "PCA2": -7938.10225195823,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.664548196,
            "PCA2": -7937.994533595525,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -35247996.66469671,
            "PCA2": -7937.848571600766,
            "Cluster": "Cluster 2"
        },
        {
            "Good": 0,
            "PCA1": -26826231.6778666,
            "PCA2": -880.651566040762,
            "Cluster": "Cluster 4"
        },
        {
            "Good": 0,
            "PCA1": -13013641.996847283,
            "PCA2": 1915.8068854492753,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.435206413,
            "PCA2": 2052.656546164872,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.435304644,
            "PCA2": 2052.8637041442594,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.435803981,
            "PCA2": 2053.1928848464063,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.436493985,
            "PCA2": 2053.601453777718,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.43631511,
            "PCA2": 2053.730135503235,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.437161358,
            "PCA2": 2054.1431856612635,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.43789351,
            "PCA2": 2054.3745210593975,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.438901303,
            "PCA2": 2054.7130008559607,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.43973522,
            "PCA2": 2054.961936971035,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.440328682,
            "PCA2": 2055.1225546040137,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.440503204,
            "PCA2": 2055.1322446007384,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.440205285,
            "PCA2": 2055.0053360619204,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.439966934,
            "PCA2": 2054.9206610979054,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.440111462,
            "PCA2": 2054.9983355421364,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.44056946,
            "PCA2": 2055.171489838542,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.440982915,
            "PCA2": 2055.2948111842366,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.440738954,
            "PCA2": 2055.170313515965,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.440447241,
            "PCA2": 2055.055607078593,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.439863374,
            "PCA2": 2054.8604221134715,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.439504424,
            "PCA2": 2054.757443662821,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337738.439271295,
            "PCA2": 2054.682898892439,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.129975416,
            "PCA2": 5467.599063507362,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.12991279,
            "PCA2": 5467.600650516459,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.129886348,
            "PCA2": 5467.597541562854,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.129852489,
            "PCA2": 5467.58899061387,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.129970664,
            "PCA2": 5467.641356121914,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.13010938,
            "PCA2": 5467.696477427393,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.130447857,
            "PCA2": 5467.812454858808,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.130641192,
            "PCA2": 5467.856589813837,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.13058157,
            "PCA2": 5467.821015435949,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.130378876,
            "PCA2": 5467.741625307088,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.130107168,
            "PCA2": 5467.651105776415,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.12998328,
            "PCA2": 5467.622274165985,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.129806416,
            "PCA2": 5467.558343197519,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.129180377,
            "PCA2": 5467.325935727547,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.128237296,
            "PCA2": 5467.000710846379,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.12746621,
            "PCA2": 5466.776070667311,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127100553,
            "PCA2": 5466.694623802203,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127134541,
            "PCA2": 5466.7441947463785,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127407202,
            "PCA2": 5466.858282490365,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127768358,
            "PCA2": 5466.987020043499,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.12822182,
            "PCA2": 5467.133856005797,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.128434153,
            "PCA2": 5467.180898421146,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.128373604,
            "PCA2": 5467.140330993019,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.128180595,
            "PCA2": 5467.057781370743,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127891514,
            "PCA2": 5466.963955639309,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127687419,
            "PCA2": 5466.904242906837,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127585612,
            "PCA2": 5466.8818650450585,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127526356,
            "PCA2": 5466.869282172802,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127499325,
            "PCA2": 5466.864255501032,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.12756858,
            "PCA2": 5466.89115530964,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.12762552,
            "PCA2": 5466.912846955594,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127647225,
            "PCA2": 5466.915742326982,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127625078,
            "PCA2": 5466.908388060305,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.12763092,
            "PCA2": 5466.894740145318,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.126546754,
            "PCA2": 5466.490119793235,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.126619479,
            "PCA2": 5466.536429417144,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.126698822,
            "PCA2": 5466.5966611533195,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.126985762,
            "PCA2": 5466.735610120356,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127468305,
            "PCA2": 5466.898700007757,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127759414,
            "PCA2": 5466.98321993524,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127863398,
            "PCA2": 5466.993197638014,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127732843,
            "PCA2": 5466.937472524623,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127483046,
            "PCA2": 5466.8390158748525,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127362818,
            "PCA2": 5466.810529812108,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127542214,
            "PCA2": 5466.888810754725,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127684955,
            "PCA2": 5466.9422950834705,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127749775,
            "PCA2": 5466.947703873617,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127489334,
            "PCA2": 5466.8400389653125,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337739.127256352,
            "PCA2": 5466.759436883814,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337740.671720654,
            "PCA2": 13097.591901717917,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337740.6715012,
            "PCA2": 13097.540243906471,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337740.67150063,
            "PCA2": 13097.555619974522,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -7198443.485934669,
            "PCA2": 14458.031281937116,
            "Cluster": "Cluster 7"
        },
        {
            "Good": 0,
            "PCA1": -6451082.543202453,
            "PCA2": 14609.378218229582,
            "Cluster": "Cluster 7"
        },
        {
            "Good": 0,
            "PCA1": -6451082.573552728,
            "PCA2": 14760.331232028695,
            "Cluster": "Cluster 7"
        },
        {
            "Good": 0,
            "PCA1": -6451082.619673801,
            "PCA2": 14989.243669143181,
            "Cluster": "Cluster 7"
        },
        {
            "Good": 0,
            "PCA1": -6451082.61990092,
            "PCA2": 14989.307965961014,
            "Cluster": "Cluster 7"
        },
        {
            "Good": 0,
            "PCA1": -6451082.6199310385,
            "PCA2": 14989.322659883128,
            "Cluster": "Cluster 7"
        },
        {
            "Good": 0,
            "PCA1": -6451082.619965875,
            "PCA2": 14989.344058339539,
            "Cluster": "Cluster 7"
        },
        {
            "Good": 0,
            "PCA1": -4210674.31002543,
            "PCA2": 15887.000363152938,
            "Cluster": "Cluster 7"
        },
        {
            "Good": 0,
            "PCA1": -535235.1495743889,
            "PCA2": 16631.002742323082,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59382326883,
            "PCA2": 16637.868814221376,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59348344867,
            "PCA2": 16637.76710192893,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5935347343,
            "PCA2": 16637.810988037803,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5939186139,
            "PCA2": 16637.95399116747,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5941119234,
            "PCA2": 16638.018133224658,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59412947984,
            "PCA2": 16637.993155792083,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59390425583,
            "PCA2": 16637.898307136507,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5936455761,
            "PCA2": 16637.825397834295,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5934973165,
            "PCA2": 16637.7791237949,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59577333037,
            "PCA2": 16638.67806172732,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.598587101,
            "PCA2": 16639.60994140434,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5986812298,
            "PCA2": 16639.458952222947,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5972783776,
            "PCA2": 16638.856130652995,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5952945407,
            "PCA2": 16638.17362082198,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59394911386,
            "PCA2": 16637.78303622046,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5935616979,
            "PCA2": 16637.76586983054,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59470027674,
            "PCA2": 16638.258655143338,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59633329575,
            "PCA2": 16638.828726500662,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.597644246,
            "PCA2": 16639.216848535118,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59822340246,
            "PCA2": 16639.339609803406,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59793849906,
            "PCA2": 16639.157162308024,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59674999805,
            "PCA2": 16638.695997023256,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5952320832,
            "PCA2": 16638.186768394786,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59420755453,
            "PCA2": 16637.90817789892,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5940828175,
            "PCA2": 16637.956851059997,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59460331703,
            "PCA2": 16638.167811326224,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5943128333,
            "PCA2": 16638.026572891187,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5944680462,
            "PCA2": 16638.110860176756,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5948448992,
            "PCA2": 16638.250266839288,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5952750745,
            "PCA2": 16638.39029920335,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5956532302,
            "PCA2": 16638.505183449844,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5958347494,
            "PCA2": 16638.54567425623,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5956785856,
            "PCA2": 16638.46306731926,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5953025259,
            "PCA2": 16638.329346533268,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5950537143,
            "PCA2": 16638.249450360858,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59490456874,
            "PCA2": 16638.2190825969,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59496344096,
            "PCA2": 16638.253313585516,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.5951845007,
            "PCA2": 16638.33627950139,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59530874697,
            "PCA2": 16638.373471289327,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59532952745,
            "PCA2": 16638.372740545637,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500773.59539317875,
            "PCA2": 16638.378153943522,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": 8394198.80800582,
            "PCA2": 22527.61137514709,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590751.322997896,
            "PCA2": 23174.716124775594,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590751.322859673,
            "PCA2": 23174.771319792555,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590751.322730254,
            "PCA2": 23174.805752417567,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590751.322664116,
            "PCA2": 23174.819998799496,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590751.32274621,
            "PCA2": 23174.783927763758,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590751.322869653,
            "PCA2": 23174.739358062183,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590751.323030481,
            "PCA2": 23174.68270881184,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590751.32316163,
            "PCA2": 23174.64875051625,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590751.323138978,
            "PCA2": 23174.665694179846,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590751.322993059,
            "PCA2": 23174.723899280907,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590751.322905067,
            "PCA2": 23174.750553273716,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": -500774.97319062107,
            "PCA2": 23446.197521526163,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9729245617,
            "PCA2": 23446.096778888845,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9725334203,
            "PCA2": 23445.96650819425,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97226461925,
            "PCA2": 23445.89623659699,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.972322188,
            "PCA2": 23445.93905250427,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.972400635,
            "PCA2": 23445.97004596482,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97213760886,
            "PCA2": 23445.857697766947,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9717698508,
            "PCA2": 23445.736247759294,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97152341343,
            "PCA2": 23445.66309176334,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97156461235,
            "PCA2": 23445.70395944873,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9716947638,
            "PCA2": 23445.758256923804,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9719067873,
            "PCA2": 23445.828197950923,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97209586,
            "PCA2": 23445.8884307876,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97224466765,
            "PCA2": 23445.930969982197,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9723002417,
            "PCA2": 23445.938403065174,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97223100974,
            "PCA2": 23445.906299024078,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97214150237,
            "PCA2": 23445.87146955963,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97202445276,
            "PCA2": 23445.837829054188,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97188096074,
            "PCA2": 23445.790044288635,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9719146502,
            "PCA2": 23445.813523936693,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97200175934,
            "PCA2": 23445.848756589323,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9721092923,
            "PCA2": 23445.880506057634,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9718004822,
            "PCA2": 23445.74799980866,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97133669513,
            "PCA2": 23445.59665167146,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9712786225,
            "PCA2": 23445.602181291004,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97134503693,
            "PCA2": 23445.6444552374,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9714309967,
            "PCA2": 23445.67099460921,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97155303747,
            "PCA2": 23445.710908526034,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97158035653,
            "PCA2": 23445.715610210715,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97158780193,
            "PCA2": 23445.712847065282,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97177683347,
            "PCA2": 23445.79293936959,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9722557392,
            "PCA2": 23445.961442071784,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9725415373,
            "PCA2": 23446.037459561907,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97262781305,
            "PCA2": 23446.04011711588,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9716195877,
            "PCA2": 23445.64078297929,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.9711667714,
            "PCA2": 23445.533658138338,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.97127158905,
            "PCA2": 23445.608217005534,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500774.971436148,
            "PCA2": 23445.687438567158,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": 11590749.131101772,
            "PCA2": 34002.61865528728,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590749.130987637,
            "PCA2": 34002.648063861394,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590749.130843805,
            "PCA2": 34002.682360926745,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590749.130966676,
            "PCA2": 34002.629986763495,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 1,
            "PCA1": 11590749.131227024,
            "PCA2": 34002.531023905765,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590749.131432382,
            "PCA2": 34002.476584933596,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590749.131334212,
            "PCA2": 34002.530887186535,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590749.130968206,
            "PCA2": 34002.67215965556,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 11590749.130699847,
            "PCA2": 34002.75433852682,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 14735303.597772917,
            "PCA2": 35850.40726949075,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": 23332816.80675283,
            "PCA2": 37590.827360148614,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936833.06605142,
            "PCA2": 37712.8133740994,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936833.066692732,
            "PCA2": 37712.61844223936,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936833.067167852,
            "PCA2": 37712.48559993407,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936833.067217417,
            "PCA2": 37712.50859088858,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936833.06703811,
            "PCA2": 37712.5919552356,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936833.066600945,
            "PCA2": 37712.752560286586,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936833.06626756,
            "PCA2": 37712.8527085097,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936833.06620082,
            "PCA2": 37712.850947731604,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 23936833.066196904,
            "PCA2": 37712.8393051336,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 32398966.117987476,
            "PCA2": 41024.93693934742,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.358047046,
            "PCA2": 41862.539184192734,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.358743794,
            "PCA2": 41862.31405462327,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.35898888,
            "PCA2": 41862.27064110391,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.35884039,
            "PCA2": 41862.36674584903,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.35812538,
            "PCA2": 41862.63235044272,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.35728508,
            "PCA2": 41862.91476453397,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.356933214,
            "PCA2": 41862.98389606439,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.35709273,
            "PCA2": 41862.89147796287,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.3576064,
            "PCA2": 41862.691293843054,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.35827957,
            "PCA2": 41862.45475792855,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.35876293,
            "PCA2": 41862.32995639404,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.3588234,
            "PCA2": 41862.34244351174,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.35864059,
            "PCA2": 41862.42377497844,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.35831825,
            "PCA2": 41862.545889262234,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.35795285,
            "PCA2": 41862.65576727271,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.358894646,
            "PCA2": 41862.266161197265,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.35925599,
            "PCA2": 41862.18504088697,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.35818156,
            "PCA2": 41862.569862859666,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 36537475.34717049,
            "PCA2": 41866.705086317204,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 35456517.76421752,
            "PCA2": 44740.80461676555,
            "Cluster": "Cluster 5"
        },
        {
            "Good": 0,
            "PCA1": 25764833.193718493,
            "PCA2": 42780.774594758535,
            "Cluster": "Cluster 1"
        },
        {
            "Good": 0,
            "PCA1": 10557421.657237964,
            "PCA2": 39783.46213068271,
            "Cluster": "Cluster 6"
        },
        {
            "Good": 0,
            "PCA1": -255360.8851611283,
            "PCA2": 37590.88459205114,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500777.82167326985,
            "PCA2": 37532.93464206362,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500777.82078143023,
            "PCA2": 37532.730112903475,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500777.82093972235,
            "PCA2": 37532.8590529691,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500777.8209032917,
            "PCA2": 37532.91115797447,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500777.8210903547,
            "PCA2": 37532.98001657737,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500777.8210330359,
            "PCA2": 37532.96577122742,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500777.821099595,
            "PCA2": 37533.00030480079,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500777.82112608827,
            "PCA2": 37533.03762252819,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -500777.8212297759,
            "PCA2": 37533.10401109881,
            "Cluster": "Cluster 0"
        },
        {
            "Good": 0,
            "PCA1": -6828829.28908586,
            "PCA2": 37531.49610363019,
            "Cluster": "Cluster 7"
        },
        {
            "Good": 0,
            "PCA1": -12337745.388783298,
            "PCA2": 36416.08258933028,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337745.388970062,
            "PCA2": 36416.1421159718,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337745.388572332,
            "PCA2": 36416.006854998224,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337745.389522241,
            "PCA2": 36416.29660319386,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337745.389153926,
            "PCA2": 36416.18632628154,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337745.390144918,
            "PCA2": 36416.48151976368,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337745.390127707,
            "PCA2": 36416.44107584582,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337745.405299254,
            "PCA2": 36420.9384897746,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337745.415999532,
            "PCA2": 36423.47306060159,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337745.408913415,
            "PCA2": 36420.20074252217,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337745.386827072,
            "PCA2": 36412.73332045863,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337745.384537527,
            "PCA2": 36411.95776008714,
            "Cluster": "Cluster 3"
        },
        {
            "Good": 0,
            "PCA1": -12337745.384477185,
            "PCA2": 36411.94507578272,
            "Cluster": "Cluster 3"
        }
        // { x: 2.3, y: 3.1, cluster: 'golden' },
        // { x: 2.0, y: 3.3, cluster: 'golden' },
        // // Normal points
        // { x: 1.5, y: 2.5, cluster: 'normal' },
        // { x: 1.8, y: 2.7, cluster: 'normal' },
        // { x: 1.6, y: 2.4, cluster: 'normal' },
        // // Outlier points
        // { x: 4.0, y: 5.0, cluster: 'outlier' },
        // { x: 0.5, y: 0.5, cluster: 'outlier' },
    ],
    goldenCentroid: { x: 2.13, y: 3.2 },
    featureX: "Temperature",
    featureY: "Pressure"
};


export const GoldenBatchScatterPlot = (data1: any) => {
    let data = data1.data;
    let operation = data1?.operation;
    
    // Get unique clusters from data
    const clusters = Array.from(new Set(data.map((p: any) => p.Cluster).filter((cluster: any) => cluster !== null)));

    // Create Plotly data array - each cluster becomes a separate trace
    const plotlyData = clusters.map((cluster: any) => {
        const clusterData = data.filter((p: any) => p.Cluster === cluster);
        
        if(operation && operation=='Identification-Model') {
            return {
                name: 'cluster-' + cluster,
                type: 'scatter',
                mode: 'markers',
                x: clusterData.map((p: any) => p.component_1),
                y: clusterData.map((p: any) => p.component_2),
                marker: {
                    size: 8,
                }
            };
        }
        
        return {
            name: cluster,
            type: 'scatter',
            mode: 'markers',
            x: clusterData.map((p: any) => p.PCA1),
            y: clusterData.map((p: any) => p.PCA2),
            marker: {
                size: 8,
            }
        };
    });

    // Define colors for the clusters - same as in original component
    const colors = [
        '#FFD700', // Orange Red  
        '#4682B4', // Dodger Blue  
        '#DC143C', // Crimson  
        '#32CD32', // Lime Green  
        '#8A2BE2', // Blue Violet  
        '#FF1493', // Deep Pink  
        '#00CED1', // Dark Turquoise  
        '#7FFF00', // Chartreuse  
        '#FF8C00'  // Dark Orange
    ];

    // Define the layout for the Plotly plot
    const layout = {
        title: !operation ? 'Golden Batch Analysis' : 'Identification Model Analysis',
        width: 700,
        height: 450,
        xaxis: {
            title: data.featureX,
            zeroline: true,
            gridcolor: '#E5E5E5'
        },
        yaxis: {
            title: data.featureY,
            zeroline: true,
            gridcolor: '#E5E5E5'
        },
        showlegend: true,
        legend: {
            orientation: 'h',
            y: 1.1
        },
        colorway: colors,
        hovermode: 'closest',
        margin: {
            l: 60,
            r: 30,
            b: 60,
            t: 50,
            pad: 4
        }
    };

    // Plotly config options
    const config = {
        responsive: true,
        displayModeBar: false
    };

    return (
        <div style={{ minWidth: '600px' }}>
            <Plot
                data={plotlyData as any}
                layout={layout as any}
                config={config}
                style={{ width: '100%', height: '450px' }}
            />
            <div style={{ 
                textAlign: 'center', 
                width: '700px',
                marginTop: '10px',
                fontSize: '14px',
                color: '#666'
            }}>
                {!operation ? 'Golden Batch Analysis':'Identification Model Analysis'}
            </div>
        </div>
    );
};

// Export the dummy data for testing
export { dummyGoldenBatchData }; 