"use client"

import { useCallback } from "react"
import ReactFlow, {
  Controls,
  Background,
  MiniMap,
  useNodesState,
  useEdgesState,
  addEdge,
  type Connection,
  MarkerType,
} from "reactflow"
import "reactflow/dist/style.css"

// Sample JSON data (can be replaced with actual data)
const sampleData = {
  nodes: [
    { id: "cooking_ph", label: "Cooking_pH", x: 100, y: 100 },
    { id: "temperature_milk", label: "Temperature_of_Milk_in_vat", x: 500, y: 100 },
    { id: "vat_ph", label: "VatpH", x: 100, y: 300 },
    { id: "culture_ph", label: "Culture_pH", x: 500, y: 300 },
  ],
  edges: [
    { source: "cooking_ph", target: "temperature_milk", value: 0.23 },
    { source: "cooking_ph", target: "vat_ph", value: 0.01 },
    { source: "cooking_ph", target: "culture_ph", value: -0.24 },
    { source: "vat_ph", target: "culture_ph", value: -0.05 },
    { source: "temperature_milk", target: "culture_ph", value: 0.03 },
  ],
}

export default function DirectedGraph({ data = sampleData }) {
  // Convert input JSON to React Flow format
  const parseGraphData = useCallback((inputData: any) => {
    const nodes = inputData.nodes.map((node: any) => ({
      id: node.id,
      data: { label: node.label },
      position: { x: node.x, y: node.y },
      style: {
        border: "1px solid #777",
        padding: "10px",
        borderRadius: "3px",
        background: "white",
        fontSize: "12px",
      },
    }))

    const edges = inputData.edges.map((edge: any, index: any) => {
      // Determine color based on value
      const edgeColor = edge.value >= 0 ? "#7ac555" : "#ff0000" // Green for positive, Red for negative
      const edgeType = edge.value < 0 ? "dashed" : "solid"

      return {
        id: `e${index}`,
        source: edge.source,
        target: edge.target,
        label: edge.value.toString(),
        style: {
          stroke: edgeColor,
          strokeWidth: 2,
          strokeDasharray: edgeType === "dashed" ? "5,5" : "none",
        },
        labelBgPadding: [4, 2],
        labelBgBorderRadius: 4,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: edgeColor,
        },
      }
    })

    return { nodes, edges }
  }, [])

  // Parse and set initial nodes & edges
  const { nodes: initialNodes, edges: initialEdges } = parseGraphData(data)
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)

  // Handle new connections
  const onConnect = useCallback(
    (connection: Connection) => {
      setEdges((eds) =>
        addEdge(
          {
            ...connection,
            markerEnd: { type: MarkerType.ArrowClosed },
            label: "0.00",
          },
          eds,
        ),
      )
    },
    [setEdges],
  )

  return (
    <div className="w-full h-[600px] border border-gray-300 rounded-md">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
      >
        <Controls />
        <MiniMap />
        <Background />
      </ReactFlow>
    </div>
  )
}
