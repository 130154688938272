import React, { useEffect, useRef, useState } from 'react';
import { Modal, Input } from 'antd';
import type { InputRef } from 'antd';

interface FileModalProps {
    visible: boolean;
    onConfirm: (name: string) => void;
    onCancel: () => void;
}

const SaveStatisticalDataModal: React.FC<FileModalProps> = ({ visible, onConfirm, onCancel }) => {
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 200);
        }
    }, [visible]);

    const handleConfirm = () => {
        if (!name.trim()) {
            setError('Name cannot be empty.');
            return;
        }
        onConfirm(name.trim());
        setName('');
        setError('');
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        if (error) setError(''); // Clear error when user starts typing
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleConfirm();
        }
    };

    return (
        <Modal
            title="Save Statistical Data"
            visible={visible}
            onOk={handleConfirm}
            onCancel={() => {
                setName('');
                setError('');
                onCancel();
            }}
            okText="Save"
            cancelText="Cancel"
        >
            <Input
                placeholder="Enter name"
                ref={inputRef}
                value={name}
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
                style={{
                    marginTop: 10,
                    borderColor: error ? 'red' : undefined,
                }}
            />
            {error && <p style={{ color: 'red', marginTop: 5 }}>{error}</p>}
        </Modal>
    );
};

export default SaveStatisticalDataModal;
